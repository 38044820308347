export const IntakeThankYou = () => {
  return (
    <div className="h-100 flex flex-col justify-around text-center items-center px-10">
      <h2 className="text-2xl md:text-4xl font-header mb-8">{`Thank You for Your Submission!`}</h2>
      <p className="text-lg md:text-2xl max-w-4xl">{`We look forward to speaking with you! A representative will be in touch in the next 1-2 business days.`}</p>
    </div>
  )
}

export default IntakeThankYou
