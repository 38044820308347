import { ClassImage } from "types/shopScheduleTypes"


type ClassProductHeadingProps = {
  classTitle?: string;
  classDuration?: string | number;
  classImage?: ClassImage;
}

export default function ClassProductHeading( props : ClassProductHeadingProps ) : JSX.Element {
  if ( !props.classTitle?.length ) return <></>

  if ( !props?.classImage?.url?.length ) return <h1 className="hub-heading text-gray-dark my-4">{props.classTitle}</h1>

  return (
    <div className="flex gap-5 items-center justify-center mt-7 mb-5 max-w-xl">
      <img
        width={75} height={75} src={props?.classImage?.url}
        alt={props?.classImage?.label}
      />
      <div className="flex flex-col items-start">
        <p className="text-lg md:text-2xl text-left">{props.classTitle}</p>
        <p className="font-light text-lg">{`${props.classDuration} minutes`}</p>
      </div>
    </div>
  )
}