import { ClassProduct, ScheduledClass } from "types/shopScheduleTypes"
import Tile from '../Tile'
import { default as ClassTile } from '../ClassManagement/ClassDetails/Class'
import { timeFromNowPrettyString, is30MinsAway } from './time'
import JoinNowCountdown from "./JoinNowCountdown"

type YNCProps = {
  class : ScheduledClass | null;
  classDetails: ClassProduct[];
  scheduledClasses: ScheduledClass[];
  setHideActions: React.Dispatch<React.SetStateAction<boolean>>;
  hideActions: boolean;
}

export default function YourNextClassTile( props : YNCProps ) : JSX.Element {

  const nextClass = [ ...props.scheduledClasses ]?.[0]
  const nextDetails = [ ...props.classDetails ]?.find( ( classDetail: ClassProduct ) => classDetail.sku === nextClass?.class_sku )

  if ( !nextClass ) return <></>

  const scheduledDate = nextClass?.start_time_local ? `${nextClass.start_time_local.substring( 0, 19 )}` : nextClass?.timeslot_exact_timestamp?.substring( 0, 19 ) as string

  const classHeaderSoon = (
    <p className="font-semibold">{`Your next class:`} {`${timeFromNowPrettyString( scheduledDate )}`}</p>
  )

  return (
    <Tile
      headerContent={is30MinsAway( scheduledDate ) ? (
        <JoinNowCountdown
          time={scheduledDate}
          zoom_link={props.class?.zoom_link ?? ``}
          event_instance_id={props.class?.event_instance_id ?? ``}
          setHideActions={props.setHideActions}
        />
      ) : classHeaderSoon}
    >
      <ClassTile
        key={nextClass.class_sku}
        classDetails={nextDetails as ClassProduct}
        scheduledClasses={props.scheduledClasses}
        {...nextClass}
        hideActions={props.hideActions}
        isNextClassTile
      />
    </Tile>
  )
}