import { getHash } from "utils"

export const getMagentoEnvHeader = () => {
  if ( !sessionStorage.getItem( `magentoToken` ) || getHash() ) return {}

  const env = process.env.REACT_APP_ENVIRONMENT !== `production` ? process.env.REACT_APP_MAGENTO_2_BASE_URL?.split( `.` )?.[1]?.toLowerCase() : null

  return {
    ...( env ?
      {
        'x-aeroflow-magento-environment': env
      } : {})
  }
}