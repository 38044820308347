import * as React from 'react'
import { ErrorBoundary } from "react-error-boundary"
import { reportToSentry } from "utils/reportToSentry"
import ErrorFallback from './ErrorFallback'
import PropTypes from 'prop-types'

export function ErrorHandler({children}) {

  const [ containsError, setContainsError ] = React.useState( false )

  function handleOnError( error, errorInfo ) {
    setContainsError( true )
    reportToSentry( error, {
      errorInfo
    })
  }

  function handleOnReset() {
    setContainsError( false )
  }

  return (
    <ErrorBoundary
      onError={handleOnError}
      FallbackComponent={ErrorFallback}
      onReset={handleOnReset}
    >
      {/* children will continue to render with errors unless we display render safe null */}
      {containsError ? null : children}
    </ErrorBoundary>
  )
}

ErrorHandler.propTypes = {
  children: PropTypes.node
}