export const getSource = () => {

  try {
    if ( window.location !== window?.parent?.location ) return window?.parent?.location?.hostname
  } catch ( e ) {
    return `aeroflowbreastpumps.com`
  }

  const searchParams = new URLSearchParams( window.location.search )

  if ( searchParams.has( `myAccount` ) ) {
    return window.location.hostname === `localhost` ?
      window.location.href.replace( `localhost:3000`, `react-lactation.aeroflow.ninja` ).concat( window.location.search )
      : window.location.href.concat( window.location.search )
  }

  // still reporting just the hostname here to not break existing reporting
  return window.location.hostname === `localhost` ? `react-lactation.aeroflow.ninja` : window.location.hostname
}