import PageNavigator from 'components/pageNavigator'
import { OneOnOneScheduling } from 'views/OneOnOneScheduling'
import { OneOnOneThankYou } from 'views/ThankYou'

function OneOnOne() : JSX.Element {

  const isReschedule = window.location.pathname.includes( `reschedule` )

  return (
    <PageNavigator
      pageNames={[ isReschedule ? `/reschedule-appointment` : `/schedule-appointment`, `/schedule-appointment/thank-you` ]}
    >
      <OneOnOneScheduling isReschedule={isReschedule} />
      <OneOnOneThankYou />
    </PageNavigator>
  )
}

export default OneOnOne