import { AWSResponse } from "types/aws"
import { ScheduledClass } from "types/shopScheduleTypes"
import { getMagentoEnvHeader } from "utils/getMagentoEnvHeader"

export default function fetchScheduledClasses( authorizationHeader: string, timezone: string ) : Promise<AWSResponse<ScheduledClasses>> {
  return fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/scheduled-classes?tz=${timezone}&source=${window.location.hostname}`, {
    headers: {
      ...getMagentoEnvHeader(),
      'Authorization': authorizationHeader
    }
  }).then( r => r.json() )
}

export type ScheduledClasses = {
  upcoming: Array<ScheduledClass>;
  completed: Array<ScheduledClass>;
}