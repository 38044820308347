import { reportToSentry } from "utils/reportToSentry"

export const submitPartialScheduling = async ( class_sku, selectedSlot, authorization, bundle_sku ) => {
  try {
    const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/partial-submit`, {
      method: `POST`,
      headers: {
        "Authorization": authorization
      },
      body: JSON.stringify({
        class_schedule_event_confirmed: true,
        ...( bundle_sku ? {
          bundle_sku
        } : {}),
        class_sku,
        event_instance_id: selectedSlot.event_instance_id,
        class_pk: selectedSlot.class_pk
      })
    })

    const responseData = await response.json()

    if ( !responseData?.data?.success || responseData?.meta?.status !== `Accepted` ) {
      reportToSentry( new Error( `Shop and Schedule: There was an error submitting partial scheduling` ), {
        authorization,
        selectedSlot: JSON.stringify( selectedSlot ),
        class_sku
      })
    }
  } catch ( error ) {
    reportToSentry( new Error( `Shop and Schedule: There was an error submitting partial scheduling`, {
      cause: error
    }), {
      authorization,
      selectedSlot: JSON.stringify( selectedSlot ),
      class_sku
    })
  }

}