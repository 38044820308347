import { AWSResponse } from "types/aws"
import { ClassDescription } from "types/shopScheduleTypes"
import { getCurrentTimeZoneString } from "utils/time"

export default function fetchClassDescription( class_id: string, authorizationHeader: string ) : Promise<AWSResponse<ClassDescription>> {
  return fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/classes/${class_id}?tz=${getCurrentTimeZoneString()}&source=${window.location.hostname}`, {
    headers: {
      'Authorization': authorizationHeader
    }
  }).then( r => r.json() )
}
