import TimeBlock from './TimeBlock'
import { TimeSlot } from 'types/shopScheduleTypes'
import { ThemedTimezoneSelector } from 'components/ThemedTimezoneSelector'

type TimeBlockSelectProps = {
  timeSlots: TimeSlot[];
  selectedTime: Date | null;
  setSelectedTime: React.Dispatch<React.SetStateAction<Date | null>>;
  onTimeSelect?: ( _d : Date ) => void
  header: JSX.Element;
  selectedTimeZone: string | null;
  setSelectedTimeZone: React.Dispatch<React.SetStateAction<string>>;
}

export default function TimeBlockSelect({timeSlots, selectedTime, setSelectedTime, selectedTimeZone, setSelectedTimeZone, header} : TimeBlockSelectProps ) : JSX.Element | null {

  function handleTimeSelect( t : Date ) {
    const isCurrentlySelected = t.toISOString() === selectedTime?.toISOString()
    setSelectedTime( isCurrentlySelected ? null : t )
  }

  if ( !selectedTimeZone ) return null

  return (
    <div>
      <ThemedTimezoneSelector timezone={selectedTimeZone} setTimezone={setSelectedTimeZone} />

      {header ?? null}

      <div className="flex gap-4 flex-wrap justify-center items-center">
        {
          timeSlots?.map( ( ts : TimeSlot ) => {
            const time = new Date( ts.timeslot_exact_timestamp )
            if ( !time ) return null

            return <span key={time.toISOString()} onClick={() => handleTimeSelect( time )}>
              <TimeBlock
                time={time} timeZone={selectedTimeZone} classDuration={ts.duration_minutes}
                selected={selectedTime?.toISOString() === time.toISOString()}
              />
            </span>
          })
        }
      </div>
    </div>
  )
}