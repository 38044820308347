import React, { useEffect, useState } from 'react'
import PageOneQuestions from './PageOneQuestions'
import Pagination from '@mui/material/Pagination'
import PageTwoQuestions from './PageTwoQuestions'
import { PatientHistoryForm, PatientHistoryOption } from 'types/integratedPatientHistory'
import { submitPatientHistoryForm } from 'views/IntegratedHistoryForm/utils'
import useAuthStore from 'stores/auth'
import { reportToSentry } from 'utils/reportToSentry'
import { useNavigate } from 'react-router-dom'
import { INTEGRATED_PATIENT_HISTORY_THANK_YOU_PATH } from 'routes'
import { initialPatientHistory } from './constants'
import RingLoader from 'components/RingLoader'
import { HER_HUB_PATH } from 'views/HerHub/utils/constants'
import useScheduledAppointments from 'hooks/useScheduledAppointments'

const requiredFieldIds = [
  `type_of_appointment`,
  `most_important_issue`,
  `have_had_baby`
]

const epdsQuestionFieldIds = [
  `been_able_to_laugh`,
  `looked_forward_to_things`,
  `blamed_myself`,
  `anxious_or_worried`,
  `scared_or_panicky`,
  `feeling_overwhelmed`,
  `unhappy_and_difficulty_sleeping`,
  `sad_or_miserable`,
  `unhappy_have_been_crying`,
  `thoughts_of_harming_myself`
]

function IntegratedHistoryInput(): JSX.Element {
  const [ page, setPage ] = useState<number>( 1 )
  const [ error, setError ] = useState<string>( `` )
  const [ loading, setLoading ] = useState<boolean>( false )
  const [ hideQuestions, setHideQuestions ] = useState<boolean>( true )
  const [ values, setValues ] = useState<PatientHistoryForm>({
    ...initialPatientHistory
  })

  const navigate = useNavigate()
  const { loading: hasSignedQuestionnaireLoading, hasSignedQuestionnaire } = useScheduledAppointments()


  useEffect( () => {
    window.scrollTo({
      top: 0,
      behavior: `smooth`
    })
  }, [ page ] )

  const { buildAuthorizer } = useAuthStore()

  const onChange = ( key: string, value: any ) => {
    setError( `` )
    const updatedValues = {
      ...values,
      [key]: value
    }

    setHideQuestions( key === `have_had_baby` && value === `No` )

    setValues( updatedValues )
  }

  const onMultiSelectChange = ( key: string, itemKey: string, value: boolean, additional = `` ) => {
    const updatedValues = {
      ...values,
      [key]: ( values[key] as PatientHistoryOption[] ).map( ( item: any ) => {
        if ( item.name?.toLowerCase() === itemKey?.toLowerCase() ) {
          return {
            ...item,
            selected: value,
            ...( additional && {
              value: additional
            })
          }
        }

        return item
      })
    }

    setValues( updatedValues )
  }

  const handlePatientHistoryFormSubmit = async ( e: React.MouseEvent<HTMLButtonElement> ) => {
    e.preventDefault()
    if ( requiredFieldIds.some( ( id ) => {
      return !values[id]
    }) ) {
      const missingFieldId = requiredFieldIds.find( ( id ) => {
        return !values[id]
      })
      if ( missingFieldId ) document.getElementById( missingFieldId )?.scrollIntoView({
        block: `center`,
        behavior: `smooth`
      })

      return setError( `Please fill out all required fields.` )
    }
    if ( page === 1 ) return setPage( 2 )
    setLoading( true )
    const epdsScore = epdsQuestionFieldIds.every( ( id ) => Boolean( values[id] ) ) ?
      epdsQuestionFieldIds.reduce( ( acc, cur ) => Number( ( values[cur] as any )?.value ) + acc, 0 ) : undefined
    const selfHarmResponse = values.thoughts_of_harming_myself?.name

    const response = await submitPatientHistoryForm( buildAuthorizer(), values, epdsScore, selfHarmResponse )
      .catch( ( error ) => {
        setError( `There was an error submitting your responses. Please try again.` )
        setLoading( false )
        reportToSentry( new Error( `Error submitting patient history form`, {
          cause: error
        }), {
          values: JSON.stringify( values )
        })
      })

    setLoading( false )

    if ( response?.meta?.status?.toLowerCase() === `ok` ) return navigate( INTEGRATED_PATIENT_HISTORY_THANK_YOU_PATH )

    return setError( `There was an error submitting your responses. Please try again.` )
  }

  if ( hasSignedQuestionnaireLoading ) return <div className="my-4 px-3"><RingLoader /></div>

  if ( hasSignedQuestionnaire ) return (
    <div className="py-4 px-4 flex flex-col w-full items-center justify-center max-w-2xl mx-auto space-y-3">
      <div className="p-5 flex flex-col items-center justify-center gap-3">
        <h1 className="hub-heading text-center text-3xl">{`Your Appointment!`}</h1>
        <p
          className="text-center text-lg"
        >{`Hey mama, you have already completed your patient history form and you are all set!`}</p>
        <p
          className="text-lg my-10 a"
          onClick={() => {
            navigate( HER_HUB_PATH.concat( window.location.search ) )
          }}
        >
          {`Go back to my Account`}
        </p>
      </div>
    </div>
  )


  return (
    <form className="w-full space-y-10 px-3">
      <div className="p-5 flex flex-col items-center justify-center gap-3">
        <h1 className="hub-heading text-center text-3xl">{`Your Appointment!`}</h1>
        <p
          className="text-center text-lg"
        >{`We are looking forward to supporting you on your mothering journey. Prior to your first appointment, please complete the quick questionnaire below to help your consultant understand your needs and goals.`}</p>
      </div>
      <PageOneQuestions
        page={page} values={values} onChange={onChange}
        onMultiSelectChange={onMultiSelectChange} hideQuestions={hideQuestions}
      />
      <PageTwoQuestions
        page={page} values={values} onChange={onChange}
      />
      <div className="flex flex-col gap-3 mx-auto items-center justify-center mb-10">
        <Pagination
          color="primary" count={2} page={page}
          onChange={( _e, page ) => {
            setPage( page )
          }}
        />
        <button
          className="primary-button mt-5" onClick={handlePatientHistoryFormSubmit}
          disabled={loading}
        >
          {
            page === 1 ?
              <>
                {`Next`}
              </>
              :
              loading ?
                <>{`Submitting...`}</>
                :
                <>{`Submit`}</>
          }
        </button>
        {
          error &&
          <p className="text-error mb-8">{error}</p>
        }
      </div>
    </form>
  )
}

export default IntegratedHistoryInput