import { useEffect } from "react"
import Tile from "../Tile"
import useHerHubStore from "stores/herHub"
import { ScheduledClass } from "types/shopScheduleTypes"

type ClassUpdateProps = {
  updateText: string;
  classItem: ScheduledClass;
}

export default function ClassUpdate({updateText, classItem } : ClassUpdateProps ) : JSX.Element {

  const { hideClassFromNotifications } = useHerHubStore()

  useEffect( () => {
    const removeNotificationTimer = setTimeout( () => {
      hideClassFromNotifications( classItem.class_pk )
    }, 15000 )

    return () => clearTimeout( removeNotificationTimer )
  }, [] )

  return (
    <Tile className="bg-secondary rounded-lg text-white -my-4 relative">
      <p
        className="absolute px-4 top-0 right-0 cursor-pointer"
        onClick={() => hideClassFromNotifications( classItem.class_pk )}
      >
        {`x`}
      </p>
      <div className="flex flex-col items-center w-full">
        <p className="uppercase font-semibold">{updateText}</p>
        <p className="font-thin">{classItem.class_title}</p>
      </div>
    </Tile>
  )
}