import { useState } from 'react'
import { isEmailValid } from 'utils'
import { ChangeTextInput, Validators } from 'components/Forms/components'
import { generateCustomerTokenMutation } from 'graphql/mutations/generateCustomerToken'
import { isEmailAvailableQuery } from 'graphql/queries/isEmailAvailable'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import useAuthStore from 'stores/auth'
import { useLocation, useNavigate, Link} from 'react-router-dom'
import ButtonLoader from 'components/ButtonLoader'
import { reportToSentry } from 'utils/reportToSentry'
import { FORGOT_PASSWORD_PATH } from 'routes'
import useIntakeStore from 'stores/intake'
import PasswordInput from 'components/Forms/components/PasswordInput'
import { usePatientDataStore } from 'stores/patientData'

export type FormDisplayType = `newSignIn` | `email`

export default function EmailOnly() : JSX.Element {

  const [ formDisplay, setFormDisplay ] = useState<FormDisplayType>( `newSignIn` )

  const [ emailInputValue, setEmailInputValue ] = useIntakeStore( state => [ state.email, state.setEmail ] )

  const [ password, setPassword ] = useState( `` )

  const [ submitLoading, setSubmitLoading ] = useState( false )
  const [ errorMessage, setErrorMessage ] = useState( `` )

  const location = useLocation()
  const navigate = useNavigate()

  const { preAuthFrom } = useAuthStore()

  const [ login ] = useMutation( generateCustomerTokenMutation, {
    errorPolicy: `all`,
    variables: {
      email: emailInputValue,
      password
    },
    onError: ( error : ApolloError ) => {
      reportToSentry( new Error( `login mutation failed` ), {
        apolloError: error
      })
    }
  })

  const [ isEmailAvailable ] = useLazyQuery( isEmailAvailableQuery )

  const { getCurrentToken, setCurrentToken } = useAuthStore()
  const { setCustomerPricingData } = usePatientDataStore()

  const resetFormView = () => {
    setFormDisplay( `newSignIn` )
    setEmailInputValue( `` )
    setErrorMessage( `` )
  }

  function handleSignIn() {
    if ( formDisplay === `newSignIn` ) handleEmailAvailability()
    if ( formDisplay === `email` ) handleEmailSignIn()
  }

  async function handleEmailAvailability() {
    const isValidEmail = isEmailValid( emailInputValue )
    if ( !isValidEmail ) return setErrorMessage( `Please enter a valid email` )
    setSubmitLoading( true )
    // check for email availability
    const gqlResponse = await isEmailAvailable({
      variables: {
        email: emailInputValue
      },
      onError: () => {
        reportToSentry( new Error( `isEmailAvailableQuery failed` ), {
          emailUsed: emailInputValue
        })
      }
    })

    // Email is not in our system, we redirect user to intake form
    const shouldShowIntakeForm = gqlResponse?.data?.isEmailAvailable.is_email_available ?? true

    if ( shouldShowIntakeForm ) return navigate( `/intake/lookup${preAuthFrom}` )

    setFormDisplay( `email` )
    setSubmitLoading( false )
  }

  async function handleEmailSignIn() {
    if ( !password ) return setErrorMessage( `Please enter your password` )
    setErrorMessage( `` )
    setSubmitLoading( true )
    const loginResult = await login()
      .catch( () => {
        return setErrorMessage( `An error occurred validating your credentials. Please try again.` )
      })
    setSubmitLoading( false )
    const magentoToken = loginResult?.data?.generateCustomerToken?.token
    if ( magentoToken ) {
      if ( magentoToken !== getCurrentToken() ) {
        await setCustomerPricingData( `Bearer ${magentoToken}` )
      }
      setCurrentToken( magentoToken )
      navigate( location?.state?.from ?? `/my-account`, {
        replace: true
      })
    }
    setErrorMessage( `The password does not match the email above. Please try again or click the “Forgot your password” link to reset your password.` )
  }


  return (
    <div className="max-w-sm w-full mx-auto flex flex-col items-center gap-5">
      <ChangeTextInput
        name="newSignInInput"
        description="If you have an account, sign in with your email address."
        placeholder="Email Address"
        value={emailInputValue}
        setValue={( newValue: string ) => {
          setErrorMessage( `` )
          setEmailInputValue( newValue )
        }}
        shouldBeHidden={( formDisplay !== `newSignIn` )}
      />

      {
        formDisplay === `email` &&
        <PasswordInput
          name="passwordInput"
          label="Enter your password"
          value={password}
          onChange={( e: React.FormEvent<HTMLInputElement> ) => {
            setErrorMessage( `` )
            setPassword( e.currentTarget.value )
          }}
          onChangeClick={resetFormView}
          shouldBeHidden={( formDisplay !== `email` )}
          changeText={emailInputValue}
          formatter={undefined}
          validator={undefined}
          reference={undefined}
          onBlur={undefined}
        />
      }


      {errorMessage && <p className="text-error mt-2 text-center">{errorMessage}</p>}

      <button
        className="primary-button my-4"
        onClick={handleSignIn}
        disabled={submitLoading || !Validators.email( emailInputValue )}
      >
        {submitLoading ?
          <div className="flex items-center justify-center gap-2">
            <ButtonLoader />
            <p>{`Verifying`}</p>
          </div>
          :
          `Sign In`
        }
      </button>
      <Link to={FORGOT_PASSWORD_PATH} className="a">{`Forgot your password?`}</Link>
    </div>
  )
}