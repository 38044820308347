import React from 'react'

function NoAppointmentsBlock() : JSX.Element {
  return (
    <div className="w-3/4 mx-auto border border-solid border-red-500">
      <p className="text-center text-error px-12 py-24">
        <span>
          {`Hey mama, this request requires assistance from a member of our customer success team. Please call`}
        </span>
        <span>
          <a className="cursor-pointer underline decoration-error" href="tel:+18887323979">{` 888-732-3979 `}</a>
        </span>
        <span>
          {`to complete your booking.`}
        </span>
      </p>
    </div>
  )
}

export default NoAppointmentsBlock