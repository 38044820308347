import Header from 'components/Header'
import PropTypes from 'prop-types'
import { Footer } from '../Footer/Footer'

const Layout = ({ children }) => {

  return (
    <div className={`flex flex-col h-screen`}>
      <Header />
      <div className={`flex-grow`}>{children}</div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node
}

export default Layout
