import React from 'react'
import ThankYouPage from '../../reusable-components/ThankYouPage'

function IntegratedPatientHistoryThankYou(): JSX.Element {
  return ( <ThankYouPage
    header={`Your Form Has Been Submitted!`}
    body={`Thank you for taking the time to fill out your patient history form. If you have already delivered your baby, please bring him/her with you to the appointment.`}
    signOff={`We look forward to meeting with you soon!`}
    signature={`The Aeroflow Lactation Team`}
  /> )
}

export default IntegratedPatientHistoryThankYou