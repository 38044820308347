import reviewJSON from './reviews.json'
import Review from './Review'
import Tile from '../Tile'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export default function ReviewsTile() : JSX.Element {

  const sliderSettings = {
    dots: true,
    autoplay: true,
    arrows: false,
    infinite: true,
    swipeToSlide: true,
    focusOnSelect: true,
    slidesToShow: 1
  }

  return (
    <Tile>
      <div className="pb-5">
        <Slider {...sliderSettings}>
          {reviewJSON.map( review => ( <div key={review.quote}><Review {...review} /></div> ) )}
        </Slider>
      </div>
    </Tile>
  )
}