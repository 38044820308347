
function TermsAndConditions() : JSX.Element {
  return (
    <div className="flex flex-col items-center text-left justify-center space-y-8 pt-5 pb-10 max-w-3xl mx-auto">
      <h1 className="text-3xl font-header">{`Terms and Conditions`}</h1>
      <p className="font-semibold">{`Please read this agreement carefully. This Agreement contains a mandatory arbitration provision and provisions that govern how claims that you and we have against each other are resolved. Specifically, the arbitration provision (with limited exception for proceeding in small claims court) requires disputes between us to be submitted to binding and final arbitration on an individual basis, rather than jury trials or class actions.`}</p>
      <div className="space-y-3">
        <h1 className="text-2xl font-header">{`Authorization/Consent for Care/Service`}</h1>
        <p>{`The patient or representative signing below has been informed of the home care treatment and product options available to them and of the selection of providers from which the patient may choose. The patient further authorizes Aeroflow Connect, d/b/a Lactation Link, a subsidiary of Aeroflow Inc. d/b/a Aeroflow Health or Lactation Link, an Aeroflow subsidiary (together “Aeroflow ''), under the direction of the patient’s prescribing physician, to provide home medical equipment, supplies and services. The patient has acknowledged that the Client/Patient Service Agreement has been explained and that the patient understands the information.`}</p>
      </div>
      <div className="space-y-3">
        <h1 className="text-2xl font-bold font-header">{`Assignment of Benefits/Authorization for Payment`}</h1>
        <p>{`All benefits and payments must be made directly to Lactation Link or Aeroflow for any Aeroflow furnished home medical equipment, products, and services. Aeroflow or Lactation Link will seek such benefits and payments on the patient’s behalf. It is understood that, as a courtesy, Aeroflow or Lactation Link will bill Medicaid or other payers and insurer(s) providing coverage, with a copy to Aeroflow or Lactation Link. The patient is responsible for providing all necessary information and for making sure all certification and enrollment requirements are fulfilled. Any changes in insurance coverage must be reported to Aeroflow or Lactation Link within 10 days of the change.`}</p>
      </div>
      <div className="space-y-3">
        <h1 className="text-2xl font-header">{`Release of Information`}</h1>
        <p>{`The patient or representative requests and authorizes Lactation Link or Aeroflow, the prescribing physician, hospital, and any other holder of information relevant to service or equipment provided by Lactation Link or Aeroflow, to release information upon request, to Lactation Link, Aeroflow, any payer source, physician, or any other medical personnel or agency involved with service. The patient also authorizes Lactation Link and Aeroflow to review medical history and payer information for the purpose of providing treatment, equipment, or products.`}</p>
      </div>
      <div className="space-y-3">
        <h1 className="text-2xl font-header">{`Financial Responsibility for Non-Covered Items`}</h1>
        <p>{`By accepting these Terms and Conditions, the patient agrees that if the patient’s insurance does not cover all items ordered, even if the item is one that the patient or the healthcare provider has good reason to think is necessary, Lactation Link/Aeroflow will not charge the insurance company and the patient will have financial responsibility for payment for the non-covered item(s). The patient also agrees that Lactation Link/Aeroflow has offered alternative covered items or services (if any) and the cost of the non-covered item or service, and that the patient has then accepted financial responsibility for the non-covered item or service.`}</p>
      </div>
      <div className="space-y-3">
        <h1 className="text-2xl font-header">{`Financial Responsibility, Arrangements, and Health Insurance`}</h1>
        <p>{`All payment and all sums that may become due for the services or products provided are due at the time services are rendered unless payment arrangements have been approved in advance by Lactation Link or Aeroflow staff. These sums include, but are not limited to, all deductibles, co-payments, out-of-pocket requirements, and non-covered services. If for any reason and to any extent, Lactation Link/Aeroflow does not receive payment from the patient’s payer source because the patient is no longer eligible for coverage or because the service or product is not covered, the patient’s balance will be due in full, within 30 days of receipt of invoice. Aeroflow and Lactation Link accept cash, checks, & most major credit cards. Payments by check may be converted into electronic fund transfers and funds may be debited from your account as soon as the same day payment is received.`}</p>
        <p>{`All patient owed charges not paid within 30 days of billing date shall be assessed late charges and are subject to legally allowable interest charges. In such an event, the patient will be liable for all charges, including collection costs and all attorneys’ fees, as applicable. Balances older than 90 days may be subject to additional collection fees and interest charges of 1.5% per month. We realize that temporary financial problems may affect timely payment to your account. If such problems do arise, we encourage you to contact us promptly for assistance in the management of your account.`}</p>
        <p>{`I understand, and consent to Lactation Link or Aeroflow sending me email or text reminders of any balance I owe.`}</p>
        <p>{`I understand if I have an unpaid balance to Lactation Link/Aeroflow and do not make satisfactory payment arrangements, my account may be placed with an external collection agency.`}</p>
        <p>{`In order for Lactation Link/Aeroflow or their designated external collection agency to service my account, and where not prohibited by applicable law, I agree that Lactation Link/Aeroflow and the designated external collection agency are authorized to (i) contact me by telephone at the telephone number(s) I am providing, including wireless telephone numbers, which could result in charges to me, (ii) contact me by sending text messages (message and data rates may apply) or emails, using any email address I provide.  I understand the methods of contact may include using pre-recorded/artificial voice message and/or use of an automatic dialing device, as applicable.`}</p>
        <p>{`Furthermore, I consent to the designated external collection agency to share personal contact and account related information with third party vendors to communicate account related information via telephone, text, e-mail, and mail notification.`}</p>
      </div>
      <div className="space-y-3">
        <h1 className="text-2xl font-header">{`Scope of Services`}</h1>
        <p>{`Aeroflow has the following services available for patients: breast pumps, lactation support and education, incontinence supplies, catheters, CPAP/BPAP/ASV/RAD equipment & supplies, compression, enteral nutrients, continuous glucose monitoring and supplies, and ostomy.`}</p>
      </div>
      <div className="space-y-3">
        <h1 className="text-2xl font-header">{`Lactation Consulting`}</h1>
        <p>{`By accepting these Terms and Conditions, if I am enrolling in Lactation Consulting services or classes with Lactation Link, I hereby give my consent for a Lactation Link lactation consultant to provide me with lactation consulting. I understand that this could involve working with my baby and me during this and subsequent consultations for my breastfeeding problem/concern. I understand that if the consultation is face-to-face, it may involve touching my breasts and/or nipples for the purposes of assessment, performing an oral digital examination on my baby in order to assess the suck, observation of a breastfeed, and demonstration of techniques and use of equipment that may be necessary to improve breastfeeding.`}</p>
        <p>{`I understand that in the process of participating in Lactation Link virtual group classes, I may be sharing some of my personal health information with other class attendees. I acknowledge that my Lactation Link lactation consultant will provide information on avoiding the sharing of PHI at each class, and if I choose not to make my comments private, I consent to other class members hearing my questions and comments, including possible sharing of PHI.`}</p>
      </div>
      <div className="space-y-3">
        <h1 className="text-2xl font-header">{`Consent for Contact`}</h1>
        <p>{`By signing and submitting this form, the patient or representative consents to receive phone calls, texts, e-mails, and pre-recorded messages from Lactation Link/ Aeroflow or any of its subsidiaries regarding Aeroflow products and services, at the phone number(s) or email address provided; including wireless number if provided. These calls may be generated using automated technology and normal carrier charges may apply.`}</p>
        <p>{`Please be aware that most standard email is not a secure means of communication and your protected health information that may be contained in our emails to you will not be encrypted. This means that there is risk that your protected health information in the emails could be intercepted and read by, or disclosed to, unauthorized third parties. Use of alternative and more secure methods of communication with us, such as telephone, fax or the U.S. Postal Service are available to you. If you do not wish to accept the risks associated with non-secure unencrypted email communications from us containing your protected health information, please indicate that you do not wish to receive such emails from us by contacting us at 844-867-9890. If you agree to receive information from Aeroflow via email or text, you agree to accept the security and privacy risks of this type of communication.`}</p>
        <p>{`I agree that if I consent to SMS notifications regarding my order, text alerts will be sent to the number I provide. I understand that anyone with access to the mobile phone or carrier account associated with the number I have provided will be able to see this information.`}</p>
      </div>
      <div className="space-y-3">
        <h1 className="text-2xl font-header">{`Communication with Minors`}</h1>
        <p>{`We are committed to protecting the privacy of children. Lactation Link and Aeroflow's websites and ordering ability are not directed at users under the age of 18. If you are under the age of 18, you are not permitted to register with Aeroflow, submit personal information, or place orders.`}</p>
      </div>
      <div className="space-y-3">
        <h1 className="text-2xl font-header">{`Patient Complaint/Grievance Process`}</h1>
        <p>{`In the event the patient should become dissatisfied with any portion of their Lactation Link/Aeroflow provided home care experience, a complaint may be lodged with Lactation Link/Aeroflow without concern for reprisal, discrimination, or unreasonable interruption of service. The patient has the right to present questions or grievances to a staff member and to receive a response within a reasonable period of time. For concerns regarding quality of care or other services, please contact Lactation Link or Aeroflow’s office by phone or mail. Grievances can also be reported to the NC Division of Services intake unit, Medicare, or Aeroflow’s Medicare accreditation agency, ACHC. All contact information and our process for handling complaints can be found below.`}</p>
        <p>{`The following procedure details the steps that Aeroflow/Lactation Link will take when a client’s/patient’s complaint/grievance is received:`}</p>
        <p>{`After receiving the concern, the Aeroflow or Lactation Link Customer Service Supervisor will take the following steps:`}</p>
        <ol className="list-decimal px-5 space-y-2">
          <li>
            {`Contact the person making the concern within 5 days if contact has not already been established.`}
          </li>
          <li>
            {`Determine what actions the caller feels should be initiated regarding the concern.`}
          </li>
          <li>
            {`Speak with involved employees and conduct additional training as appropriate.`}
          </li>
          <li>
            {`Attempt to resolve the concern to the client/patient's satisfaction.`}
          </li>
          <li>
            {`Report status of activities to client/patient within2 days following receipt of concern.`}
          </li>
          <li>
            {`Send complaint information to the Compliance Department so they can record it to the Complaint Log.`}
          </li>
          <li>
            {`If the complaint remains unresolved, the Compliance Department will have a department supervisor contact the patient within 5 working days.`}
          </li>
          <li>
            {`Within 14 days the company shall provide written notification to the patient of the results of the investigation.`}
          </li>
        </ol>
        <p>{`Patient Satisfaction Surveys: Aeroflow sends patient surveys via email approximately 5-7 days after the order has been shipped. Data is collected and analyzed on a weekly basis. By signing below, you acknowledge that you have been informed of this patient satisfaction survey procedure.`}</p>
      </div>
      <div className="space-y-3">
        <h1 className="text-2xl font-header">{`Binding Arbitration & Class Action Waiver Agreement`}</h1>
        <p>
          {`I agree that any and all disputes, claims or controversies directly or indirectly arising out of or relating to these Terms and Conditions or any aspect of the relationship between me, on the one hand, and Lactation Link or Aeroflow, on the other hand, whether based in contract, tort, statute, fraud, misrepresentation or any other legal theory – including, but not limited to, claims relating to my account, Aeroflow products and services, and communications from or on behalf of Aeroflow (“Disputes”) – shall be submitted to JAMS, or its successor, for confidential, final, and binding arbitration to be resolved by a single arbitrator. As of January 3, 2024, information regarding JAMS and its arbitration processes, rules, and procedures is available at the website: `}
          <a href="https://www.jamsadr.com" target="_blank" rel="noreferrer">
            {`https://www.jamsadr.com`}
          </a>
          {`. I further agree that the arbitration will take place on an individual basis, that class arbitrations and class actions are not permitted, and that I agree to give up the ability to participate in any class action. For avoidance of doubt, I am agreeing to give up the ability to bring a lawsuit in court (except small claims discussed below); and I am giving up the ability to bring or participate in a class action in any form or forum, even if my Dispute is determined not to be subject to arbitration.`}
        </p>
        <p>{`I agree that I will send notice of my Dispute to 3165 Sweeten Creek Rd, Asheville, North Carolina 28803-2115, and that I must wait 30 days after notice is received by Aeroflow to initiate arbitration. If I initiate arbitration, I will do so in accordance with JAMS Streamlined Rules for Arbitration (“Rules”). The JAMS arbitrator shall resolve the Dispute and is empowered with the exclusive authority to resolve any dispute relating to the interpretation, applicability or enforceability of these terms or the formation of this agreement and the Terms & Conditions, including the arbitrability of any dispute and any contention that all or any part of this agreement is unconscionable, void, or voidable. Any arbitration conducted pursuant to the terms of this agreement shall be governed by the Federal Arbitration Act (9 U.S.C.§§ 1–16). The party that prevails in the arbitration shall be entitled to recover from the other party all reasonable attorneys’ fees, costs and expenses incurred by the prevailing party in connection with the arbitration; except that this provision shall not apply if I live in California.`}</p>
        <p>{`The arbitration will be administered by JAMS under its Rules and will comply with the JAMS Consumer Minimum Standards (which are incorporated by reference). Notwithstanding the foregoing, I understand that I may instead litigate a Dispute in small claims court if the Dispute meets the requirements to be heard in small claims court.`}</p>
        <p>{`I UNDERSTAND THAT I AM WAIVING ANY RIGHT I MIGHT OTHERWISE HAVE TO A TRIAL BEFORE A JUDGE OR JURY. I understand that upon initiating the arbitration in accordance with JAMS rules, I must send a copy of the Demand for Arbitration via U.S. Mail to 3165 Sweeten Creek Rd, Asheville, North Carolina 28803-2115.`}</p>
        <p>{`The formation, existence, construction, performance, and validity of this agreement shall be governed by the laws of the State of North Carolina and the United States, without reference to choice or conflict of law principles.`}</p>
        <p>{`You agree that, for any disputes that have been determined by a court of law or an arbitrator as not being subject to arbitration and are not brought in small claims court, this Agreement, for all purposes, shall be governed and construed in accordance with the laws of the State of North Carolina and any such dispute must be brought in a state or federal court located in the Western District of North Carolina, United States District Court. In addition, both parties agree to submit to the exclusive personal jurisdiction and venue of such courts. As detailed above, however, no such dispute may be brought on a class or representative basis.`}</p>
      </div>
    </div>
  )
}

export default TermsAndConditions