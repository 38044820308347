import React from 'react'
import { HER_HUB_PATH } from 'views/HerHub/utils/constants'
import { useNavigate } from 'react-router-dom'

export type ThankYouPageProps = {
  header: string,
  body: string,
  signOff: string
  signature: string
}

function ThankYouPage({ header, body, signOff, signature }: ThankYouPageProps ): JSX.Element {
  const navigate = useNavigate()

  return (
    <div className="py-4 px-4 flex flex-col w-full items-center justify-center max-w-2xl mx-auto space-y-3">
      <div className="p-5 flex flex-col items-center justify-center gap-3">
        <h1 className="text-3xl font-header mb-3 text-center">{header}</h1>
        <p className="text-center text-lg">{body}</p>
        <p className="text-center text-lg">{signOff}</p>
        <p className="text-center mt-3">{`- ${signature}`}</p>

        <p
          className="text-lg my-10 a"
          onClick={() => {
            navigate( HER_HUB_PATH.concat( window.location.search ) )
          }}
        >
          {`Go back to my Account`}
        </p>
      </div>
    </div>
  )
}

export default ThankYouPage