export const backupStatePayerList =
[
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `AL`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 1173,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Alliant Health Plans`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 99,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Blue Cross and Blue Shield of Alabama`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `AL`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `AL`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `AL`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 1903,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Viva Health`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 1228,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Medpartners`
  },
  {
    "parent_payer_pk": 407,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Medicare`
  },
  {
    "parent_payer_pk": 420,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Moda Health`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 4695,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Employee Benefit Management Services - EBMS`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `AK`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 2688,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Premera Blue Cross`
  },
  {
    "parent_payer_pk": 109,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Blue Cross Blue Shield of Arizona`
  },
  {
    "parent_payer_pk": 27282,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Arizona Complete Health - Medicaid`
  },
  {
    "parent_payer_pk": 27627,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Mercy Care Plan - Medicaid`
  },
  {
    "parent_payer_pk": 27228,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Steward Health Choice - Medicaid`
  },
  {
    "parent_payer_pk": 21149,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Banner University Health Plans`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8488,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Regence BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 1680,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `AmeriBen`
  },
  {
    "parent_payer_pk": 1101,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Excellus BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 371,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Mayo Clinic Health Solutions`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 64,
    "state_association_abbreviation": `AR`,
    "friendly_name": `Arkansas Blue Cross and Blue Shield`
  },
  {
    "parent_payer_pk": 486,
    "state_association_abbreviation": `AR`,
    "friendly_name": `Qualchoice`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `AR`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `AR`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 2877,
    "state_association_abbreviation": `AR`,
    "friendly_name": `Health scope`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `AR`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `AR`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `AR`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 693,
    "state_association_abbreviation": `AR`,
    "friendly_name": `WebTPA`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `AR`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `AR`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 1228,
    "state_association_abbreviation": `AR`,
    "friendly_name": `Medpartners`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `AR`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `AR`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 22953,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Kaiser Foundation Health Plan`
  },
  {
    "parent_payer_pk": 722,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Health Net`
  },
  {
    "parent_payer_pk": 130,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Blue Shield of California`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 4695,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Employee Benefit Management Services - EBMS`
  },
  {
    "parent_payer_pk": 693,
    "state_association_abbreviation": `CA`,
    "friendly_name": `WebTPA`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `CA`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 383,
    "state_association_abbreviation": `CA`,
    "friendly_name": `MedCost`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `CA`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `CA`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 19807,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Medicaid - Health First`
  },
  {
    "parent_payer_pk": 22953,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Kaiser Foundation Health Plan`
  },
  {
    "parent_payer_pk": 170,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Cofinity`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 4695,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Employee Benefit Management Services - EBMS`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `CO`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 710,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Rocky Mountain Health Plans`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `CO`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 1680,
    "state_association_abbreviation": `CO`,
    "friendly_name": `AmeriBen`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `CO`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 1844,
    "state_association_abbreviation": `CT`,
    "friendly_name": `Harvard Pilgrim Health Care`
  },
  {
    "parent_payer_pk": 178,
    "state_association_abbreviation": `CT`,
    "friendly_name": `Connecticare`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `CT`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `CT`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `CT`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `CT`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `CT`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `CT`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `CT`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 1784,
    "state_association_abbreviation": `CT`,
    "friendly_name": `United Healthcare Oxford`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `CT`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `CT`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 17374,
    "state_association_abbreviation": `DE`,
    "friendly_name": `AmeriHealth Caritas`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `DE`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `DE`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `DE`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `DE`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `DE`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `DE`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `DE`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `DE`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 3059,
    "state_association_abbreviation": `DE`,
    "friendly_name": `Independence Bluecross`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `DE`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `DE`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `DE`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 501,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Simply Healthcare`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 3049,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Prestige Health Choice`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 4642,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Wellcare`
  },
  {
    "parent_payer_pk": 70,
    "state_association_abbreviation": `FL`,
    "friendly_name": `AvMed`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `FL`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 2866,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Magellan Complete Care`
  },
  {
    "parent_payer_pk": 693,
    "state_association_abbreviation": `FL`,
    "friendly_name": `WebTPA`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 226,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Florida Blue`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `FL`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 424,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Molina healthcare`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 1101,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Excellus BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 371,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Mayo Clinic Health Solutions`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `FL`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `FL`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 2877,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Health scope`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 10471,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Sunshine Health Plan`
  },
  {
    "parent_payer_pk": 22953,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Kaiser Foundation Health Plan`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 4642,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Wellcare`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `GA`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `GA`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 693,
    "state_association_abbreviation": `GA`,
    "friendly_name": `WebTPA`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 284,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Healthgram`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `GA`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 1173,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Alliant Health Plans`
  },
  {
    "parent_payer_pk": 4789,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Peachcare`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 22,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Amerigroup`
  },
  {
    "parent_payer_pk": 165,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Caresource`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `GA`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `HI`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `HI`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `HI`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `HI`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `HI`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `HI`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 424,
    "state_association_abbreviation": `ID`,
    "friendly_name": `Molina healthcare`
  },
  {
    "parent_payer_pk": 673,
    "state_association_abbreviation": `ID`,
    "friendly_name": `Blue Cross of Idaho`
  },
  {
    "parent_payer_pk": 1405,
    "state_association_abbreviation": `ID`,
    "friendly_name": `SelectHealth`
  },
  {
    "parent_payer_pk": 12477,
    "state_association_abbreviation": `ID`,
    "friendly_name": `PacificSource Health Plans`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `ID`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `ID`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `ID`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `ID`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `ID`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `ID`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `ID`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `ID`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8488,
    "state_association_abbreviation": `ID`,
    "friendly_name": `Regence BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 16115,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Blue Cross and Blue Shield of Illinois`
  },
  {
    "parent_payer_pk": 5514,
    "state_association_abbreviation": `IL`,
    "friendly_name": `CountyCare Health Plan`
  },
  {
    "parent_payer_pk": 13066,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Health Alliance Medical Plans`
  },
  {
    "parent_payer_pk": 285,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Healthlink`
  },
  {
    "parent_payer_pk": 4642,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Wellcare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `IL`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `IL`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 424,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Molina healthcare`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 1164,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Illinicare health`
  },
  {
    "parent_payer_pk": 1784,
    "state_association_abbreviation": `IL`,
    "friendly_name": `United Healthcare Oxford`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 413,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Meridian Health Plan`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `IL`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 10817,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Next Level Health`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `IL`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 9567,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Indiana university health plans`
  },
  {
    "parent_payer_pk": 2095,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Parkview Signature Care`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 2328,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Managed Health Services`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `IN`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 1678,
    "state_association_abbreviation": `IN`,
    "friendly_name": `MDwise`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `IN`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 2340,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Medben`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `IN`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 165,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Caresource`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `IN`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 22,
    "state_association_abbreviation": `IA`,
    "friendly_name": `Amerigroup`
  },
  {
    "parent_payer_pk": 774,
    "state_association_abbreviation": `IA`,
    "friendly_name": `Medica`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `IA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `IA`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `IA`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `IA`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 5574,
    "state_association_abbreviation": `IA`,
    "friendly_name": `Wellmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `IA`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `IA`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `IA`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `IA`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `IA`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 27731,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Blue Cross and Blue Shield of Kansas`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 774,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Medica`
  },
  {
    "parent_payer_pk": 22,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Amerigroup`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `KS`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `KS`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `KS`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 4642,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Wellcare`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `KY`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `KY`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 2340,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Medben`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `KY`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 1081,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Humana Caresource`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 15810,
    "state_association_abbreviation": `LA`,
    "friendly_name": `Blue Cross and Blue Shield of Louisiana`
  },
  {
    "parent_payer_pk": 23648,
    "state_association_abbreviation": `LA`,
    "friendly_name": `Louisiana Healthcare Connections`
  },
  {
    "parent_payer_pk": 27733,
    "state_association_abbreviation": `LA`,
    "friendly_name": `Healthy Blue Louisiana Medicaid`
  },
  {
    "parent_payer_pk": 17374,
    "state_association_abbreviation": `LA`,
    "friendly_name": `AmeriHealth Caritas`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `LA`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `LA`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `LA`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `LA`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `LA`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `LA`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `LA`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 693,
    "state_association_abbreviation": `LA`,
    "friendly_name": `WebTPA`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `LA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `LA`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `LA`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 1844,
    "state_association_abbreviation": `ME`,
    "friendly_name": `Harvard Pilgrim Health Care`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `ME`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `ME`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `ME`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `ME`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `ME`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `ME`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `ME`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `ME`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 22953,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Kaiser Foundation Health Plan`
  },
  {
    "parent_payer_pk": 19533,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Priority Partners`
  },
  {
    "parent_payer_pk": 22802,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Maryland Physicians care`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `MD`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 720,
    "state_association_abbreviation": `MD`,
    "friendly_name": `CareFirst BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 22,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Amerigroup`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `MD`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `MD`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 1269,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Blue Cross Blue Shield of Massachusetts`
  },
  {
    "parent_payer_pk": 878,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Tufts Health Plan`
  },
  {
    "parent_payer_pk": 1844,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Harvard Pilgrim Health Care`
  },
  {
    "parent_payer_pk": 3295,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Boston Medical Center HealthNet Plan`
  },
  {
    "parent_payer_pk": 7452,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Fallon Health`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 612,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Health New England`
  },
  {
    "parent_payer_pk": 18097,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Neighborhood health Plan`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `MA`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `MA`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 117,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Blue Cross Blue Shield of Michigan`
  },
  {
    "parent_payer_pk": 477,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Priority Health`
  },
  {
    "parent_payer_pk": 15624,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Molina`
  },
  {
    "parent_payer_pk": 3597,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Health Alliance Plan`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 7433,
    "state_association_abbreviation": `MI`,
    "friendly_name": `McLaren Health Plans`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `MI`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 1693,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Upper Peninsula Health Plan`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `MI`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 413,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Meridian Health Plan`
  },
  {
    "parent_payer_pk": 3150,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Total Health Care`
  },
  {
    "parent_payer_pk": 2608,
    "state_association_abbreviation": `MI`,
    "friendly_name": `ASR Health Benefits`
  },
  {
    "parent_payer_pk": 170,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Cofinity`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `MI`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 617,
    "state_association_abbreviation": `MN`,
    "friendly_name": `Blue Cross Blue Shield of Minnesota`
  },
  {
    "parent_payer_pk": 291,
    "state_association_abbreviation": `MN`,
    "friendly_name": `HealthPartners`
  },
  {
    "parent_payer_pk": 774,
    "state_association_abbreviation": `MN`,
    "friendly_name": `Medica`
  },
  {
    "parent_payer_pk": 532,
    "state_association_abbreviation": `MN`,
    "friendly_name": `UCare`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `MN`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 491,
    "state_association_abbreviation": `MN`,
    "friendly_name": `Sanford Health Plan`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `MN`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 371,
    "state_association_abbreviation": `MN`,
    "friendly_name": `Mayo Clinic Health Solutions`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `MN`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `MN`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `MN`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 4763,
    "state_association_abbreviation": `MN`,
    "friendly_name": `PreferredOne Health Insurance`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `MN`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MN`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `MN`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `MN`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 15624,
    "state_association_abbreviation": `MS`,
    "friendly_name": `Molina`
  },
  {
    "parent_payer_pk": 716,
    "state_association_abbreviation": `MS`,
    "friendly_name": `Magnolia health plan`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `MS`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `MS`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `MS`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MS`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `MS`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `MS`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `MS`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `MS`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 9893,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Blue Cross and Blue Shield of Kansas City`
  },
  {
    "parent_payer_pk": 4642,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Wellcare`
  },
  {
    "parent_payer_pk": 2766,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Home State Health`
  },
  {
    "parent_payer_pk": 285,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Healthlink`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `MO`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `MO`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `MO`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 4695,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Employee Benefit Management Services - EBMS`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `MO`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `MT`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `MT`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8488,
    "state_association_abbreviation": `MT`,
    "friendly_name": `Regence BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `MT`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `MT`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MT`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 4695,
    "state_association_abbreviation": `MT`,
    "friendly_name": `Employee Benefit Management Services - EBMS`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `MT`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `MT`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 34500,
    "state_association_abbreviation": `MT`,
    "friendly_name": `Blue Cross and Blue Shield of Montana`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 27295,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Blue Cross and Blue Shield of Nebraska`
  },
  {
    "parent_payer_pk": 774,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Medica`
  },
  {
    "parent_payer_pk": 9357,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Nebraska total care`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `NE`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `NE`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 4642,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Wellcare`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `NE`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 12777,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Silversummit Healthplan`
  },
  {
    "parent_payer_pk": 22,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Amerigroup`
  },
  {
    "parent_payer_pk": 1690,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Sierra Health and Life`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `NV`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `NV`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 130,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Blue Shield of California`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `NV`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 2877,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Health scope`
  },
  {
    "parent_payer_pk": 1844,
    "state_association_abbreviation": `NH`,
    "friendly_name": `Harvard Pilgrim Health Care`
  },
  {
    "parent_payer_pk": 22136,
    "state_association_abbreviation": `NH`,
    "friendly_name": `NH Healthy Families`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `NH`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `NH`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `NH`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `NH`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NH`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `NH`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `NH`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `NH`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 28,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `AmeriHealth`
  },
  {
    "parent_payer_pk": 4642,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Wellcare`
  },
  {
    "parent_payer_pk": 22,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Amerigroup`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 2824,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Oxford Health Plans`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 3059,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Independence Bluecross`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 4457,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Horizon Bluecross Blueshield`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 18912,
    "state_association_abbreviation": `NM`,
    "friendly_name": `Blue Cross and Blue Shield of New Mexico`
  },
  {
    "parent_payer_pk": 15624,
    "state_association_abbreviation": `NM`,
    "friendly_name": `Molina`
  },
  {
    "parent_payer_pk": 474,
    "state_association_abbreviation": `NM`,
    "friendly_name": `Presbyterian Health Plan`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `NM`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `NM`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `NM`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `NM`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NM`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `NM`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `NM`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `NM`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `NM`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 27686,
    "state_association_abbreviation": `NY`,
    "friendly_name": `BlueCross BlueShield of Western New York`
  },
  {
    "parent_payer_pk": 4642,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Wellcare`
  },
  {
    "parent_payer_pk": 15624,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Molina`
  },
  {
    "parent_payer_pk": 25023,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Healthfirst`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 624,
    "state_association_abbreviation": `NY`,
    "friendly_name": `CDPHP`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `NY`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 1101,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Excellus BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 218,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Fidelis Care`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 11,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Affinity Health Plan`
  },
  {
    "parent_payer_pk": 6451,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Empire BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `NY`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `NY`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 202,
    "state_association_abbreviation": `NY`,
    "friendly_name": `EmblemHealth`
  },
  {
    "parent_payer_pk": 2162,
    "state_association_abbreviation": `NY`,
    "friendly_name": `MVP Health Care`
  },
  {
    "parent_payer_pk": 3521,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Metroplus Health plan`
  },
  {
    "parent_payer_pk": 1721,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Independent Health Association`
  },
  {
    "parent_payer_pk": 948,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Univera Healthcare`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `NC`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `NC`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `NC`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `NC`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 693,
    "state_association_abbreviation": `NC`,
    "friendly_name": `WebTPA`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `NC`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `NC`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 383,
    "state_association_abbreviation": `NC`,
    "friendly_name": `MedCost`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `NC`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 727,
    "state_association_abbreviation": `NC`,
    "friendly_name": `Optima`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `NC`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `NC`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `NC`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 27237,
    "state_association_abbreviation": `NC`,
    "friendly_name": `Blue Cross and Blue Shield of North Carolina`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NC`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 122,
    "state_association_abbreviation": `ND`,
    "friendly_name": `Blue Cross Blue Shield of North Dakota`
  },
  {
    "parent_payer_pk": 774,
    "state_association_abbreviation": `ND`,
    "friendly_name": `Medica`
  },
  {
    "parent_payer_pk": 491,
    "state_association_abbreviation": `ND`,
    "friendly_name": `Sanford Health Plan`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `ND`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `ND`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `ND`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `ND`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `ND`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `ND`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `ND`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `ND`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 748,
    "state_association_abbreviation": `OH`,
    "friendly_name": `AultCare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `OH`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 1683,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Buckeye Community Health Plan`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 424,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Molina healthcare`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `OH`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 2340,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Medben`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 720,
    "state_association_abbreviation": `OH`,
    "friendly_name": `CareFirst BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 165,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Caresource`
  },
  {
    "parent_payer_pk": 8523,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Paramount Advantage - OH`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `OH`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 1867,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Medical Mutual`
  },
  {
    "parent_payer_pk": 727,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Optima`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `OH`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 27274,
    "state_association_abbreviation": `OK`,
    "friendly_name": `Blue Cross and Blue Shield of Oklahoma`
  },
  {
    "parent_payer_pk": 774,
    "state_association_abbreviation": `OK`,
    "friendly_name": `Medica`
  },
  {
    "parent_payer_pk": 177,
    "state_association_abbreviation": `OK`,
    "friendly_name": `CommunityCare`
  },
  {
    "parent_payer_pk": 9642,
    "state_association_abbreviation": `OK`,
    "friendly_name": `Health Choice of Oklahoma`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `OK`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `OK`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `OK`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `OK`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `OK`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `OK`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `OK`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 693,
    "state_association_abbreviation": `OK`,
    "friendly_name": `WebTPA`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `OK`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `OK`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `OK`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `OK`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 22953,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Kaiser Foundation Health Plan`
  },
  {
    "parent_payer_pk": 3472,
    "state_association_abbreviation": `OR`,
    "friendly_name": `CareOregon`
  },
  {
    "parent_payer_pk": 420,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Moda Health`
  },
  {
    "parent_payer_pk": 12477,
    "state_association_abbreviation": `OR`,
    "friendly_name": `PacificSource Health Plans`
  },
  {
    "parent_payer_pk": 8488,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Regence BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `OR`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `OR`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 482,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Providence Health Plan`
  },
  {
    "parent_payer_pk": 4695,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Employee Benefit Management Services - EBMS`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `OR`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 25619,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Jackson Care connect`
  },
  {
    "parent_payer_pk": 5551,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Eastern Oregon CCO`
  },
  {
    "parent_payer_pk": 281,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Healthcare Management administrators`
  },
  {
    "parent_payer_pk": 1117,
    "state_association_abbreviation": `PA`,
    "friendly_name": `UPMC`
  },
  {
    "parent_payer_pk": 17374,
    "state_association_abbreviation": `PA`,
    "friendly_name": `AmeriHealth Caritas`
  },
  {
    "parent_payer_pk": 240,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Geisinger Health Plan`
  },
  {
    "parent_payer_pk": 236,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Gateway Health Plan`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `PA`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `PA`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 1101,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Excellus BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3059,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Independence Bluecross`
  },
  {
    "parent_payer_pk": 676,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Capital BlueCross`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `PA`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `PA`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 18097,
    "state_association_abbreviation": `RI`,
    "friendly_name": `Neighborhood health Plan`
  },
  {
    "parent_payer_pk": 878,
    "state_association_abbreviation": `RI`,
    "friendly_name": `Tufts Health Plan`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `RI`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `RI`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `RI`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `RI`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `RI`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `RI`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `RI`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `RI`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 9827,
    "state_association_abbreviation": `SC`,
    "friendly_name": `BlueCross BlueShield of South Carolina`
  },
  {
    "parent_payer_pk": 4642,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Wellcare`
  },
  {
    "parent_payer_pk": 17374,
    "state_association_abbreviation": `SC`,
    "friendly_name": `AmeriHealth Caritas`
  },
  {
    "parent_payer_pk": 22,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Amerigroup`
  },
  {
    "parent_payer_pk": 1824,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Absolute Total Care`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 383,
    "state_association_abbreviation": `SC`,
    "friendly_name": `MedCost`
  },
  {
    "parent_payer_pk": 1101,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Excellus BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `SC`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 424,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Molina healthcare`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 27636,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Firsth Choice by Select Health`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `SC`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `SC`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 774,
    "state_association_abbreviation": `SD`,
    "friendly_name": `Medica`
  },
  {
    "parent_payer_pk": 491,
    "state_association_abbreviation": `SD`,
    "friendly_name": `Sanford Health Plan`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `SD`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `SD`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `SD`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `SD`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `SD`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 5574,
    "state_association_abbreviation": `SD`,
    "friendly_name": `Wellmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `SD`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `SD`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `SD`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3971,
    "state_association_abbreviation": `TN`,
    "friendly_name": `BlueCross BlueShield of Tennessee`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `TN`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `TN`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `TN`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 2877,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Health scope`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 22,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Amerigroup`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 19702,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Blue Cross and Blue Shield of Texas`
  },
  {
    "parent_payer_pk": 1765,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Parkland Community Health Plan`
  },
  {
    "parent_payer_pk": 27701,
    "state_association_abbreviation": `TX`,
    "friendly_name": `El Paso First Health Plan`
  },
  {
    "parent_payer_pk": 3892,
    "state_association_abbreviation": `TX`,
    "friendly_name": `FirstCare Health Plans`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 1725,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Driscoll Health Plan`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 4689,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Boon-Chapman`
  },
  {
    "parent_payer_pk": 424,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Molina healthcare`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `TX`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 22,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Amerigroup`
  },
  {
    "parent_payer_pk": 186,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Coresource`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `TX`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `TX`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 5345,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Cook Children Health Plan`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `TX`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `UT`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `UT`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 424,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Molina healthcare`
  },
  {
    "parent_payer_pk": 6775,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Healthy U`
  },
  {
    "parent_payer_pk": 8599,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Public Employee Health Program`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 8488,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Regence BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `UT`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3631,
    "state_association_abbreviation": `UT`,
    "friendly_name": `SelectHealth Community Care`
  },
  {
    "parent_payer_pk": 27544,
    "state_association_abbreviation": `VT`,
    "friendly_name": `Blue Cross and Blue Shield of Vermont`
  },
  {
    "parent_payer_pk": 2162,
    "state_association_abbreviation": `VT`,
    "friendly_name": `MVP Health Care`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `VT`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `VT`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `VT`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `VT`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `VT`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `VT`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `VT`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `VT`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 22953,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Kaiser Foundation Health Plan`
  },
  {
    "parent_payer_pk": 2866,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Magellan Complete Care`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `VA`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 727,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Optima`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 10509,
    "state_association_abbreviation": `VA`,
    "friendly_name": `VA Premier Health Plan`
  },
  {
    "parent_payer_pk": 383,
    "state_association_abbreviation": `VA`,
    "friendly_name": `MedCost`
  },
  {
    "parent_payer_pk": 720,
    "state_association_abbreviation": `VA`,
    "friendly_name": `CareFirst BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `VA`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `VA`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `VA`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 22953,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Kaiser Foundation Health Plan`
  },
  {
    "parent_payer_pk": 22,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Amerigroup`
  },
  {
    "parent_payer_pk": 11253,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Community Health Plan of Washington`
  },
  {
    "parent_payer_pk": 8052,
    "state_association_abbreviation": `WA`,
    "friendly_name": `First Choice Health Administrators`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `WA`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `WA`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 424,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Molina healthcare`
  },
  {
    "parent_payer_pk": 8488,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Regence BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 482,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Providence Health Plan`
  },
  {
    "parent_payer_pk": 4695,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Employee Benefit Management Services - EBMS`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `WA`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 13066,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Health Alliance Medical Plans`
  },
  {
    "parent_payer_pk": 2688,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Premera Blue Cross`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `WA`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 4846,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Ambetter`
  },
  {
    "parent_payer_pk": 542,
    "state_association_abbreviation": `WV`,
    "friendly_name": `Unicare`
  },
  {
    "parent_payer_pk": 27532,
    "state_association_abbreviation": `WV`,
    "friendly_name": `The Health Plan - Upper Ohio Valley`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `WV`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `WV`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `WV`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `WV`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `WV`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `WV`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `WV`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 9,
    "state_association_abbreviation": `WV`,
    "friendly_name": `Aetna Better Health`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `WV`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `WV`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 291,
    "state_association_abbreviation": `WI`,
    "friendly_name": `HealthPartners`
  },
  {
    "parent_payer_pk": 774,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Medica`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `WI`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 424,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Molina healthcare`
  },
  {
    "parent_payer_pk": 644,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Forward Health`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `WI`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 8564,
    "state_association_abbreviation": `WI`,
    "friendly_name": `WPS Health Insurance`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 4124,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Network Health Plan`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `WI`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 34466,
    "state_association_abbreviation": `WY`,
    "friendly_name": `Blue Cross and Blue Shield of Wyoming`
  },
  {
    "parent_payer_pk": 8558,
    "state_association_abbreviation": `WY`,
    "friendly_name": `Allied Benefit Solutions`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `WY`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `WY`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 4695,
    "state_association_abbreviation": `WY`,
    "friendly_name": `Employee Benefit Management Services - EBMS`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `WY`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 302,
    "state_association_abbreviation": `WY`,
    "friendly_name": `Highmark BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `WY`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `WY`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `WY`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 3782,
    "state_association_abbreviation": `WY`,
    "friendly_name": `All Savers Health Plans`
  },
  {
    "parent_payer_pk": 414,
    "state_association_abbreviation": `WY`,
    "friendly_name": `Meritain Health`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `WY`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 17374,
    "state_association_abbreviation": `DC`,
    "friendly_name": `AmeriHealth Caritas`
  },
  {
    "parent_payer_pk": 22953,
    "state_association_abbreviation": `DC`,
    "friendly_name": `Kaiser Foundation Health Plan`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `DC`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 9936,
    "state_association_abbreviation": `DC`,
    "friendly_name": `United Healthcare (UHC)`
  },
  {
    "parent_payer_pk": 316,
    "state_association_abbreviation": `DC`,
    "friendly_name": `Humana`
  },
  {
    "parent_payer_pk": 522,
    "state_association_abbreviation": `DC`,
    "friendly_name": `Tricare`
  },
  {
    "parent_payer_pk": 541,
    "state_association_abbreviation": `DC`,
    "friendly_name": `UMR`
  },
  {
    "parent_payer_pk": 8,
    "state_association_abbreviation": `DC`,
    "friendly_name": `Aetna`
  },
  {
    "parent_payer_pk": 720,
    "state_association_abbreviation": `DC`,
    "friendly_name": `CareFirst BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 2824,
    "state_association_abbreviation": `DC`,
    "friendly_name": `Oxford Health Plans`
  },
  {
    "parent_payer_pk": 55,
    "state_association_abbreviation": `DC`,
    "friendly_name": `Anthem BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 3840,
    "state_association_abbreviation": `DC`,
    "friendly_name": `United Healthcare StudentResources`
  },
  {
    "parent_payer_pk": 35392,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Medicaid/Denali Care`
  },
  {
    "parent_payer_pk": 27286,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Care First Arizona a Wellcare Company`
  },
  {
    "parent_payer_pk": 1957,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Inland Empire Health Plan - IEHP`
  },
  {
    "parent_payer_pk": 1957,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Inland Empire Health Plan - IEHP`
  },
  {
    "parent_payer_pk": 14099,
    "state_association_abbreviation": `CA`,
    "friendly_name": `United Healthcare (UHC) Community Plan`
  },
  {
    "parent_payer_pk": 14099,
    "state_association_abbreviation": `FL`,
    "friendly_name": `United Healthcare (UHC) Community Plan`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `AK`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 5282,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Alabama Medicaid`
  },
  {
    "parent_payer_pk": 3930,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Arise Health Plan`
  },
  {
    "parent_payer_pk": 67,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Asuris Northwest Health`
  },
  {
    "parent_payer_pk": 34800,
    "state_association_abbreviation": `MS`,
    "friendly_name": `Blue Cross and Blue Shield of Mississippi`
  },
  {
    "parent_payer_pk": 34801,
    "state_association_abbreviation": `RI`,
    "friendly_name": `Blue Cross and Blue Shield of Rhode Island`
  },
  {
    "parent_payer_pk": 27308,
    "state_association_abbreviation": `TN`,
    "friendly_name": `BlueCareTenncare`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `AL`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `AR`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `CA`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `AK`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `CT`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `DE`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `DC`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `FL`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `GA`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `HI`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `ID`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `IL`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `IN`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `IA`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `KS`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `KY`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `LA`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `ME`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `MD`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `MA`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `MI`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `MS`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `MN`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `MO`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `CO`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `MT`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `NE`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `NV`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `NH`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `NM`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `NY`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `NC`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `ND`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `OH`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `OK`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `OR`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `PA`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `RI`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `SC`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `SD`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `TN`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `TX`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `UT`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `VT`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `VA`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `WA`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `WV`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `WI`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 35391,
    "state_association_abbreviation": `WY`,
    "friendly_name": `BlueCross BlueShield`
  },
  {
    "parent_payer_pk": 27579,
    "state_association_abbreviation": `NY`,
    "friendly_name": `BlueShield of Northeastern New York`
  },
  {
    "parent_payer_pk": 8559,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Care Wisconsin`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `AL`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `AK`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `AZ`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `AR`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `CA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `CO`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `CT`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `DE`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `DC`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `FL`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `GA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `HI`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `ID`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `IL`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `IN`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `IA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `KY`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `LA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `ME`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MD`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MI`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MN`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MS`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `MT`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NE`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NV`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NH`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NJ`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NM`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NY`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `NC`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `ND`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `OH`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `OK`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `PA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `RI`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `SC`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `SD`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `TN`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `TX`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `UT`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `VT`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `VA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `WA`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `WV`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 168,
    "state_association_abbreviation": `WY`,
    "friendly_name": `Cigna`
  },
  {
    "parent_payer_pk": 7061,
    "state_association_abbreviation": `KS`,
    "friendly_name": `Cerner HealthPlan`
  },
  {
    "parent_payer_pk": 7061,
    "state_association_abbreviation": `MO`,
    "friendly_name": `Cerner HealthPlan`
  },
  {
    "parent_payer_pk": 11521,
    "state_association_abbreviation": `WI`,
    "friendly_name": `Childrens Community Health Plan`
  },
  {
    "parent_payer_pk": 34486,
    "state_association_abbreviation": `TX`,
    "friendly_name": `CHRISTUS Health Plan`
  },
  {
    "parent_payer_pk": 2618,
    "state_association_abbreviation": `OR`,
    "friendly_name": `Columbia Pacific CCO`
  }
]