import React from 'react'
import { Bundle, Class } from 'types/shopScheduleTypes'

type CurrentClassTab = {
  classInfo: Class;
  bundleDetails?: Bundle;
}

function CurrentClassTab({ classInfo, bundleDetails } : CurrentClassTab ) : JSX.Element {

  return (
    <div className="flex flex-col">
      {
        bundleDetails?.bundle_title &&
        <div className="bg-secondary mb-5 rounded-t-lg font-light">
          <p className="text-lg md:text-xl text-white text-center py-1">{bundleDetails.bundle_title}</p>
        </div>
      }
      <div className="flex gap-5 items-center justify-center px-5">
        <img
          src={classInfo.small_image_path}
          alt="current class image"
          width={80}
          height={80}
        />
        <div className="flex flex-col items-start">
          <p className="text-lg md:text-xl text-left">{classInfo.class_title}</p>
          {
            classInfo?.class_duration &&
          <p className="font-light text-lg">{`${classInfo.class_duration} min${classInfo.class_duration > 1 ? `s` : ``}`}</p>
          }
        </div>
      </div>
    </div>
  )
}

export default CurrentClassTab