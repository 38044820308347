import { Rating, styled } from '@mui/material'
import { TextInput, Validators } from 'components/Forms/components'
import tailwindColors from 'config/tailwindColors'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { submitPostClassSurvey } from './utils'
import RingLoader from 'components/RingLoader'
import { reportToSentry } from 'utils/reportToSentry'
import RatingButtonGroup from 'components/ButtonGroup/RatingButtonGroup'

export type PostClassSurveyValues = {
  recommendScore: number | null
  qualityScore: number | null
  deliverScore: number | null
  betterPrepared: string
  betterExperienceFreeform?: string
  emailAddress?: string
  classId: string
}

const searchParams = new URLSearchParams( window.location.search )
const classId = searchParams.get( `class_id` )

function PostClassSurveyForm() : JSX.Element {

  const [ loading, setLoading ] = useState<boolean>( false )
  const [ error, setError ] = useState<string | null>( null )
  const navigate = useNavigate()

  const [ values, setValues ] = useState<PostClassSurveyValues>({
    recommendScore: null,
    qualityScore: null,
    deliverScore: null,
    betterPrepared: ``,
    betterExperienceFreeform: ``,
    emailAddress: ``,
    classId: classId ?? ``
  })

  useEffect( () => {
    if ( !classId ) return navigate( `/my-account${window.location.search}` )
  }, [] )

  const onChange = ( key: string, value: string | number | null ) => {
    setError( null )
    const updatedValues = {
      ...values,
      [key]: value
    }

    setValues( updatedValues )
  }

  const handleSubmitForm = async () => {
    // remove any empty values
    const postData = values
    Object.keys( postData ).forEach( key => {
      if ( !( postData as { [key: string] : string | number })[key] ) delete ( postData as { [key: string] : string | number })[key]
    })

    if ( !postData.recommendScore || !postData.qualityScore || !postData.deliverScore ) return setError( `Please fill out all required fields.` )
    if ( postData.emailAddress && !Validators.email( postData.emailAddress ) ) return setError( `Please enter a valid email address.` )

    setLoading( true )

    const surveySubmissionStatus = await submitPostClassSurvey( postData )
      .catch( ( error ) => {
        reportToSentry( new Error( `Error submitting post class survey`, {
          cause: error
        }), {
          classId,
          data: JSON.stringify( postData )
        })
        setLoading( false )

        return setError( `There was an error submitting your survey responses. Please try again.` )

      })

    setLoading( false )

    if ( surveySubmissionStatus ) return navigate( `/post-class-survey/thank-you${window.location.search}` )

    return setError( `There was an error submitting your survey responses. Please try again...` )

  }

  const StyledRating = styled( Rating )({
    '& .MuiRating-iconFilled': {
      color: tailwindColors.primary,
      fontSize: `2rem`
    },
    '& .MuiRating-iconHover': {
      color: tailwindColors.primary,
      fontSize: `2rem`
    },
    '& .MuiRating-iconEmpty': {
      fontSize: `2rem`,
      color: tailwindColors.primary
    }
  })

  const inputContainerClassname = `flex flex-col items-start justify-start gap-4`

  return (
    <div className="flex flex-col gap-10">
      <h1 className="hub-heading text-center text-3xl">{`Post Class Survey`}</h1>
      {
        loading ? <div className="flex flex-col my-4 gap-3"><p>{`Submitting your survey responses...`}</p><RingLoader /></div>
          :
          <>
            <div className={inputContainerClassname}>
              <p className="font-semibold">
                {`On a scale of 0 to 10, how likely are you to recommend this class to a friend or colleague?`}
                <span className="text-error ml-1">{`*`}</span>
              </p>
              <div className="md:self-center relative">
                <RatingButtonGroup
                  hideSelectText
                  buttons={Array.from( Array( 11 ).keys() )
                    .map( key => {
                      const keyString = key.toString()

                      return {
                        key: keyString,
                        selected: values.recommendScore === key,
                        text: keyString
                      }
                    })}
                  toggleButton={( button ) => {
                    onChange( `recommendScore`, parseInt( button.text ) )
                  }}
                />
              </div>
            </div>
            <div className={inputContainerClassname}>
              <p className="font-semibold">
                {`Please rate the following on a scale of 1-5:`}
                <span className="text-error ml-1">{`*`}</span>
              </p>
              <div className="flex flex-col items-start justify-start gap-1">
                <p>{`Quality of Content`}</p>
                <div className="relative">
                  <StyledRating
                    name="simple-controlled"
                    className="text-primary"
                    value={values?.qualityScore}
                    onChange={( _event, newValue ) => {
                      onChange( `qualityScore`, newValue ? newValue : null )
                    }}
                  />
                  <div className="flex justify-between font-thin text-sm">
                    <p>{`Poor`}</p>
                    <p>{`Excellent`}</p>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start justify-start gap-1">
                <p>{`Delivery of Content`}</p>
                <div className="relative">
                  <StyledRating
                    name="simple-controlled"
                    className="text-primary"
                    value={values.deliverScore}
                    onChange={( _event, newValue ) => {
                      onChange( `deliverScore`, newValue ? newValue : null )
                    }}
                  />
                  <div className="flex justify-between font-thin text-sm">
                    <p>{`Poor`}</p>
                    <p>{`Excellent`}</p>
                  </div>
                </div>
              </div>
            </div>
            <TextInput
              type="textarea"
              id="betterPrepared"
              name="betterPrepared"
              value={values.betterPrepared}
              label="Please tell us why you feel more prepared now to care for your baby. (optional)"
              onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
              maxLength={500}
            />
            <TextInput
              type="textarea"
              id="betterExperienceFreeform"
              name="betterExperienceFreeform"
              value={values.betterExperienceFreeform}
              label="Is there anything we could have done to make your experience better? (optional)"
              onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
              maxLength={500}
            />
            <TextInput
              id="emailAddress"
              name="emailAddress"
              value={values.emailAddress}
              label="Please provide your email if you would like for a member of our team to reach out to you directly."
              onChange={({ target: { name, value } }) => { return onChange( name, value ) }}
              className="input w-full mt-3"
            />
            <div className="flex flex-col gap-2 mb-10">
              <div className="w-fit mx-auto">
                <button
                  onClick={() => handleSubmitForm()}
                  className="primary-button"
                  disabled={values.recommendScore === null || !values.qualityScore || !values.deliverScore}
                >
                  {`Submit`}
                </button>
              </div>
              {error && <p className="text-error my-3 text-center">{error}</p>}
            </div>
          </>
      }
    </div>
  )
}

export default PostClassSurveyForm