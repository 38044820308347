import { TIME_ZONES } from "./constants"

export type TimeZoneString = `US/Eastern` | `US/Central` | `US/Pacific` | `US/Mountain`

export function getCurrentTimeZoneString( display: `label` | `name` = `name` ) : string {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()

  const foundTimeZone = TIME_ZONES?.find( ({area}) => area === timeZone )?.[display]

  return foundTimeZone ? foundTimeZone : display === `name` ? `US/Eastern` : `EST`
}


// Takes in a date string `2023-04-27T00:00:00.000Z` and adds the time stamp (eg. `13:30:00.000`) to make a exact date time string `2023-04-27T13:30:00.000Z`
export function buildFullDateTimeString( dateStamp : string, timeStamp : string ) : string {
  return dateStamp.replace( `00:00:00.000`, timeStamp )
}

export function addTimeZoneTimeString( dateTimeString: string ) : string {
  const tzOffsetMinutes = new Date( dateTimeString ).getTimezoneOffset() // This offset allows for us to account for daylight savings time

  return dateTimeString.slice( 0, -1 ) + `-0${tzOffsetMinutes / 60}:00`
}

export function getTimeString( time: Date ) : string {
  return `${time.getHours() < 10 ? `0${time.getHours()}`: time.getHours()}:${time.getMinutes() < 10 ? `0${time.getMinutes()}`: time.getMinutes()}`
}