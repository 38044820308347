import { FormattedAppointments } from "types/shopScheduleTypes"
import { addZeroIfNeeded } from "utils"

// this is a helper function that takes a string date key used in the appointments object and returns the date key as a Date type for comparison
export const dateFromKey = ( key: string ) => {
  try {
    return new Date( parseInt( key.substring( 0, 4 ) ), parseInt( key.substring( 5, 7 ) ) - 1, parseInt( key.substring( key.length - 2, key.length ) ) )
  } catch { return new Date() }
}

export const getFirstApptDate = ( m: Date, appointments: FormattedAppointments, sortedAppointmentKeys: string[] ) => {
  if ( appointments ) {
    const monthCompare = addZeroIfNeeded( m.getMonth() + 1 ).toString()

    const firstApptKey = sortedAppointmentKeys.find( apptKey => {
      return apptKey.substring( 5, 7 ) === monthCompare && appointments[apptKey]?.length
    })

    return firstApptKey ? new Date( appointments[firstApptKey][0].start_time.substring( 0, 16 ) ) : m
  }

  return m
}