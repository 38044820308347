import { useCallback, useEffect, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

// this is okay to be public
export const AEROFLOW_RECAPTCHA_SITE_KEY = `6LfsYuYpAAAAADMjInfXaD-7xZ5FGclge88Yo6U4`

function useGoogleReCaptchaGetter() : { token: string, handleReCaptchaVerify: () => Promise<string | null> } {
  const [ token, setToken ] = useState<string>( `` )
  const { executeRecaptcha } = useGoogleReCaptcha()

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback( async () => {

    if ( !executeRecaptcha ) return null

    const token = await executeRecaptcha()
    setToken( token )

    return token
    // Do whatever you want with the token
  }, [ executeRecaptcha ] )

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect( () => {
    handleReCaptchaVerify()
  }, [] )

  return {
    token,
    handleReCaptchaVerify
  }
}

export default useGoogleReCaptchaGetter