import { useState, useEffect } from 'react'
import { getStatePayers } from 'apis'
import { backupStatePayerList } from 'utils/backupStatePayerList'

const INSURANCE_CARRIER_ITEM = {
  name: `Select Insurance Carrier`,
  value: ``
}
const OTHER_ITEM = {
  name: `Other`,
  value: `other`
}

const useInsuranceTypes = selectedState => {
  const [ insuranceTypes, setInsuranceTypes ] = useState( [] )

  useEffect( () => {
    if ( !selectedState ) return
    getStatePayers( selectedState )
      .then( results => {
        const resultStatus = results?.meta?.status

        let payerList = []
        const statePayerBackup = backupStatePayerList.filter( state => {
          return state.state_association_abbreviation === selectedState
        })

        resultStatus === `OK` ? ( payerList = results.data ) : ( payerList = statePayerBackup )

        const _results = payerList.map( stateInsurance => {
          const { friendly_name, parent_payer_pk } = stateInsurance
          let _results = [{
            name: friendly_name,
            value: friendly_name,
            parent_payer_pk
          }]

          return _results
        })

        const _payers = _results.map( payer => {

          return payer[0]
        })

        setInsuranceTypes( [
          INSURANCE_CARRIER_ITEM,
          ..._payers,
          OTHER_ITEM
        ] )
      })
  }, [ selectedState ] )

  return {
    insuranceTypes
  }
}

export default useInsuranceTypes
