import * as yup from 'yup'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { addZeroIfNeeded } from 'utils'
import { MONTHS } from './constants'
dayjs.extend( isSameOrBefore )

const DOB_REGX = /^\d{4}-\d{2}-\d{2}$/
const PHONE_REGX = /^\(([2-9]\d{2})\) \d{3}-\d{4}$/
const STREET1_REGX = /(\S+\s+\S+){1}.*/
const ZIPCODE_REGX = /^[0-9]{5}(?:-[0-9]{4})?$/
const ONLYNUMBER_REGX = /^[a-zA-Z0-9\s,'-]*$/

export const DOBCheckerSchema = yup.object().shape({
  email: yup
    .string()
    .email( `Enter a valid Email` )
    .required( `Email is required` ),
  dob: yup
    .string()
    .required( `Date of Birth is required` )
    .matches( DOB_REGX, `Date of Birth is invalid` )
    .test(
      `dob`,
      `Enter a valid birth date after ${MONTHS[dayjs().month()]} ${addZeroIfNeeded( dayjs().date() )}, ${dayjs().year() - 77} and before ${MONTHS[dayjs().month()]} ${addZeroIfNeeded( dayjs().date() )}, ${dayjs().year() - 12}`,
      value => {
        return value.substring( 5, 7 ) != 0 && value.substring( 8, 10 ) != 0 && dayjs( value ).isAfter( dayjs( `${dayjs().year() - 77}-${addZeroIfNeeded( dayjs().month()+1 )}-${addZeroIfNeeded( dayjs().date() )}` ) ) &&
        dayjs( value ).isBefore( dayjs( `${dayjs().year() - 12}-${addZeroIfNeeded( dayjs().month()+1 )}-${addZeroIfNeeded( dayjs().date() )}` ) )
      }
    )
})

export const IntakeFormSchema = yup.object().shape({
  first_name: yup.string().required( `First name is required` ),
  last_name: yup.string().required( `Last name is required` ),
  phone: yup
    .string()
    .required( `Phone number is required` )
    .matches( PHONE_REGX, `Phone number is invalid` ),
  baby_due_date: yup
    .string()
    .required( `Due Date/Baby Birth Date is required` )
    .matches( DOB_REGX, `Due Date/Baby Birth Date is invalid` )
    .test(
      `baby_due_date`,
      `Enter a valid Due Date/Baby Birth Date date within 1 year of today`,
      value => {
        return value.substring( 5, 7 ) != 0 && value.substring( 8, 10 ) != 0 && dayjs( value ).isAfter( `${dayjs().year() - 1}-${addZeroIfNeeded( dayjs().month()+1 )}-${addZeroIfNeeded( dayjs().date() )}` ) &&
        dayjs( value ).isSameOrBefore( dayjs( `${dayjs().year() + 1}-${addZeroIfNeeded( dayjs().month()+1 )}-${addZeroIfNeeded( dayjs().date() )}` ) )
      }
    ),
  street1: yup
    .string()
    .required( `Street1 is required` )
    .matches( STREET1_REGX, `Street1 is invalid` ),
  city: yup.string().required( `City is required` ),
  zipcode: yup
    .string()
    .required( `Zipcode is required` )
    .matches( ZIPCODE_REGX, `Zipcode is invalid` ),
  state: yup.string().required( `State is required` ),
  parent_payer_pk: yup
    .string()
    .required( `Insurance Carrier is required` ),
  insurance_primary_provider_other: yup
    .string()
    .matches(
      ONLYNUMBER_REGX,
      `Name of other Insurance Carrier is invalid`
    ),
  insurance_primary_policy_number: yup
    .string()
    .min( 5, `Policy Number is too short` )
    .max( 20, `Policy Number is too long` )
    .matches( ONLYNUMBER_REGX, `Policy Number is invalid` )
    .required( `Policy Number is required` ),
  how_did_you_hear_about_us: yup
    .string()
    .required( `Please select an option for how you heard about us` ),
  terms: yup
    .string()
    .matches( true, `Please accept terms and conditions` )
})