export type TileProps = {
  headerContent?: JSX.Element | React.ReactNode;
  children: JSX.Element | React.ReactNode;
  className?: string;
  onClick?: () => void;
  id?: string;
}
export default function Tile({ headerContent, children, className, onClick, id } : TileProps ) : JSX.Element {
  return (
    <div
      id={id}
      className="hub-tile-border max-w-sm my-6 mx-4"
      onClick={onClick ? onClick : () => { return }}
    >
      {headerContent && (
        <div className="bg-white text-gray-dark rounded-t-lg p-2 text-center">
          {headerContent}
        </div>
      )}
      <div className={`p-4 ${className ?? ``}`}>
        {children}
      </div>
    </div>
  )
}