import { ButtonGroup } from 'components/ButtonGroup'
import React from 'react'
import { TIME_ZONES } from 'utils/constants'

function ThemedTimezoneSelector({
  timezone,
  setTimezone
}: {
  timezone: string
  setTimezone: React.Dispatch<React.SetStateAction<string>>
}): JSX.Element {

  return (
    <div className="mb-5 flex flex-col text-center items-center justify-center">
      <p className="font-semibold text-lg">{`Time Zone`}</p>
      <div className="flex gap-2 mt-5 flex-wrap justify-center items-center">
        <ButtonGroup
          buttons={TIME_ZONES.map( zone => {
            return {
              key: zone.label,
              selected: timezone === zone.name,
              text: zone.name
            }
          })}
          toggleButton={( button ) => {
            setTimezone( button.text )
          }}
        />
      </div>
    </div>
  )
}

export default ThemedTimezoneSelector
