import { getSource } from "utils/getSource"

export const getBundleTimeslots = async ( sku, timezone, auth ) => {
  const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/bundles/${sku}?tz=${timezone}&source=${getSource()}`, {
    headers: {
      "Authorization": auth
    }
  })

  return await response.json()
}

export const submitFinalScheduling = async ( classes, auth ) => {
  const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/schedule-class`, {
    method: `POST`,
    headers: {
      "Authorization": auth
    },
    body: JSON.stringify({
      source: getSource(),
      class_pk: classes.map( classDetails => {
        return {
          pk: classDetails.selected_timeslot.class_pk,
          event_instance_id: classDetails.selected_timeslot.event_instance_id,
          scheduled_time: classDetails.selected_timeslot.timeslot_exact_timestamp,
          tz: classDetails.selected_timeslot.time_zone
        }
      })
    })
  })

  return await response.json()
}