import React from 'react'
import { ShopScheduleThankYouSrc } from 'assets'
import { useNavigate } from 'react-router-dom'
import { HER_HUB_PATH } from 'views/HerHub/utils/constants'

function ShopScheduleThankYou() : JSX.Element {

  const navigate = useNavigate()

  return (
    <div className="max-w-lg px-10 flex flex-col gap-8 mx-auto justify-center items-center mt-5">
      <img
        src={ShopScheduleThankYouSrc}
        alt="shop and schedule thank you"
        width={400}
        height={400}
      />
      <div className="text-center">
        <p className="text-3xl md:text-4xl font-header mb-1">{`Congratulations!`}</p>
        <p className="text-lg font-light">{`You have successfully scheduled your classes. You will receive reminders via email and SMS.`}</p>
        <p
          className="text-lg my-10 a"
          onClick={() => { navigate( HER_HUB_PATH.concat( window.location.search ) ) }}
        >
          {`Go to my Account`}
        </p>
      </div>
    </div>
  )
}

export default ShopScheduleThankYou