import React from 'react'
import Select from 'components/Select'
import { MONTHS } from 'utils/constants'

type MonthSelectorProps = {
  fromMonth: Date;
  toMonth: Date;
  handleMonthSelection: ( _e: string ) => void;
  selectedMonth: Date;
}

function MonthSelector({fromMonth, toMonth, handleMonthSelection, selectedMonth} : MonthSelectorProps ) : JSX.Element {

  return (
    <div className="absolute top-4 gap-4 flex z-40">
      <Select
        name="month_selector"
        displayLabel={false}
        selectValues={
          MONTHS.filter( ( month, i ) => {
            const checkMonthDate = new Date( fromMonth.getMonth() < i ? fromMonth.getFullYear() : toMonth.getFullYear(), i )
            if ( ( fromMonth <= checkMonthDate && checkMonthDate <= toMonth ) || fromMonth.getMonth() === checkMonthDate.getMonth() ) return month
          }).map( ( month ) => {
            return {
              name: month,
              value: month
            }
          })
        }
        onChange={handleMonthSelection}
        required={false}
        inputProps={{
          value: MONTHS[selectedMonth.getMonth()],
          className: `w-fit`
        }}
        error={null}
      />
      <p className="text-2xl m-auto font-light">{selectedMonth.getFullYear()}</p>
    </div>
  )
}

export default MonthSelector