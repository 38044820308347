export default function fetchZoomLinkTracking( authorizationHeader : string, zoom_link: string, event_instance_id: string ) : Promise<Response> {
  return fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/zoom-link-click-tracking`, {
    method: `POST`,
    headers: {
      Authorization: authorizationHeader
    },
    body: JSON.stringify({
      zoom_link,
      event_instance_id
    })
  })
}