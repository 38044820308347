import React from 'react'
import { Class } from 'types/shopScheduleTypes'
import { ClassSelectedIcon, PlusIcon } from 'assets'

type IndividualClassTileProps = {
  classDetails: Class
  openModal: ( _e: React.MouseEvent<HTMLElement> ) => void
  isSelected: boolean
  handleSelectClass: ( _classDetails: Class ) => void
}

function IndividualClassTile({
  classDetails,
  openModal,
  isSelected,
  handleSelectClass
}: IndividualClassTileProps ): JSX.Element {
  return (
    <div
      onClick={openModal}
      className={`max-w-xs border border-${
        isSelected ? `primary` : `gray5`
      } py-5 px-6 rounded-xl min-w-fit flex flex-col justify-center items-center pb-10 relative my-5 w-full mx-auto cursor-pointer`}
    >
      <div className="h-44 flex items-center">
        <img
          src={classDetails?.image_path}
          width={150}
          height="auto"
          className="mt-2 object-fill"
        />
      </div>
      <span className="font-light my-2 text-sm md:text-base line-clamp h-11">
        {classDetails.class_title}
      </span>
      {/* Review display has been descoped for initial release <ReviewDisplay reviews={classDetails.reviews} number_of_reviews={classDetails.number_of_reviews} /> */}
      {classDetails?.class_duration && (
        <div className="absolute right-3 bottom-2">
          <p className="text-xs md:text-base font-light italicized text-gray-light">{`${
            classDetails.class_duration
          } min${classDetails.class_duration > 1 ? `s` : ``}`}</p>
        </div>
      )}
      <img
        src={isSelected ? ClassSelectedIcon : PlusIcon}
        alt="select class check"
        className="absolute -bottom-6 cursor-pointer w-14 xl:w-16 bg-white"
        onClick={() => {
          handleSelectClass( classDetails )
        }}
      />
    </div>
  )
}

export default IndividualClassTile
