export const timeFromNowPrettyString = ( dateString : string ) : string => {
  const now = new Date().getTime() // UTC timestamp
  const date = new Date( dateString ).getTime() // UTC timestamp
  const remaining = date - now // Difference in seconds
  const days = Math.floor( remaining / ( 1000 * 60 * 60 * 24 ) )
  const hours = Math.floor( ( remaining / ( 1000 * 60 * 60 ) ) % 24 )
  const minutes = Math.floor( ( remaining / 1000 / 60 ) % 60 )
  const dayString = ( days === 1 ) ? `1 day,` : ( days !== 0 ) ? `${days} days,` : ``
  const hourString = ( hours === 1 )? `1 hour, and` : ( hours !== 0 ) ? `${hours} hours, and` : ``
  const minuteString = ( minutes === 1 ) ? `1 minute` : `${minutes} minutes`
  if ( minutes <= 0 ) return `0 minutes`

  if ( days < 1 ) return `${hourString} ${minuteString}`

  return `${dayString.substring( 0, dayString.length - 1 )}`
}

export const isLessThanEqualHalfHourAway = ( dateString : string ) : boolean => {
  const now = new Date( )
  const date = new Date( dateString )

  const timeDiff = Math.abs( date.getTime() - now.getTime() )

  // Check if the time difference is less than or equal to 30 minutes in milliseconds
  const THIRTY_MINUTES_IN_MILLISECONDS = 30 * 60 * 1000

  return timeDiff <= THIRTY_MINUTES_IN_MILLISECONDS
}

export const is30MinsAway = ( dateString: string ) : boolean => {
  const now = new Date( )
  const date = new Date( dateString )

  const timeDiff = date.getTime() - now.getTime()

  // Check if the time difference is 30 mins in milliseconds
  const THIRTY_MINUTES_IN_MILLISECONDS = 30 * 60 * 1000

  return timeDiff <= THIRTY_MINUTES_IN_MILLISECONDS
}

export const isPlusMinusTwentyFourHoursAway = ( dateString: string ) : boolean => {
  const now = new Date( )
  const date = new Date( dateString )

  const timeDiff = Math.abs( date.getTime() - now.getTime() )

  // Check if the time difference is 4 hours in milliseconds
  const FOUR_HOURS_IN_MILLISECONDS = 4 * 60 * 60 * 1000

  return timeDiff <= FOUR_HOURS_IN_MILLISECONDS
}

export const isSameDay = ( dateString: string ) : boolean => {
  const now = new Date()
  const date = new Date( dateString )

  return now.getDate() === date.getDate() && now.getMonth() === date.getMonth()
}

export const isDayAway = ( dateString : string ) : boolean => {
  const now = new Date( )
  const date = new Date( dateString )

  const timeDiff = Math.abs( date.getTime() - now.getTime() )

  // Check if the time difference is 4 hours in milliseconds
  const TWENTY_FOUR_HOURS_IN_MILLISECONDS = 24 * 60 * 60 * 1000

  return timeDiff <= TWENTY_FOUR_HOURS_IN_MILLISECONDS
}

export const isWithin48Hours = ( dateString : string ) : boolean => {
  const now = new Date( )
  const date = new Date( dateString )

  const timeDiff = Math.abs( date.getTime() - now.getTime() )

  // Check if the time difference is 48 hours in milliseconds
  const FORTY_EIGHT_HOURS_IN_MILLISECONDS = 48 * 60 * 60 * 1000

  return timeDiff <= FORTY_EIGHT_HOURS_IN_MILLISECONDS
}

// Makes a string in the format of Sat, Apr 22, 2023, 8:00 PM
export const prettyTimeString = ( dateString : string | Date ) : string => {
  const date = new Date( dateString )
  const formatter = new Intl.DateTimeFormat( `en-US`, {
    weekday: `short`,
    month: `short`,
    day: `numeric`,
    year: `numeric`,
    hour: `numeric`,
    minute: `numeric`,
    hour12: true
  })
  const formattedDate = formatter.format( date )

  return formattedDate
}