import { useEffect } from 'react'
import PropTypes from 'prop-types'

function SchedulingNavbar({step, total, nextPage, disabled, handleSkipClass }) {

  useEffect( () => {
    const fnElement = document.getElementById( `fn-chat-iframe-container` )

    if ( fnElement ) fnElement.style.display = `none`

    return () => {
      const fnElement = document.getElementById( `fn-chat-iframe-container` )

      if ( fnElement ) fnElement.style.display = `block`
    }
  }, [] )

  return (
    <div className="fixed bottom-0 py-7 px-2 bg-pink-white  w-full z-40">
      <div className="flex justify-between items-center max-w-none lg:max-w-2xl mx-auto">
        <p className="mx-5 md:text-xl md:mx-16 font-semibold">{`${step} of ${total}`}</p>
        <div className="mx-3 md:mx-16 flex flex-col gap-3 justify-center items-center">
          <button
            onClick={nextPage}
            className="primary-button"
            disabled={disabled}
          >
            {`Next`}
          </button>
          {
            handleSkipClass &&
            <span
              className="a"
              onClick={handleSkipClass}
            >
              {`Skip This Class`}
            </span>
          }
        </div>
      </div>
    </div>
  )
}

SchedulingNavbar.propTypes = {
  step: PropTypes.number,
  total: PropTypes.number,
  nextPage: PropTypes.func,
  disabled: PropTypes.bool,
  bundleDetails: PropTypes.object,
  handleSkipClass: PropTypes.func
}

export default SchedulingNavbar