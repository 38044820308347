import { IntegratedHistoryInput } from 'components/IntegratedHistoryInput'

function IntegratedHistoryForm() : JSX.Element {
  return (
    <div className="py-4 px-4 flex flex-col w-full items-center justify-center max-w-2xl mx-auto space-y-3">
      <IntegratedHistoryInput />
    </div>
  )
}

export default IntegratedHistoryForm