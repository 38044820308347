import Modal from 'components/modal/Modal'
import React from 'react'
import { ScheduledClass } from 'types/shopScheduleTypes'

type CancelModalType = {
  display: boolean;
  seriesClasses: ScheduledClass[];
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel: ( _seriesClasses?: ScheduledClass[] ) => void;
}

function CancelModal({
  display,
  setDisplay,
  onCancel,
  seriesClasses
} : CancelModalType ) : JSX.Element | null {

  function handleCancelClass() {
    setDisplay( false )
    onCancel( seriesClasses )
  }

  return (
    <Modal
      isOpen={display}
      defaultCloseButton={false}
      containerStyling=""
      onClose={() => { setDisplay( false ) }}
      hasClickListener={false}
    >
      <div className="bg-white p-10">
        <p className={`text-center my-4 text-lg`}>{`Are you sure you want to cancel this class?`}</p>
        {
          seriesClasses?.length > 0 &&
                <div>
                  <p className="text-center">{`The following classes will be cancelled as they are part of a series:`}</p>
                  <ul className="my-5 list-disc w-10/12 mx-auto">
                    {
                      seriesClasses.map( ( c: ScheduledClass ) => {
                        return <li key={c.class_id} className="font-semibold my-1 text-center">{c.class_title}</li>
                      })
                    }
                  </ul>
                </div>
        }
        <div className="flex flex-col justify-center gap-3 w-48 mx-auto">
          <button className="primary-button" onClick={handleCancelClass}>{`Yes`}</button>
          <button className="standard-button" onClick={() => setDisplay( false )}>{`No`}</button>
        </div>
      </div>
    </Modal>
  )
}

export default CancelModal