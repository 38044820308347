import useHerHubStore from 'stores/herHub'
import { ClassDescription, RescheduleClassDetails, ScheduledClass, TimeSlot } from 'types/shopScheduleTypes'
import { useNavigate } from 'react-router-dom'
import { HER_HUB_PATH } from 'views/HerHub/utils/constants'
import useAuthStore from 'stores/auth'
import useSeriesReference from 'hooks/useSeriesReference'
import { reportToSentry } from 'utils/reportToSentry'

type RescheduleHandlersReturn = {
  handleSubmitReschedule: ( _classDetails: ClassDescription | undefined, _selectedTime: Date | null, _selectedTimeZone: string | null ) => void;
  handleSeriesReschedule: (
    _totalSteps: number,
    _seriesClasses: ScheduledClass[] | undefined,
    _setSeriesClasses: React.Dispatch<React.SetStateAction<ScheduledClass[]>>,
    _classIndex: number | undefined,
    _nextPage: ( () => void ) | undefined,
    _selectedTime: Date | null,
    _selectedTimeZone: string | null ) => void;
}

function useRescheduleHandlers(
  scheduledClass: ScheduledClass | undefined,
  classDetails: ClassDescription | undefined,
  rescheduleSingle: ( _classDetail: RescheduleClassDetails, _authorization: string ) => Promise<void>,
  rescheduleMultiple: ( _classDetails: RescheduleClassDetails[], _authorization: string ) => Promise<void>
) : RescheduleHandlersReturn {

  const { addRecentReschedule } = useHerHubStore()
  const navigate = useNavigate()
  const { buildAuthorizer } = useAuthStore()
  const { setSeriesReference, resetSeriesReference } = useSeriesReference()

  const getRescheduleData = (
    scheduledClass: ScheduledClass,
    classDetails: ClassDescription | undefined,
    selectedTime: Date | null,
    selectedTimeZone: string | null
  ) : {
    classDetail: RescheduleClassDetails;
    newClass: TimeSlot;
  } => {
    const newClass = classDetails?.timeslots?.find( ( ts : TimeSlot ) => {
      const isSameDay = new Date( ts.timeslot_exact_timestamp ).toISOString() === selectedTime?.toISOString()
      if ( isSameDay ) return ts
    })

    const classDetail:RescheduleClassDetails = { // Note: these values should never be undefined, but TS
      class_pk: newClass.class_pk,
      event_instance_id: newClass?.event_instance_id ?? ``,
      scheduled_time: newClass?.timeslot_exact_timestamp ?? ``,
      tz: selectedTimeZone ?? ``,
      scheduled_event_instance_id: scheduledClass?.event_instance_id ?? ``,
      scheduled_event_attendance_id: scheduledClass?.event_attendance_id ?? ``
    }

    return {
      classDetail,
      newClass
    }
  }


  const handleSubmitReschedule = ( classDetails: ClassDescription | undefined, selectedTime: Date | null, selectedTimeZone: string | null ) => {
    if ( scheduledClass === undefined ) return

    const { classDetail, newClass } = getRescheduleData( scheduledClass, classDetails, selectedTime, selectedTimeZone )

    rescheduleSingle( classDetail, buildAuthorizer() ).then( () => {
      addRecentReschedule({
        class_title: ( classDetails as ClassDescription ).class_title,
        class_sku: ( classDetails as ClassDescription ).class_sku,
        ...( newClass as any )
      })

      navigate( `${HER_HUB_PATH}${window.location.search}`, {
        replace: true
      })
    })
      .catch( ( error: Error ) => {
        reportToSentry( new Error( `Hub Reschedule: Error rescheduling single class`, {
          cause: error
        }), {
          classDetail: JSON.stringify( classDetail ),
          newClass: JSON.stringify( newClass )
        })
      })
  }

  const handleSeriesReschedule = (
    totalSteps: number,
    seriesClasses: ScheduledClass[] | undefined,
    setSeriesClasses: React.Dispatch<React.SetStateAction<ScheduledClass[]>>,
    classIndex: number | undefined,
    nextPage: ( () => void ) | undefined,
    selectedTime: Date | null,
    selectedTimeZone: string | null
  ) => {
    if ( !seriesClasses?.length || !nextPage || classIndex === undefined || !seriesClasses[classIndex] ) return

    const seriesScheduledClass:ScheduledClass = seriesClasses[classIndex]
    const { classDetail, newClass } = getRescheduleData( seriesScheduledClass, classDetails, selectedTime, selectedTimeZone )

    if ( classIndex !== totalSteps - 1 ) {
      // store data to submit after selecting all series class slots
      const updatedSeriesClasses:ScheduledClass[] = seriesClasses
      const seriesClassData = updatedSeriesClasses[ classIndex ]
      updatedSeriesClasses[ classIndex ] = Object.assign({}, seriesClassData, {
        reschedule_class_details: classDetail,
        reschedule_new_class: newClass
      })
      setSeriesClasses( updatedSeriesClasses )
      // set series ref so we can force the user to schedule in order
      setSeriesReference( seriesClassData.class_series_pk, seriesClassData.class_sequence, newClass.timeslot_exact_timestamp )

      return nextPage()
    }

    // update the last class
    const finalSeriesClasses = seriesClasses
    finalSeriesClasses[ classIndex ] = Object.assign({}, finalSeriesClasses[ classIndex ], {
      reschedule_class_details: classDetail,
      reschedule_new_class: newClass
    })

    if ( finalSeriesClasses ) {
      rescheduleMultiple( finalSeriesClasses.map( ( c: ScheduledClass ) => {
        return c.reschedule_class_details as RescheduleClassDetails
      }), buildAuthorizer() ).then( () => {

        seriesClasses.forEach( ( c: ScheduledClass ) => {
          addRecentReschedule({
            class_title: c?.class_title,
            class_sku: c?.class_sku,
            // ts doesn't like us adding time slot type to this need to look if it is necessary
            ...( c.reschedule_new_class as any )
          })
        })
        // reset series ref state
        resetSeriesReference()
        navigate( `${HER_HUB_PATH}${window.location.search}`, {
          replace: true
        })
      })
        .catch( ( error: Error ) => {
          reportToSentry( new Error( `Hub Reschedule: Error rescheduling series classes`, {
            cause: error
          }), {
            seriesClasses: JSON.stringify( seriesClasses )
          })
        })
    }
  }

  return {
    handleSubmitReschedule,
    handleSeriesReschedule
  }
}

export default useRescheduleHandlers

