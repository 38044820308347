import React, { useEffect, useState } from 'react'
import useActiveTag from 'hooks/useActiveTag'
import { Class } from 'types/shopScheduleTypes'
import FilterMapping from 'types/FilterMapping'
import { ButtonGroup } from 'components/ButtonGroup'
import { ButtonButtonGroup } from 'components/ButtonGroup/ButtonGroup'

function ClassTagSection({ classes, filterMapping } : { classes: Class[] | null, filterMapping: FilterMapping[] }) : JSX.Element | null {

  const [ tags, setTags ] = useState<Set<string> | null>( null )

  const { activeTag, clearActiveTag, updateActiveTag } = useActiveTag()

  useEffect( () => {
    if ( classes?.length && !tags ) {
      const buildTags:Set<string> = new Set()
      classes.forEach( classDetails => {
        if ( classDetails?.category ) buildTags.add( classDetails.category )
      })

      setTags( buildTags )
    }

  }, [ classes ] )

  const handleToggle = ( button : ButtonButtonGroup ) => {
    return button.key === activeTag ? clearActiveTag() : updateActiveTag( button.key )
  }

  const tagButtons = ( tags?.size ? [ ...Array.from( tags ) ].sort( ( a: string, b: string ) => { return a.localeCompare( b ) }) : [] ).map( tag => {
    const tagMeta = filterMapping.find( tagMeta => { return tagMeta.value === tag })

    const buttonText = ( tagMeta?.label ?? tag ).toLowerCase().split( ` ` )
      .map( ( c ) => c.charAt( 0 ).toUpperCase() + c.substring( 1 ) )
      .join( ` ` )

    return Object.assign({}, {
      key: tag,
      selected: activeTag === tag,
      text: buttonText
    })
  })

  if ( !tags ) return null

  return (
    <div className="flex flex-wrap gap-3 md:gap-6 w-full max-w-3xl justify-center items-center my-3">
      <ButtonGroup
        hideSelectText
        buttons={tagButtons}
        toggleButton={handleToggle}
      />
    </div>
  )
}

export default ClassTagSection