import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { ScheduledClass } from 'types/shopScheduleTypes'
import { OneOnOne } from 'types/shopScheduleTypes/OneOnOne'
import { ScheduleAppointment } from 'types/shopScheduleTypes/ScheduleAppointment'
import { OneOnOneNotifications } from 'types/shopScheduleTypes/OneOnOneNotifications'

interface herHubState {
  recentReschedule: ScheduledClass[];
  addRecentReschedule: ( _recentClass: ScheduledClass ) => void;
  recentCancel: ScheduledClass[];
  oneOnOneNotifications: OneOnOneNotifications[];
  addOneOnOneNotification: ( _notification: OneOnOneNotifications ) => void;
  removeOneOnOneNotification: ( _appoitnmentId: string ) => void;
  addRecentCancel: ( _recentClass: ScheduledClass ) => void;
  removeRecentCancel: ( _recentClass: ScheduledClass ) => void;
  recentAppointmentReschedule: OneOnOne[];
  addRecentAppointmentReschedule: ( _recentAppointment: OneOnOne ) => void;
  recentAppointmentCancel: ScheduleAppointment[];
  addRecentAppointmentCancel: ( _recentAppointment: ScheduleAppointment ) => void;
  removeRecentAppointmentCancel: ( _recentClass: ScheduleAppointment ) => void;
  resetRecentAppointmentReschedule: () => void;
  resetRecentAppointmentCancel: () => void;
  removeRecentAppointmentReschedule: ( _ra: OneOnOne ) => void;
  hiddenClassNotifications: Array<string | number>;
  hideClassFromNotifications: ( _classPk: string | number ) => void;
  wasRecentlyCanceled: ( _classPk: number | string ) => boolean;
  wasHiddenFromNotifications: ( _classPk: number | string ) => boolean;
  removeUpdatedReschedules: ( _upcomingClasses: ScheduledClass[] ) => ScheduledClass[];
  removeUpdatedCancels: ( _upcomingClasses: ScheduledClass[] ) => void;
  updateClassNotifications: () => void;
}

const useHerHubStore = create<herHubState>()( persist( ( set, get ) => ({
  recentReschedule: [],
  recentAppointmentReschedule: [],
  oneOnOneNotifications: [],
  addOneOnOneNotification: ( _notification ) => {
    set({
      oneOnOneNotifications: [ _notification, ...get().oneOnOneNotifications ]
    })
  },
  removeOneOnOneNotification: ( _appointmentId ) => {
    const indexOfNotification = get().oneOnOneNotifications.findIndex( ( notification ) => notification.appointment_id === _appointmentId )
    if ( indexOfNotification !== -1 ) {
      set({
        oneOnOneNotifications: indexOfNotification === 0 ? [] : [ ...get().oneOnOneNotifications.splice( indexOfNotification, 1 ) ]
      })
    }
  },
  resetRecentAppointmentCancel: () => {
    set({
      recentAppointmentCancel: []
    })
  },
  resetRecentAppointmentReschedule: () => {
    set({
      recentAppointmentReschedule: []
    })
  },
  addRecentReschedule: ( _rr ) => {
    get().removeRecentCancel( _rr ) // Rare case where user cancels then reschedules same class
    set({
      recentReschedule: [ _rr, ...get().recentReschedule ]
    })
  },
  addRecentAppointmentReschedule: ( _rr ) => {
    set({
      recentAppointmentReschedule: [ _rr, ...get().recentAppointmentReschedule ]
    })
  },
  updateClassNotifications: () => {
    set({
      hiddenClassNotifications: [ ...( get().hiddenClassNotifications.filter( ( notificationPk: string | number ) => {
        const isStillRecentReschedule = Boolean( ( get().recentReschedule ).find( ( c: ScheduledClass ) => {
          return c.class_pk == notificationPk
        }) )
        const isStillRecentCancel = Boolean( ( get().recentCancel ).find( ( c: ScheduledClass ) => {
          return c.class_pk == notificationPk
        }) )

        return isStillRecentCancel || isStillRecentReschedule
      }) ) ]
    })

  },
  removeUpdatedReschedules: ( upcomingClasses: ScheduledClass[] ) => {
    const { recentReschedule } = get()
    const updatedRecents = recentReschedule.filter( ( c: ScheduledClass ) => {
      const upcomingClassData = upcomingClasses.find( ( upcomingC: ScheduledClass ) => {
        return upcomingC.class_pk === c.class_pk
      })
      // if the upcoming response time slot matches the recent reschedule time slot we can remove it from recent reschedule

      return c.scheduled_date !== upcomingClassData?.scheduled_date
    })
    set({
      recentReschedule: updatedRecents
    })
    get().updateClassNotifications()

    return updatedRecents
  },
  removeUpdatedCancels: ( upcomingClasses: ScheduledClass[] ) => {
    const { recentCancel } = get()
    const updatedCancels = recentCancel.filter( ( c: ScheduledClass ) => {
      const upcomingClassData = upcomingClasses.find( ( upcomingC: ScheduledClass ) => {
        return upcomingC.class_pk === c.class_pk
      })
      // if the class still exists in the upcoming class data we keep it in recently cancelled

      return Boolean( upcomingClassData )
    })
    set({
      recentCancel: [ ...updatedCancels ]
    })

    get().updateClassNotifications()
  },
  recentCancel: [],
  recentAppointmentCancel: [],
  addRecentCancel: ( _rc ) => set({
    recentCancel: [ _rc, ...get().recentCancel ]
  }),
  addRecentAppointmentCancel: ( _rc ) => set({
    recentAppointmentCancel: [ _rc, ...get().recentAppointmentCancel ]
  }),
  removeRecentAppointmentCancel: ( _rc ) => {
    const indexOfRc = get().recentAppointmentCancel.findIndex( ( rc ) => rc.start_time === _rc.start_time )
    if ( indexOfRc !== -1 ) {
      set({
        recentAppointmentCancel: indexOfRc === 0 ? [] : [ ...get().recentAppointmentCancel.splice( indexOfRc, 1 ) ]
      })
    }
  },
  removeRecentCancel: ( _rc ) => {
    const indexOfRc = get().recentCancel.findIndex( ( rc ) => rc.class_pk === _rc.class_pk )
    if ( get().recentAppointmentCancel.length === 1 ) get().resetRecentAppointmentCancel()
    else if ( indexOfRc !== -1 ) set({
      recentCancel: [ ...get().recentCancel.splice( indexOfRc, 1 ) ]
    })
  },
  removeRecentAppointmentReschedule: ( _ra ) => {
    const indexOfRa = get().recentAppointmentReschedule.findIndex( ( ra ) => ra.appointment_id === _ra.appointment_id )
    if ( get().recentAppointmentReschedule.length === 1 ) get().resetRecentAppointmentReschedule()
    else if ( indexOfRa !== -1 ) {
      set({
        recentAppointmentReschedule: [ ...get().recentAppointmentReschedule.splice( indexOfRa, 1 ) ]
      })
    } else {
      const indexOfNewAppointment = get().recentAppointmentReschedule.findIndex( ( ra ) => ra.start_time.substring( 0, 10 ) === _ra.start_time.substring( 0, 10 ) )
      set({
        recentAppointmentReschedule: [ ...get().recentAppointmentReschedule.splice( indexOfNewAppointment, 1 ) ]
      })
    }
  },
  hiddenClassNotifications: [],
  hideClassFromNotifications: ( _classPk ) => set({
    hiddenClassNotifications: [ _classPk, ...get().hiddenClassNotifications ]
  }),
  wasRecentlyCanceled: ( classPk: number | string ) => {
    const _recentlyCanceled = get().recentCancel.find( ( _cClass ) => _cClass.class_pk == classPk )

    return _recentlyCanceled !== undefined
  },
  wasHiddenFromNotifications: ( _classPk: number | string ) => {
    return get().hiddenClassNotifications.find( _hidden => _hidden == _classPk ) !== undefined
  }
}), {
  name: `herHubStorage`,
  storage: createJSONStorage( () => sessionStorage )
}) )

export default useHerHubStore