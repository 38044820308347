import { BrowserRouter as Router } from 'react-router-dom'
import { RouteMapping } from 'components/Route'
import Layout from 'components/Layout'
import 'styles/tailwind.css'
import { ErrorHandler } from 'components/ErrorBoundary'
import MaintenanceListener from 'components/MaintenanceListener'
import TokenCheck from 'views/TokenCheck/TokenCheck'
import ApolloSetup from 'components/ApolloSetup/ApolloSetup'
import MABTheme from 'config/MABTheme'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

const App = () => {
  return (
    <ErrorHandler>
      <Router>
        <GoogleReCaptchaProvider reCaptchaKey="6LfsYuYpAAAAADMjInfXaD-7xZ5FGclge88Yo6U4">
          <ApolloSetup>
            <MaintenanceListener />
            <TokenCheck />
            <Layout>
              <MABTheme>
                <RouteMapping />
              </MABTheme>
            </Layout>
          </ApolloSetup>
        </GoogleReCaptchaProvider>
      </Router>
    </ErrorHandler>
  )
}

export default App
