import PropTypes from 'prop-types'
import { TERMS_AND_CONDITIONS_PATH } from 'routes'

const TermsChecker = ({
  name,
  value,
  type,
  onClick,
  agreeToCancel = false,
  showMayRecordStatement = false,
  containerClassname = ``
}) => {

  return (
    <div className={`max-w-lg mx-auto mt-3 cursor-pointer ${containerClassname}`}>
      <label className="flex text-xs pt-3">
        <span>
          <input
            type={type}
            name={name}
            checked={value}
            className="h-7 w-7 ml-2 mr-3 text-pink-3 cursor-pointer"
            onClick={onClick}
            onChange={onClick}
          />
        </span>
        <div className="flex flex-col gap-4">
          <span className="normal-case text-sm cursor-pointer">
            {`By checking this box, I provide my consent for Aeroflow Breastpumps or Aeroflow Health to send me recurring text messages and calls, including with marketing, at the number provided.  I understand I can reply “STOP” to stop text messages at any time and that text message and data rates may apply. Consent is not a condition of purchase of any goods or services. I also agree to Aeroflow Health's dispute resolution and binding arbitration policies, its `}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://aeroflowbreastpumps.com/privacy-policy-cookie-restriction-mode"
            >
              {`Privacy Policy`}
            </a>
            {`, as well as its `}
            <a
              target="_blank"
              rel="noreferrer"
              href={TERMS_AND_CONDITIONS_PATH}
            >
              {`Terms and Conditions`}
            </a>
            {`.`}
          </span>
          {
            showMayRecordStatement &&
            <span className="normal-case text-sm cursor-pointer">
              {`Aeroflow Breastpumps or Aeroflow Health may record appointments for the purpose of completing medical records.`}
            </span>
          }
          {
            agreeToCancel &&
            <span className="normal-case text-sm cursor-pointer">
              {`I acknowledge that Aeroflow requires a 24 hour notice for any appointment cancellations. Failure to cancel outside of 24 hours is subject to a $25 fee.`}
            </span>
          }
        </div>

      </label>
    </div>
  )
}

TermsChecker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  type: PropTypes.string,
  onClick: PropTypes.func,
  containerClassname: PropTypes.string,
  linkColor: PropTypes.string,
  agreeToCancel: PropTypes.bool,
  showMayRecordStatement: PropTypes.bool
}

export default TermsChecker
