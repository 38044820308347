import { ClassProduct, ScheduledClass } from "types/shopScheduleTypes"
import { useState } from "react"
import ClassDetailsModal from "./ClassDetailsModal"
import { checkmark } from "assets/herHub"
import postCancelClass from 'apis/postCancelClass'
import useHerHubStore from "stores/herHub"
import { reportToSentry } from "utils/reportToSentry"
import CancelModal from "./CancelModal"
import useSeriesClasses from "../hooks/useSeriesClasses"
import { Link, useNavigate } from "react-router-dom"
import fetchZoomLinkTracking from "apis/postZoomLinkTracking"
import useAuthStore from "stores/auth"
import { INDIVIDUAL_SELECTION_BASE_PATH } from "routes"
import { getCalendarEventDescription } from "utils"
import { getCurrentTimeZoneString, getTimeString } from 'utils/time'
import { is30MinsAway } from "components/HubLandingTiles/YourNextClass/time"
import CustomAddToCalendarButton from "components/CustomAddToCalendar/CustomAddToCalendarButton"

type ClassProps = {
  id?: string;
  completed?: boolean;
  classDetails?: ClassProduct;
  scheduledClasses: ScheduledClass[];
  hideActions?: boolean;
  isNextClassTile?: boolean;
} & ScheduledClass

export default function Class({completed = false, hideActions = false, classDetails, scheduledClasses, isNextClassTile = false, ...scheduledClass} : ClassProps ) : JSX.Element {

  const [ showDetailsModal, setShowDetailsModal ] = useState( false )
  const [ showCancelModal, setShowCancelModal ] = useState( false )

  const { addRecentCancel } = useHerHubStore()
  const { buildAuthorizer } = useAuthStore()
  const navigate = useNavigate()

  const seriesClasses = useSeriesClasses( scheduledClass, scheduledClasses )

  const handleCancelClass = ( seriesClasses?: ScheduledClass[] ) => {
    // if class is a series class cancel other classes in the series
    if ( seriesClasses?.length ) {
      seriesClasses.forEach( ( c: ScheduledClass ) => {
        addRecentCancel({
          ...c
        })
      })
      // fire off cancel classes in parallel
      Promise.all<Promise<Response> | void>(
        seriesClasses.map( async ( c: ScheduledClass ) => {
          return postCancelClass( c.event_instance_id, c.event_attendance_id )
        }) )
        .catch( ( err: Error ) => {
          reportToSentry( new Error( `Hersource Hub: Error canceling series classes`, {
            cause: err
          }), {
            seriesClasses: JSON.stringify( seriesClasses )
          })
          console.error( err )
        })
    } else {
      addRecentCancel({
        ...scheduledClass
      })
      postCancelClass( scheduledClass.event_instance_id, scheduledClass.event_attendance_id )
        .catch( ( err ) => {
          reportToSentry( new Error( `Hersource Hub: Error canceling class`, {
            cause: err
          }), {
            scheduledClass: JSON.stringify( scheduledClass )
          })
          console.error( err )
        })
    }

  }
  const handleLaunchZoom = () => {
    fetchZoomLinkTracking( buildAuthorizer(), scheduledClass.zoom_link, scheduledClass.event_instance_id ).catch( ( e ) => {
      reportToSentry( e, {
        scheduledClass: JSON.stringify( scheduledClass )
      })
    })

    window.open( scheduledClass.zoom_link, `_blank`, `noreferrer` )
  }

  const handleRetakeClass = () => {
    const updatedURLSearchParams = new URLSearchParams( window.location.search )
    updatedURLSearchParams.set( `class_id`, scheduledClass.class_id )
    navigate( `${INDIVIDUAL_SELECTION_BASE_PATH}?${updatedURLSearchParams.toString()}` )
  }

  const startTime = ( new Date( scheduledClass.start_time_utc ?? scheduledClass.timeslot_exact_timestamp ) ).toTimeString()
    .split( ` ` )[0].substring( 0, 5 )
  const endTime = scheduledClass.end_time_utc ? ( new Date( scheduledClass.end_time_utc ) ).toTimeString()
    .split( ` ` )[0].substring( 0, 5 )
    : getTimeString( new Date( new Date( scheduledClass?.timeslot_exact_timestamp as string ).getTime() + ( scheduledClass.duration_minutes * 60 * 1000 ) ) )

  const dateStamp = scheduledClass?.scheduled_date?.substring( 0, 10 )

  const scheduledDate = scheduledClass?.start_time_local ? `${scheduledClass.start_time_local.substring( 0, 19 )}` : scheduledClass?.timeslot_exact_timestamp?.substring( 0, 19 ) as string

  const classIs30MinutesAway = is30MinsAway( scheduledDate )

  return (
    <>
      <div className="flex mt-4 mb-5 gap-5 relative cursor-pointer">
        <div className="flex flex-col w-full">
          <div className="flex gap-2">
            <div onClick={() => setShowDetailsModal( true )}>
              {classDetails ?
                <img
                  width={70} height={70}
                  src={classDetails?.small_image.url}
                  alt={classDetails?.small_image.label}
                /> : (
                  <div className="w-12 h-12 bg-gray-300" />
                )
              }
            </div>
            <div className="w-full max-w-sm px-3 flex flex-col">
              {
                completed ?
                  <div
                    onClick={() => setShowDetailsModal( true )}
                  >
                    <p className="hub-title">{scheduledClass.class_title}</p>
                    <p className="hub-p">{`${scheduledClass.scheduled_date_pretty} ${scheduledClass.begin_time_pretty}, ${scheduledClass.duration_minutes}mins`}</p>
                  </div>
                  :

                  <CustomAddToCalendarButton
                    disabled={!scheduledClass?.zoom_link || !scheduledClass?.class_id}
                    config={{
                      name: scheduledClass.class_title,
                      iCalFileName: `lactation-link-calendar-event-download`,
                      buttonStyle: `date`,
                      forceOverlay: true,
                      description: getCalendarEventDescription( scheduledClass ),
                      options: [ `Apple`, `Google`, `Outlook.com`, `Microsoft365`, `Yahoo`, `iCal`, `MicrosoftTeams` ] as any,
                      location: scheduledClass.zoom_link ?? ``,
                      startDate: dateStamp,
                      endDate: dateStamp,
                      startTime: startTime,
                      endTime: endTime,
                      timeZone: getCurrentTimeZoneString(),
                      hideBranding: true,
                      hideCheckmark: true,
                      customCss: `/resources/css/atcb-override.css`
                    }}
                  />

              }
              <div>
                {
                  scheduledClass?.class_id?.length ?
                    <div className="flex mt-4 gap-6 items-start justify-start">
                      {
                        !completed ?
                          <>
                            {
                              isNextClassTile && classIs30MinutesAway &&
                              <p
                                onClick={handleLaunchZoom}
                                className="text-sm text-center a"
                              >
                                {`Join Class`}
                              </p>
                            }
                            {
                              !hideActions && !completed &&
                            <>
                              <p className="a text-sm text-center">
                                <Link to={`/reschedule/${scheduledClass.class_id}${window.location.search}`}>
                                  {`Reschedule`}
                                </Link>
                              </p>
                              <p
                                className="a text-sm text-center"
                                onClick={() => { setShowCancelModal( true ) }}
                              >
                                {`Cancel`}
                              </p>
                            </>
                            }
                          </>
                          :
                          <>
                            {
                              scheduledClass?.class_id && completed &&
                              <p
                                className="a text-sm text-center"
                                onClick={handleRetakeClass}
                              >
                                {`Retake Class`}
                              </p>
                            }
                            {
                              classDetails?.course_material_link && completed &&
                              <p className="a text-sm text-center">
                                {/* have to strip out the html here since we are provided a string link within a wysiwyg */}
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={classDetails.course_material_link.replace( /(<([^>]+)>)/gi, `` )}
                                >
                                  {`Resource Guide`}
                                </a>
                              </p>
                            }
                            <p
                              className="a text-sm text-center"
                              onClick={() => setShowDetailsModal( true )}
                            >
                              {`View Class Details`}
                            </p>
                          </>
                      }
                    </div>
                    :
                    <div className="flex mt-4 gap-6 items-start justify-start">
                      {
                        classDetails?.course_material_link && completed &&
                        <p className="a text-sm text-center">
                          {/* have to strip out the html here since we are provided a string link within a wysiwyg */}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={classDetails.course_material_link.replace( /(<([^>]+)>)/gi, `` )}
                          >
                            {`Resource Guide`}
                          </a>
                        </p>
                      }
                      <p
                        className="a text-sm text-center"
                        onClick={() => setShowDetailsModal( true )}
                      >
                        {`View Class Details`}
                      </p>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
        {
          completed &&
          <img
            width={16} height={16}
            src={checkmark}
            alt={`checkmark`}
            className="absolute top-0 left-8"
          />
        }
      </div>
      <CancelModal
        onCancel={handleCancelClass}
        seriesClasses={seriesClasses}
        display={showCancelModal}
        setDisplay={setShowCancelModal}
      />
      <ClassDetailsModal
        key={`${showDetailsModal}-${scheduledClass.event_instance_id}`}
        scheduledClass={scheduledClass}
        image={classDetails?.image}
        description={classDetails?.short_description}
        isOpen={showDetailsModal}
        handleClose={() => setShowDetailsModal( false )}
        onCancelClass={handleCancelClass}
        completed={completed}
        scheduledClasses={scheduledClasses}
      />
    </>
  )
}