import { gql } from '@apollo/client'

export const getAttributeMeta = gql`
  query getAttributeMeta( $attribute_code: String!, $entity_type: String! ) {
    customAttributeMetadata(
      attributes: [
        {
          attribute_code: $attribute_code
          entity_type: $entity_type
        }
      ]
    ) {
      items {
        attribute_code
        
        attribute_options {
          value
          label
        }
      }
    }
  }
`