import React from 'react'
import { Xicon } from 'assets'
import { Class } from 'types/shopScheduleTypes'
import useSelectedClasses from 'hooks/useSelectedClasses'
import { useNavigate } from 'react-router-dom'
import { INDIVIDUAL_SELECTION_BASE_PATH } from 'routes'

type CurrentClassTab = {
  classInfo: Class;
}

function ActionClassTab({ classInfo } : CurrentClassTab ) : JSX.Element {

  const { selectedClasses, removeClasses, clearClasses } = useSelectedClasses()

  const navigate = useNavigate()

  const getCurrentSeriesClasses = ( classDetails: Class ) => {
    if ( !classDetails.is_series ) return [ classDetails ]

    return selectedClasses?.filter( ( classItem: Class ) => {
      return classItem.is_series && classItem.series_pk === classDetails.series_pk
    })
  }


  const handleRemoveClass = () => {
    const allClasses = getCurrentSeriesClasses( classInfo )
    removeClasses( allClasses )
    if ( !selectedClasses.length ) {
      navigate( INDIVIDUAL_SELECTION_BASE_PATH.concat( window.location.search ) )

      return clearClasses()
    }
  }

  const handleChangeClass = () => {
    // remove class and go back to schedule your classes page to pick a different class
    const allClasses = getCurrentSeriesClasses( classInfo )
    removeClasses( allClasses )

    return navigate( INDIVIDUAL_SELECTION_BASE_PATH.concat( window.location.search ) )

  }

  return (
    <div className="flex justify-between w-full items-center">
      <div className="flex gap-5 items-center w-10/12">
        <img
          src={classInfo.image_path}
          alt="action class image"
          width={50}
          height={50}
        />
        <div className="flex flex-col items-start">
          <p className="text-left">{classInfo.class_title}</p>
          <p
            className="a"
            onClick={handleChangeClass}
          >
            {`Change Class`}
          </p>
        </div>
      </div>
      <button
        className="bg-white px-2 cursor-pointer transition duration-150 hover:opacity-70"
        onClick={handleRemoveClass}
      >
        <img
          src={Xicon}
          alt="close icon"
          width={15}
          height={15}
        />
      </button>
    </div>
  )
}

export default ActionClassTab