import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import useAuthStore from 'stores/auth'
import { LOGIN_PAGE_PATH } from 'routes'
import { useQuery } from '@apollo/client'
import { heartbeatQuery } from 'graphql/queries/heartbeat'
import { reportToSentry } from 'utils/reportToSentry'
import { useEffect } from 'react'

const AuthRoute = ({...routeProps}) => {
  const { getCurrentHash, setPreAuthFrom, evaluateIsLoggedIn } = useAuthStore()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  useEffect( () => {
    setPreAuthFrom( routeProps?.location?.pathname )
  }, [] )

  useQuery( heartbeatQuery, {
    skip: getCurrentHash()?.length, // skip if patient hash exists
    onError: ( error ) => {
      if ( error?.message === `The current customer isn't authorized.` ) {
        // Save route for redirect after login
        setPreAuthFrom( pathname )

        return navigate( `/logout` )
      }

      reportToSentry( `Heartbeat Query Failed`, {
        error
      })
    },
    fetchPolicy: `network-only`
  })

  if ( !evaluateIsLoggedIn() ) {

    return (
      <Navigate
        to={{
          pathname: LOGIN_PAGE_PATH,
          state: {
            from: routeProps.path
          }
        }}
        from={routeProps.path}
      /> )
  }

  return routeProps.element
}


export default AuthRoute
