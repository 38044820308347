import * as React from 'react'
import { getUniqueIdPrefix } from './utils'
import { TextInputProps } from '../types'

/** Error handling works in two ways
 * a) pass a error function and message through props.validator to work on onChange
 * b) pass a the error message to be displayed through the prop error */
const TextInput = ({
  name,
  value,
  type = `text`,
  required = false,
  label,
  className = `input`,
  containerClassName = `flex flex-col`,
  errorClassName = `input-error`,
  labelClassName = `font-semibold mb-1.5`,
  errorMessageClassName = `text-error px-3 text-xs mt-1.5`,
  asteriskClassName = `text-error`,
  errorMessage = ``,
  description = ``,
  formatter,
  validator,
  reference,
  onChange,
  onBlur,
  disabled = false,
  id,
  ...rest
} : TextInputProps ) : JSX.Element => {
  const [ validationError, setValidationError ] = React.useState( errorMessage )
  const uniqueId = id || `${getUniqueIdPrefix()}-${name}`

  React.useEffect( () => {
    setValidationError( errorMessage )
  }, [ errorMessage ] )

  const onHandleChange = ( event : React.ChangeEvent<HTMLInputElement> ) => {
    if ( formatter?.function && !formatter?.onBlur ) {
      event.currentTarget.value = formatter.function( event.currentTarget.value )
    }

    onChange( event )

    // in the case we are handling error displaying through the error message
    if ( !validator ) return
    else if ( validator && validator.function && !validator.function( event.currentTarget.value ) ) {
      setValidationError( validator.failureMessage || `Invalid Entry` )
    } else setValidationError( `` )
  }

  const onHandleBlur = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    if ( formatter?.function && formatter?.onBlur ) {
      event.currentTarget.value = formatter.function( event.currentTarget.value )
      onChange( event )
    }
    onBlur && onBlur( event )
  }

  // sets error, disabled, or normal className on input
  const inputClassName = ( validationError ) ? errorClassName : (
    ( disabled ) ? `input-disabled ${className}` : className
  )

  return (
    <div className={containerClassName}>
      {label && (
        <label htmlFor={uniqueId} className={labelClassName}>
          {label}
          {required && <span className={asteriskClassName}>{`*`}</span>}
        </label>
      )}

      {description && <p className="text-gray-500 mb-2">{description}</p>}

      {
        type === `textarea` ?
          <textarea
            id={uniqueId}
            name={name}
            value={value}
            onChange={onHandleChange as unknown as React.ChangeEventHandler<HTMLTextAreaElement>}
            required={required}
            ref={reference}
            disabled={disabled}
            rows={5}
            cols={50}
            {...rest}
          /> :
          <input
            id={uniqueId}
            name={name}
            type={type}
            value={value}
            onChange={onHandleChange}
            className={inputClassName}
            required={required}
            ref={reference}
            disabled={disabled}
            onBlur={onHandleBlur}
            {...rest}
          />
      }

      {validationError && <p className={errorMessageClassName}>{validationError}</p>}
    </div>
  )
}

export default TextInput
