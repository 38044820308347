import React from 'react'

function RecaptchaMessage() : JSX.Element {
  return (
    <p className="text-gray-500 text-sm m-2">
      {`This site is protected by reCAPTCHA and the Google `}
      <a
        href="https://policies.google.com/privacy"
        target="_blank"
        rel="noreferrer"
      >
        {`Privacy Policy`}
      </a>
      {` and `}
      <a
        href="https://policies.google.com/terms"
        target="_blank"
        rel="noreferrer"
      >
        {`Terms of Service`}
      </a>
      {` apply.`}
    </p>
  )
}

export default RecaptchaMessage