import { TextInput } from 'components/Forms/components'
import { useEffect, useRef } from 'react'

export type ValidatorType = {
  function: ( _value: string ) => boolean;
  failureMessage: string;
}

export type ChangeTextInputProps = {
  description: string;
  value: string;
  setValue: ( _newValue : string ) => void;
  name: string;
  shouldBeHidden: boolean;
  changeText?: string;
  onChangeClick?: () => void;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
}

export default function ChangeTextInput({
  name,
  changeText,
  onChangeClick = () => { return },
  description,
  value,
  setValue,
  disabled = false,
  shouldBeHidden = false,
  placeholder = ``,
  type=`text`
} : ChangeTextInputProps ) : JSX.Element | null {
  if ( shouldBeHidden ) return null

  const inputRef = useRef<HTMLInputElement>( null )

  useEffect( () => {
    // autofocus on mount
    inputRef?.current?.focus()
  }, [] )

  return (
    <div className="flex flex-col">
      {changeText && (
        <p className="cursor-pointer" onClick={onChangeClick}>
          {changeText} <span className="a">{`Change`}</span>
        </p>
      )}
      {description && <p className="text-gray-500 mb-2">{description}</p>}
      <TextInput
        className="input w-full"
        value={value}
        placeholder={placeholder}
        onChange={( e: React.ChangeEvent<HTMLInputElement> ) => { return setValue( e.target.value ) }}
        name={name} label={undefined} formatter={undefined}
        validator={undefined}
        reference={inputRef}
        type={type}
        disabled={disabled}
      />
    </div>
  )
}