import Tile from '../Tile'
import useAuthStore from 'stores/auth'
import RingLoader from 'components/RingLoader'
import { return_to_my_account_icon } from 'assets/herHub'
import { useEffect, useState } from 'react'
import { getHash } from 'utils'

function ReturnToMyAccount() : JSX.Element | null {

  const { getCurrentToken } = useAuthStore()
  const token = getCurrentToken()
  const hash = getHash()

  const [ loading, setLoading ] = useState<boolean>( true )

  useEffect( () => {
    const tokenCheckInterval = setInterval( () => {
      const token = getCurrentToken()
      // token will now be generated in the header but if the hub is launched initially we need to wait for it
      if ( token ) {
        setLoading( false )
        clearInterval( tokenCheckInterval )
      }
    }, 1000 )
  }, [] )

  if ( ( !token && !hash ) ) return null

  if ( loading ) return (
    <div className="my-4">
      <RingLoader />
    </div>
  )

  return (
    <Tile
      className="cursor-pointer"
      onClick={() => {
        setLoading( true )
        if ( token ) window.location.href = `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/customer/order/history?token=${token}`
        else window.location.href = `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/customer/account/login`
      }}
    >
      <div className="flex gap-6">
        <img
          src={return_to_my_account_icon}
          width={75}
        />
        <div className="flex flex-col gap-1">
          <p className="left-prata text-sm font-light text-left">
            {`Looking for an Update?`}
          </p>
          <p className="hub-heading text-gray-dark">
            {`Return to My Account Dashboard`}
          </p>
        </div>
      </div>
    </Tile>
  )
}

export default ReturnToMyAccount