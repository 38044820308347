import { useMemo } from "react"
import { Class, ScheduledClass } from "types/shopScheduleTypes"


// this custom hook is to dedupe classes that have both pre-reg and legacy on the FE
// if there is both we want to display the pre-reg class
function useDedupePreReg( classes: Class[] | null, scheduledClasses?: ScheduledClass[] ) : Class[] | null {
  const dedupedClasses = useMemo( () => {
    return classes?.filter( ( classItem: Class, index: number ) => {

      // hide both classes if either pre-reg or legacy is in scheduled classes
      const classIsInScheduled = scheduledClasses?.find( ( c: ScheduledClass ) => {
        return c?.class_title?.trim() === classItem.class_title?.trim()
      })

      // merge pre-reg and legacy
      const classesWithoutCurrent = [ ...classes ]
      classesWithoutCurrent.splice( index, 1 )
      const sameClass = classesWithoutCurrent.find( ( c: Class ) => {

        return c.internal_class_title?.trim() === classItem.internal_class_title?.trim()
      })

      return ( !scheduledClasses || !classIsInScheduled ) && ( !sameClass || ( sameClass && !classItem.is_legacy ) || ( !sameClass.is_legacy && !classItem.is_legacy ) )// should not run into this but handling just in case
    }) ?? null
  }, [ classes, scheduledClasses ] )

  return dedupedClasses
}

export default useDedupePreReg