export type ReviewProps = {
  quote: string;
  author: string;
}
import { star } from 'assets/herHub'

export default function Review({ quote, author} : ReviewProps ) : JSX.Element {
  return (
    <div className="p-2 flex flex-col justify-evenly h-80">
      <h1 className="hub-heading text-gray-dark text-center my-1 se:w-52 w-72 mx-auto se:text-xs text-sm">{`"${quote}"`}</h1>
      <div>
        <div className="flex gap-1 w-full justify-center my-1">
          <img src={star} width={21} height={21} />
          <img src={star} width={21} height={21} />
          <img src={star} width={21} height={21} />
          <img src={star} width={21} height={21} />
          <img src={star} width={21} height={21} />
        </div>
        <p className="text-center my-1 hub-p">{`- ${author}`}</p>
      </div>
    </div>
  )
}