import { Link } from 'react-router-dom'

export const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <p>{`Unfortunately, this page does not exist.`}</p>
      <p>
        {`Please check your URL or return to the `}{` `}
        <Link to="/login">{`Home Page`}</Link>
        {`.`}
      </p>
    </div>
  )
}

export default NotFound
