
import { useState, FormEvent, ChangeEvent } from "react"
import useForgotPasswordStore from "./store"
import { TextInput } from "components/Forms/components"
import { reportToSentry } from "utils/reportToSentry"
import { useMutation } from "@apollo/client"
import { requestPasswordResetEmailMutation } from 'graphql/mutations'
import RingLoader from "components/RingLoader"
import useGoogleReCaptchaGetter from "hooks/useGoogleRecaptchaGetter"
import RecaptchaMessage from "components/GoogleRecaptchaMessage/RecaptchaMessage"

export default function RequestPasswordForm() : JSX.Element {

  const { email, setFieldState } = useForgotPasswordStore()
  const [ errorMessage, setErrorMessage ] = useState( `` )

  const { token, handleReCaptchaVerify } = useGoogleReCaptchaGetter()

  const [ requestResetPasswordEmail, { data, loading }] = useMutation( requestPasswordResetEmailMutation, {
    variables: {
      email
    },
    context: {
      headers: {
        Store: `lactation`,
        'X-ReCaptcha': token
      }
    },
    errorPolicy: `all`,
    onError: ( ( error ) => {
      reportToSentry( new Error ( `RequestPasswordResetForm -> requestPasswordResetEmail`, {
        cause: error
      }) )
    })
  })

  const handleSubmit = async ( e : FormEvent ) => {
    e.preventDefault()
    await handleReCaptchaVerify()
    setErrorMessage( `` )
    // submit request for password reset
    const result = await requestResetPasswordEmail()

    if ( !result?.data?.requestPasswordResetEmail ) {
      setErrorMessage( `Either we couldn't find an account with that email, or that email is invalid` )
    }

    if ( result?.errors?.length ) {
      setErrorMessage( result.errors.map( errorObject => { return errorObject.message }).join( ` ` ) )
    }

  }

  const handleChange = ( e : ChangeEvent<HTMLInputElement> ) => {
    setFieldState( e.target.name, e.target.value )
  }

  const heading = <h1 className="heading">{`Forgot Password`}</h1>

  if ( data && data.requestPasswordResetEmail ) return <p className="my-3 max-w-sm mx-auto text-center">{`You will receive an email with instructions to reset your password shortly`}</p>
  if ( loading ) return <div className="flex flex-col gap-4 my-4">{heading}<RingLoader /></div>

  return (
    <form className="max-w-md mx-auto flex flex-col items-center gap-4 px-3" onSubmit={handleSubmit}>

      {heading}

      <div>
        <TextInput
          name="email"
          value={email}
          onChange={handleChange}
          containerClassName="flex flex-col items-center gap-2"
          className="input w-full"
          label={`Confirm your email`}
          labelClassName="text-center max-w-xs mt-4"
          formatter={undefined} validator={undefined} reference={undefined}
        />
        <RecaptchaMessage />
      </div>


      { errorMessage && <p className="text-error max-w-md mx-auto text-center">{errorMessage}</p>}

      <button className="primary-button primary-button my-4" type="submit" disabled={loading}>{`Request New Password`}</button>

    </form>
  )
}