import { useEffect, useState } from "react"
import { icoVisible, icoInvisible } from "assets"
import PropTypes from 'prop-types'
import { getUniqueIdPrefix } from "./utils"


export default function PasswordInput({
  name,
  value,
  required = false,
  label,
  className = `input`,
  errorClassName = `input-error`,
  labelClassName = `input-label`,
  errorMessageClassName = `text-error px-3`,
  asteriskClassName = `font-semibold text-error pl-2`,
  errorMessage = ``,
  formatter,
  validator,
  reference,
  onChange,
  onBlur,
  disabled = false,
  id = ``,
  changeText = ``,
  onChangeClick = () => { return },
  ...rest
}) {
  const [ showPassword, setShowPassword ] = useState( false )

  const [ validationError, setValidationError ] = useState( errorMessage )
  const uniqueId = id || `${getUniqueIdPrefix}-${name}`

  useEffect( () => {
    setValidationError( errorMessage )
  }, [ errorMessage ] )

  const onHandleChange = event => {
    if ( formatter?.function && !formatter?.onBlur ) {
      event.currentTarget.value = formatter.function( event.currentTarget.value )
    }

    if ( validator && validator.function && !validator.function( event.currentTarget.value ) ) {
      setValidationError( validator.failureMessage || `Invalid Entry` )
    } else {
      setValidationError( `` )
    }

    onChange( event )
  }

  const onHandleBlur = event => {
    if ( formatter?.function && formatter?.onBlur ) {
      event.currentTarget.value = formatter.function( event.currentTarget.value )
      onChange( event )
    }
    onBlur && onBlur( event )
  }

  // sets error, disabled, or normal className on input
  const inputClassName = ( validationError ) ? errorClassName : (
    ( disabled ) ? `input-disabled ${className}` : className
  )

  return (
    <>
      {changeText && (
        <p className="cursor-pointer" onClick={onChangeClick}>
          {changeText} <span className="a">{`Change`}</span>
        </p>
      )}
      {label && (
        <label htmlFor={uniqueId} className={labelClassName}>
          {label}
          {required && <span className={asteriskClassName}>{`*`}</span>}
        </label>
      )}
      <div className="relative h-12">
        <input
          id={uniqueId}
          name={name}
          type={showPassword ? `text` : `password`}
          value={value}
          onChange={onHandleChange}
          className={inputClassName}
          required={required}
          ref={reference}
          disabled={disabled}
          onBlur={onHandleBlur}
          {...rest}
        />
        <div
          className="absolute cursor-pointer right-3 bottom-5"
        >
          <img
            src={showPassword ? icoInvisible : icoVisible} alt="show/hide password" height="24px"
            width="24px"
            className={`text-midnight1 -mt-5`}
            onClick={() => { setShowPassword( !showPassword ) }}
          />
        </div>
      </div>
      {validationError && <p className={errorMessageClassName}>{validationError}</p>}
    </>
  )
}

PasswordInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  required: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  errorClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  errorMessageClassName: PropTypes.string,
  asteriskClassName: PropTypes.string,
  errorMessage: PropTypes.oneOfType( [ PropTypes.string, PropTypes.bool ] ),
  formatter: PropTypes.shape({
    function: PropTypes.func,
    onBlur: PropTypes.bool
  }),
  validator: PropTypes.shape({
    function: PropTypes.func,
    failureMessage: PropTypes.string
  }),
  reference: PropTypes.any,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  changeText: PropTypes.string,
  onChangeClick: PropTypes.func
}