import { useMemo } from "react"
import useAppointmentHelpers from "stores/appointmentHelpers"
import { FormattedAppointments } from "types/shopScheduleTypes"
import { addZeroIfNeeded, getMDY } from "utils"
import { DAYS, MONTHS } from "utils/constants"
import { dateFromKey } from "./utils"

export type FormattedTimeSlot = {
  start_time: string;
  lc_name?: string;
  duration_minutes: number;
  begin_time_pretty: string;
  event_instance_id: string;
}

function useDatePicker(
  appointments: FormattedAppointments,
  dayFromCal: Date | null,
  setDayFromCal: ( _d: Date ) => void,
  setSelectedMonth: React.Dispatch<React.SetStateAction<Date>>,
  dynamicMonthBoundary?: boolean
) {

  const {staffRequired, setConsultantName} = useAppointmentHelpers()

  const { appointmentDates, sortedAppointmentKeys } = useMemo( () => {
    const apptKeys = Object.keys( appointments )

    apptKeys.sort( ( a, b ) => {
      return a < b ? -1 : 1
    })

    return Object.assign({}, {
      appointmentDates: apptKeys.map( d => {

        // in this case there should only be one required LC
        if ( staffRequired === 1 && appointments[d][0]?.lc_name ) setConsultantName( appointments[d][0].lc_name )

        const currentDate = d.split( `-` )

        return new Date(
          parseInt( currentDate[0] ),
          parseInt( currentDate[1] ) - 1,
          parseInt( currentDate[2] )
        )
      }),
      sortedAppointmentKeys: apptKeys
    })
  }, [ appointments ] )



  const [ selectedDayLabel, times ] = useMemo( () => {

    if ( !dayFromCal ) return [ null, [] ]
    const { year, month, date, weekday } = getMDY( dayFromCal )

    if ( typeof year !== `number` || typeof month !== `number` || typeof date !== `number` || typeof weekday !== `number` ) return [ null, [] ]

    const dateKey = `${year}-${addZeroIfNeeded(
      month + 1
    )}-${addZeroIfNeeded( date )}`

    const timesMap:{ [key: string]: FormattedTimeSlot} = {}
    const times = appointments[dateKey] || []
    times.forEach( item => {
      timesMap[item.start_time] = item
    })

    return [
      `${DAYS[weekday]}, ${MONTHS[month]} ${addZeroIfNeeded( date )}`,
      Object.values( timesMap ).sort(
        ( a: FormattedTimeSlot, b: FormattedTimeSlot ) => { return a.start_time.localeCompare( b.start_time ) }
      )
    ]
  }, [ dayFromCal, appointments ] )

  const [ fromMonth, toMonth ] = useMemo( () => {
    const firstApptDate = dateFromKey( sortedAppointmentKeys[0] )

    setDayFromCal( firstApptDate )
    setSelectedMonth( firstApptDate )

    if ( !dynamicMonthBoundary ) {
      const sixMonthsFromNow = new Date()
      sixMonthsFromNow.setMonth( sixMonthsFromNow.getMonth() + 5 )

      return [ new Date(), sixMonthsFromNow ]
    }
    if ( !appointments || !sortedAppointmentKeys?.length ) {
      const constantFromMonth = new Date()
      const constantToMonth = new Date()
      constantToMonth.setMonth( constantToMonth.getMonth() + 3 )

      return [
        constantFromMonth,
        constantToMonth
      ]
    }

    return [
      firstApptDate,
      dateFromKey( sortedAppointmentKeys[ sortedAppointmentKeys.length - 1 ] )
    ]
  }, [ appointments, sortedAppointmentKeys ] )

  return {
    appointmentDates,
    sortedAppointmentKeys,
    fromMonth,
    toMonth,
    selectedDayLabel,
    times
  }
}

export default useDatePicker