import React from 'react'
import { Class } from 'types/shopScheduleTypes'
import { ClassConfirmCheck, Xicon } from 'assets'
import { useNavigate } from 'react-router-dom'
import useSelectedClasses from 'hooks/useSelectedClasses'
import { INDIVIDUAL_SELECTION_BASE_PATH } from 'routes'
import { scrollToTop } from 'utils'
import useSelectedBundle from 'hooks/useSelectedBundle'

type ConfirmClassTab = {
  classInfo: Class;
  allowRemove?: boolean;
  displayTimezone?: boolean;
  removeTimes: ( _classInfo: Class[] ) => void;
  toggleSkipScheduling: ( _classInfo: Class ) => void;
}

function ConfirmClassTab({ classInfo, removeTimes, toggleSkipScheduling, allowRemove = false, displayTimezone = false } : ConfirmClassTab ) : JSX.Element {

  const navigate = useNavigate()
  const { selectedClasses, removeClasses, clearClasses, clearDayFromCal } = useSelectedClasses()
  const { selectedBundle } = useSelectedBundle()

  const handleChangeTime = ( classDetails: Class ) => {
    clearDayFromCal()
    const allClasses = getCurrentSeriesClasses( classDetails )
    removeTimes( allClasses )
    scrollToTop()
  }

  const getCurrentSeriesClasses = ( classDetails: Class ) => {
    if ( !classDetails.is_series ) return [ classDetails ]

    return selectedBundle?.classes?.length ?
      selectedBundle?.classes?.filter( ( classItem: Class ) => {
        return classItem.is_series && classItem.series_pk === classDetails.series_pk
      })
      :
      selectedClasses?.filter( ( classItem: Class ) => {
        return classItem.is_series && classItem.series_pk === classDetails.series_pk
      })
  }

  const handleRemoveClass = () => {
    const allClasses = getCurrentSeriesClasses( classInfo )
    removeClasses( allClasses )
    if ( !selectedClasses.length ) {
      navigate( `${INDIVIDUAL_SELECTION_BASE_PATH}${window.location.search}` )

      return clearClasses()
    }
  }

  return (
    <div className="flex justify-between mb-10 w-full max-w-xl">
      <div className="flex gap-5 w-full">
        <div className="relative">
          <img
            src={classInfo.image_path}
            alt="confirm class image"
            width={80}
            height={80}
          />
          {
            !classInfo.skip_scheduling &&
            <img
              src={ClassConfirmCheck}
              alt="class confirm check image"
              width={18}
              height={18}
              className="object-contain absolute top-0 right-0"
            />
          }
        </div>
        <div className="flex flex-col w-9/12">
          <p className="text-lg md:text-xl">{classInfo.class_title}</p>
          {
            classInfo?.selected_timeslot &&
            <p className="md:text-lg">{`${classInfo.selected_timeslot?.scheduled_date_pretty.substring( 0, classInfo.selected_timeslot?.scheduled_date_pretty?.length - 5 )} ${classInfo.selected_timeslot?.begin_time_pretty} ${displayTimezone ? classInfo?.selected_timeslot?.time_zone : ``}`}</p>
          }
          <p
            className="a"
            onClick={() => { return classInfo.skip_scheduling ? toggleSkipScheduling( classInfo ) : handleChangeTime( classInfo ) }}
          >
            {classInfo.skip_scheduling ? `Schedule` : `Change Date or Time`}
          </p>
        </div>
        {
          selectedClasses.length > 0 && allowRemove &&
          <div className="w-1/12">
            <button
              className="bg-white px-2 cursor-pointer transition duration-150 hover:opacity-70"
              onClick={handleRemoveClass}
            >
              <img
                src={Xicon}
                alt="close icon"
                width={15}
                height={15}
              />
            </button>
          </div>
        }

      </div>
    </div>
  )
}

export default ConfirmClassTab