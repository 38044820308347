import React from 'react'

function IneligibleBlock() : JSX.Element {
  return (
    <div className="w-3/4 mx-auto border border-solid border-red-500">
      <p className="text-center text-error px-12 py-24">
        <span>
          {`Hey mama, looks like you aren't eligible to schedule at this time. Please give us a call at`}
        </span>
        <span>
          <a className="cursor-pointer underline decoration-error" href="tel:+18887323979">{` 888-732-3979 `}</a>
        </span>
        <span>
          {`for questions.`}
        </span>
      </p>
    </div>
  )
}

export default IneligibleBlock