import { useState, useEffect } from 'react'
import { ScheduledClass } from 'types/shopScheduleTypes'

function useSeriesClasses( scheduledClass: ScheduledClass, scheduledClasses: ScheduledClass[] ) : ScheduledClass[] {
  const [ seriesClasses, setSeriesClasses ] = useState<ScheduledClass[]>( [] )

  useEffect( () => {
    if ( scheduledClass?.class_series_pk ) {
      const allSeriesClasses = scheduledClasses.filter( ( c: ScheduledClass ) => {
        return c.class_series_pk === scheduledClass.class_series_pk
      })
      setSeriesClasses( [ ...allSeriesClasses ] )
    }
  }, [ scheduledClasses ] )

  return seriesClasses
}

export default useSeriesClasses