import { gql } from '@apollo/client'

import { productsFragment } from 'graphql/fragments/products'

export const getProductsQueryBySkuArray = gql`
  ${productsFragment}
  query getProductQueryBySku($skus: [String!], $pageSize: Int) {
    resupplyProducts(filter: {sku: {in: $skus} }, pageSize: $pageSize) {
      ...Products
    }
  }
`


export const getProductsQueryBySku = gql`
  ${productsFragment}
  query getProductQueryBySku($sku : String!) {
    resupplyProducts(filter: {sku: {eq: $sku} }) {
        ...Products
      }
  }
`