import React from 'react'
import styled from 'styled-components'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const tailwindColors = require( `config/tailwindColors` )

const Wrapper = styled.div.attrs({
  className: `my-0 mx-auto relative w-30`
})`
  & > div {
    border: 8px solid ${tailwindColors.pink[`3`]};
    border-top: 8px solid ${tailwindColors.secondary};
    border-radius: 100%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  .two {
    top: 5px;
    left: 5px;
    border: 5px solid #FEF8F8;
    width: 110px;
    height: 110px;
    animation: spin 4s linear infinite;
  }
  .three {
    top: 10px;
    left: 10px;
    border: 4px solid #FEF8F8;
    border-top: 4px solid ${tailwindColors.primary};
    width: 100px;
    height: 100px;
    animation: spin 4s linear infinite;
  }
  .four {
    border: 2px solid ${tailwindColors.pink[`3`]};
    border-right: 2px solid #FEF8F8;
    width: 80px;
    height: 80px;
    animation: spin-reverse 4.25s linear infinite;
  }
  .five {
    top: 26px;
    left: 26px;
    border: 2px solid ${tailwindColors.pink[`3`]};
    width: 68px;
    height: 68px;
    animation: spin 1.25s linear infinite;
  }
  .six {
    border: 4px solid transparent;
    border-top: 4px solid ${tailwindColors.secondary};
    width: 80px;
    height: 80px;
    animation: spin-reverse 4.25s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spin-reverse {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`

function RingLoader() {
  return (
    <Wrapper>
      <div />
      <div className="absolute top-3 left-3 two"></div>
      <div className="absolute top-5 left-5 three"></div>
      <div className="absolute top-5 left-5 four"></div>
      <div className="absolute five"></div>
      <div className="absolute top-5 left-5 six"></div>
    </Wrapper>
  )
}

export default RingLoader
