export default async function postCancelClass( event_instance_id : string, event_attendance_id : string ) : Promise<Response> {
  return await fetch( `${process.env.REACT_APP_AWS_URL}/cancel-groupclass-booking`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`
    },
    body: JSON.stringify({
      event_instance_id,
      event_attendance_id
    })
  })
}