import { AWSResponse } from 'types/aws'
import { PatientHistoryForm } from 'types/integratedPatientHistory'

export const submitPatientHistoryForm = async ( auth: string, values: PatientHistoryForm, epdsScore?: number, selfHarmResponse?: string ): Promise<AWSResponse<undefined>> => {
  const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/patient-history`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`,
      'Authorization': auth
    },
    body: JSON.stringify({
      form_json: JSON.stringify( values ),
      epds_score: epdsScore ?? ``,
      self_harm_response: selfHarmResponse ?? ``
    })
  })

  return await response.json()
}