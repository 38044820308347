import React from 'react'
import useHerHubStore from 'stores/herHub'
import OneOnOneNotification from './OneOnOneNotification'

function OneOnOneUpdates() : JSX.Element {

  const { oneOnOneNotifications } = useHerHubStore()

  return (
    <div className="flex flex-col">
      {
        oneOnOneNotifications.map( ( notification ) => {
          return <OneOnOneNotification key={notification.appointment_id} notification={notification} />
        })
      }
    </div>
  )
}

export default OneOnOneUpdates