import Tile from './Tile'

export default Tile

export * from './ClassManagement'
export * from './OneOnOne'
export { default as ShopAndSchedule } from './ShopAndSchedule'
export { default as ReviewsTile } from './Reviews/ReviewsTile'
export { default as RecentClassUpdates } from './RecentClassUpdates'
export { default as OneOnOneUpdates } from './OneOnOneUpdates'
export { default as HelpfulResources } from './HelpfulResources/HelpfulResources'
export { default as ReturnToMyAccount } from './ReturnToMyAccount/ReturnToMyAccount'
export { default as OneOnOneManagement } from './OneOnOne/Management'
export { default as OneOnOneSchedule } from './OneOnOne/Schedule'
