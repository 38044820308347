import { useEffect, useState, useReducer } from "react"
import { fetchAppointmentTimeslots } from 'apis'
import { cachedResultsReducer, initialState } from "./reducer"
import { reportToSentry } from "utils/reportToSentry"
import useAppointmentHelpers from "stores/appointmentHelpers"
import useAuthStore from '../../../stores/auth'

export const useFetchAppointmentsByPageHook = ( timeZone, pageIndex, noCache, setPageIndex, setSelectedAppointment ) => {
  const { staffRequired, setStaffRequired } = useAppointmentHelpers()

  const [ loading, setLoading ] = useState( false )
  const [ error, setError ] = useState( `` )
  const [ serviceId, setServiceId ] = useState( `` )
  const [ cachedPageData, dispatchCachedPageData ] = useReducer( cachedResultsReducer, initialState )
  const [ appointments, setAppointments ] = useState( null )
  const { buildAuthorizer } = useAuthStore()


  useEffect( () => {
    const controller = new AbortController()

    let isResultCached = cachedPageData && cachedPageData[ pageIndex ] && cachedPageData[ pageIndex ][ timeZone ]

    if ( isResultCached && !staffRequired ) {
      isResultCached = false
    }

    if ( timeZone && error !== `Patient Ineligible` ) {
      if ( !isResultCached ) {
        setLoading( true )
        fetchAppointmentTimeslots( staffRequired, pageIndex, timeZone, buildAuthorizer(), controller.signal ).then( data => {

          setLoading( false )

          if ( data?.data?.staff_available === false ) return setStaffRequired( 0 ) // need to rerun the query without staff required

          // patient is ineligible for scheduling
          if ( data?.data?.patient_eligibility === false ) return setError( `Patient Ineligible` )

          if ( data?.data?.service_id ) setServiceId( data.data.service_id )
          else {
            reportToSentry( `no service id`, {
              data
            })

            return setError( `A service id could not be found for this session. Please refresh your browser tab. If issues persist please contact customer service.` )
          }

          // patient is eligible for scheduling but there are no appointments available for this month so go to next month
          if ( Object.keys( data?.data?.availabilities )?.length === 0 ) {
            if ( pageIndex < 3 ) {
              if ( setSelectedAppointment ) setSelectedAppointment( null )

              return setPageIndex ? setPageIndex( parseInt( pageIndex ) + 1 ) : null
            } else setError( `No Appointments` )
          }

          dispatchCachedPageData({
            newData: data.data.availabilities,
            timezone: timeZone,
            page: pageIndex,
            type: `add`
          })
          // set appointments here
          setAppointments( data?.data?.availabilities )
        })
          .catch( error => {
            if ( error.name !== `AbortError` ) {
              setError( `Oops there was an error when attempting to get the appointments for this month...` )
              reportToSentry( `One on one: There was an error getting appointment data...`, {
                error,
                pageIndex
              })
            }

            setLoading( false )
          })
      } else setAppointments( cachedPageData[ pageIndex ][ timeZone ] )
    }

    return () => {
      controller.abort()
    }
  }, [ pageIndex, timeZone, staffRequired ] )

  useEffect( () => {
    const controller = new AbortController()

    if ( loading ) controller.abort()

    if ( timeZone && appointments && !loading && !noCache && error !== `Not Found` ) {
      const pageRequests = []
      for ( let i = 1; i <= 6; i++ ) {
        const isResultCached = cachedPageData && cachedPageData[ i ] && cachedPageData[ i ][ timeZone ]
        if ( !isResultCached ) pageRequests.push( fetchAppointmentTimeslots( staffRequired, pageIndex, timeZone, buildAuthorizer(), controller.signal ) )
      }
      if ( pageRequests.length ) {
        Promise.all( pageRequests )
          .then( data => {
            data.forEach( ( data ) => {
              if ( data?.status === 200 && data?.response?.availabilities && data?.request?.page ) {
                dispatchCachedPageData({
                  newData: data.data.availabilities,
                  page: data.request.page,
                  timezone: timeZone,
                  type: `add`
                })
              }
            })
          })
          .catch( ( error ) => {
            if ( error.name !== `AbortError` ) reportToSentry( `Clinical Scheduling: There was an exception getting appointments in the background`, error )
          })
      }
    }

    return () => {
      controller.abort()
    }
  }, [ timeZone, loading ] )

  return {
    data: appointments,
    loading,
    error,
    serviceId
  }
}