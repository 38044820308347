import { HTMLProps, ReactNode, Ref, useEffect, useRef } from 'react'

export interface ModalProps extends HTMLProps<HTMLDivElement> {
  isOpen: boolean
  title?: string
  subHeading?: string
  children: ReactNode
  onClose: () => void
  closeBtnTitle?: string
  iconId?: string
  containerStyling?: string
  defaultCloseButton?: boolean
  hasClickListener?: boolean
}

const Modal = ({
  isOpen,
  title = ``,
  subHeading = ``,
  children,
  onClose,
  closeBtnTitle = `Close`,
  iconId = ``,
  containerStyling = `bg-white`,
  defaultCloseButton = true,
  hasClickListener = true
}: ModalProps ) => {

  const modalRef: Ref<any> = useRef( null )

  useEffect( () => {
    document.removeEventListener( `mousedown`, triggerClose )
    if ( isOpen && hasClickListener ) {
      document.addEventListener( `mousedown`, triggerClose )
      document.body.style.overflow = `hidden`
    }

    return () => {
      document.body.style.overflow = `auto`
      document.removeEventListener( `mousedown`, triggerClose )
    }
  }, [ isOpen ] )

  const triggerClose = ( e: any ) => {
    if ( !modalRef?.current?.contains( e?.target ) && ( !iconId || e.target.id !== iconId ) ) onClose()
  }

  if ( !isOpen ) return null

  return (
    <div className="fixed inset-0 z-50 overflow-auto bg-gray-light bg-opacity-70">
      <div
        className="min-w-screen m-3 animated fadeIn faster absolute flex justify-center items-center inset-0 z-50 outline-none focus:outline-none"
      >
        <div className={`w-full max-w-md relative mx-5 my-auto shadow-lg ${containerStyling}`} ref={modalRef}>
          <div className="text-center w-full max-w-lg">
            <h1>{title}</h1>
          </div>
          <h2 className="text-xl text-black pl-4">{subHeading}</h2>
          <div className="mx-auto">
            {children}
            {onClose && defaultCloseButton &&
              <button className="primary-button" onClick={onClose}>{closeBtnTitle}</button>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
