import { useState } from "react"
import postCancelClass from 'apis/postCancelClass'
import { reportToSentry } from "utils/reportToSentry"
import { RescheduleClassDetails } from "types/shopScheduleTypes"
import { getSource } from "utils/getSource"

type RescheduleHookReturn = {
  rescheduleSingle: ( _classDetail : RescheduleClassDetails, _authorization: string ) => Promise<void>,
  rescheduleMultiple: ( _classDetails : RescheduleClassDetails[], _authorization: string ) => Promise<void>,
  loading: boolean;
  errorMessage: string;
}

export default function useRescheduleClass() : RescheduleHookReturn {
  const [ loading, setLoading ] = useState( false )
  const [ errorMessage, setErrorMessage ] = useState( `` )

  // this function will submit rescheduling for all the series classes in parallel
  async function rescheduleMultiple( classDetails: RescheduleClassDetails[], authorization: string ) {
    setLoading( true )
    setErrorMessage( `` )

    await Promise.all(
      classDetails.map( async ( classDetail: RescheduleClassDetails ) => {
        return await rescheduleSingle( classDetail, authorization, true )
      })
    )
      .catch( error => {
        setLoading( false )
        setErrorMessage( `Oops... We encountered an exception attempting to reschedule your series classes.` )
        throw error
      })

    setLoading( false )
  }

  async function rescheduleSingle( classDetail : RescheduleClassDetails, authorization : string, isSeries = false ) {

    try {
      // don't pull down the spinner or reset the error message for series parallel rescheduling
      if ( !isSeries ) {
        setLoading( true )
        setErrorMessage( `` )
      }

      const scheduleResponse = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/schedule-class`, {
        method: `POST`,
        headers: {
          authorization
        },
        body: JSON.stringify({
          class_pk: [{
            pk: classDetail.class_pk,
            event_instance_id: classDetail.event_instance_id,
            scheduled_time: classDetail.scheduled_time,
            tz: classDetail.tz
          }
          ],
          source: getSource()
        })
      })

      if ( !( await scheduleResponse?.json() )?.data?.success ) {
        if ( !isSeries ) setLoading( false )
        reportToSentry( new Error( `Reschedule Class - failed to schedule class`, {
          cause: JSON.stringify( scheduleResponse )
        }) )

        return setErrorMessage( `Oops... We encountered an exception rescheduling your class.` )
      }

      const cancelResponse = await postCancelClass( classDetail.scheduled_event_instance_id, classDetail.scheduled_event_attendance_id )

      if ( !( ( await cancelResponse?.json() )?.message === `Submitted` ) ) {
        if ( !isSeries ) setLoading( false )
        reportToSentry( new Error( `Reschedule Class - failed to cancel class`, {
          cause: JSON.stringify( cancelResponse )
        }) )

        return setErrorMessage( `Oops... We encountered an exception canceling your previous class scheduling.` )
      }

      if ( !isSeries ) setLoading( false )
    } catch ( e ) {
      if ( !isSeries ) setLoading( false )
      setErrorMessage( `Oops... We encountered an exception rescheduling your class.` )
      throw e
    }

  }

  return {
    rescheduleSingle,
    rescheduleMultiple,
    loading,
    errorMessage
  }
}