import DOBInput from './DOBInput'
import { StyledInput } from './styled'
import PropTypes from 'prop-types'

const TextField = ({
  type = `text`,
  autoFocus,
  name,
  value,
  error,
  label,
  onChange,
  placeholder = ``,
  hideDOBLabels=false,
  required = true,
  disabled = false
}) => {
  return (
    <div>
      <label className="font-semibold mb-1.5">
        <p className="mb-2">
          {label}
          {required && <span className="text-error">{` *`}</span>}
        </p>
      </label>
      {type === `dob` ? (
        <DOBInput
          value={value}
          onChange={onChange}
          hideDOBLabels={hideDOBLabels}
          label={label}
          error={error}
          required
        />
      ) : (
        <div>
          <StyledInput
            name={name}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            autoFocus={Boolean( autoFocus )}
            disabled={disabled}
          />
          {error && (
            <div className="text-error pl-3.5 pt-2 text-xs">
              {error}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

TextField.propTypes = {
  type: PropTypes.string,
  autoFocus: PropTypes.oneOfType( [ PropTypes.string, PropTypes.bool ] ),
  name: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  hideDOBLabels: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool
}

export default TextField
