import Tile from '../index'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

function HelpfulResources(): JSX.Element | null {
  const openInNewTab = ( url: string ) => {
    window.open( url, `_blank`, `noreferrer` )
  }

  return (
    <Tile
      className="cursor-pointer"
      onClick={() => {
        openInNewTab( `https://aeroflowbreastpumps.com/media/amasty/amfile/attach/SmCy2tspqyuknHA7Uc3siQg8kujT2qWJ.png` )
      }}
    >
      <div className="flex gap-6">
        <div className="flex flex-col gap-4">
          <p className="hub-heading text-gray-dark">
            {`Helpful Resources`}
          </p>
          <div className={`flex items-center gap-1`}>
            <p className="">
              {`INSURANCE BILLING INFORMATION`}
            </p>
            <ArrowForwardIosIcon color="primary" fontSize="small" />
          </div>
        </div>
      </div>
    </Tile>
  )
}

export default HelpfulResources