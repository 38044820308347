import { PatientHistoryForm } from "types/integratedPatientHistory"

export const initialPatientHistory:PatientHistoryForm = {
  have_had_baby: ``,
  email: ``,
  type_of_appointment: ``,
  language_for_appointment: ``,
  other_language_for_appointment: ``,
  most_important_issue: ``,
  release_of_health_information: ``,
  first_baby: ``,
  baby_dob: ``,
  weeks_pregnant_at_birth: ``,
  baby_full_name: ``,
  baby_weight_at_birth: ``,
  baby_most_recent_weight: ``,
  baby_most_recent_weight_date: ``,
  baby_medical_history: [
    {
      "id": `none`,
      "name": `None`,
      "selected": false
    },
    {
      "id": `nicu_admission`,
      "name": `NICU admission`,
      "selected": false
    },
    {
      "id": `poor_weight_gain`,
      "name": `Poor weight gain`,
      "selected": false
    },
    {
      "id": `born_before_37_weeks`,
      "name": `Born before 37 weeks`,
      "selected": false
    },
    {
      "id": `reflux`,
      "name": `Reflux`,
      "selected": false
    },
    {
      "id": `other`,
      "name": `Other`,
      "selected": false,
      "value": ``
    }
  ],
  baby_prescription_medication: ``,
  your_medical_history: [
    {
      "id": `thyroid_disease`,
      "name": `Thyroid disease`,
      "selected": false
    },
    {
      "id": `diabetes`,
      "name": `Diabetes / Gestational diabetes`,
      "selected": false
    },
    {
      "id": `breast_surgery`,
      "name": `Breast surgery (biopsy, implants, reduction, nipple piercing)`,
      "selected": false
    },
    {
      "id": `nipple_inversion`,
      "name": `Nipple inversion`,
      "selected": false
    },
    {
      "id": `hypertension`,
      "name": `Hypertension`,
      "selected": false
    },
    {
      "id": `anemia`,
      "name": `Anemia`,
      "selected": false
    },
    {
      "id": `gastric_bypass`,
      "name": `Gastric bypass`,
      "selected": false
    },
    {
      "id": `gastrointestinal_problems`,
      "name": `Gastrointestinal problems`,
      "selected": false
    },
    {
      "id": `pcos`,
      "name": `Polycystic ovarian syndrome (PCOS)`,
      "selected": false
    },
    {
      "id": `fertility_problems`,
      "name": `Fertility problems`,
      "selected": false
    },
    {
      "id": `irregular_periods`,
      "name": `Irregular periods`,
      "selected": false
    },
    {
      "id": `pregnancy_loss`,
      "name": `Pregnancy loss`,
      "selected": false
    },
    {
      "id": `yeast_infections`,
      "name": `Yeast infections`,
      "selected": false
    },
    {
      "id": `gbs_positive`,
      "name": `Group beta strep (GBS) positive`,
      "selected": false
    },
    {
      "id": `hemorrhaging_after_birth`,
      "name": `Hemorrhaging after birth`,
      "selected": false
    },
    {
      "id": `breast_development_problems`,
      "name": `Problems with breast development during puberty`,
      "selected": false
    },
    {
      "id": `anxiety`,
      "name": `Anxiety`,
      "selected": false
    },
    {
      "id": `depression`,
      "name": `Depression`,
      "selected": false
    },
    {
      "id": `mood_disorders`,
      "name": `Other mood disorders`,
      "selected": false
    },
    {
      "id": `none_noted`,
      "name": `None noted`,
      "selected": false
    },
    {
      "id": `other`,
      "name": `Other`,
      "selected": false,
      "value": ``
    }
  ],
  your_medical_conditions: ``,
  your_prescription_medication: ``,
  your_family_medical_history: [
    {
      "id": `thyroid_disease`,
      "name": `Thyroid disease`,
      "selected": false
    },
    {
      "id": `diabetes`,
      "name": `Diabetes`,
      "selected": false
    },
    {
      "id": `breast_cancer`,
      "name": `Breast cancer`,
      "selected": false
    },
    {
      "id": `breastfeeding_difficulties`,
      "name": `Breastfeeding difficulties that your mother or sister(s) experienced with their babies`,
      "selected": false
    },
    {
      "id": `none_noted`,
      "name": `None noted`,
      "selected": false
    }
  ],
  been_able_to_laugh: ``,
  looked_forward_to_things: ``,
  blamed_myself: ``,
  anxious_or_worried: ``,
  scared_or_panicky: ``,
  feeling_overwhelmed: ``,
  unhappy_and_difficulty_sleeping: ``,
  sad_or_miserable: ``,
  unhappy_have_been_crying: ``,
  thoughts_of_harming_myself: ``
}