import { reportToSentry } from 'utils/reportToSentry'
import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

interface PatientDataStore {
    customerPricingData: object;
    setCustomerPricingData: ( _authHeader: string ) => Promise<void>;
}

declare global {
  interface Window {
    NOIBUJS: {
      addCustomAttribute: ( _name: string, _value: string ) => void;
    };
  }
}

async function addPatientIdToNoibu( patientId: string ) {
  if ( !window.NOIBUJS ) {
    await new Promise( resolve => {
      window.addEventListener( `noibuSDKReady`, resolve )
    })
  }
  window.NOIBUJS.addCustomAttribute( `patient_id`, patientId )
}

/**
 * Getting product pricing information for a patient. It also returns a lot of patient data.
 *
 * @param {string} authHeader
 * @returns json response which has patient data in it
 * @throws Error if an invalid response is returned
 */
async function fetchProductPricing( authHeader: string ) {
  const response = await fetch( `${process.env.REACT_APP_PATIENTS_URL}/v2/product-pricing?store_name=momandbaby`, {
    headers: {
      'Authorization': authHeader
    }
  })

  if ( response.ok ) {
    return await response.json()
  } else {
    throw new Error( `Error fetching product pricing: ` + response.status + ` ` + response.statusText )
  }
}

export const usePatientDataStore = create<PatientDataStore>()(
  persist( ( set ) => ({
    customerPricingData: {},
    setCustomerPricingData: async ( _authHeader: string ) => {
      try {
        const response = await fetchProductPricing( _authHeader )
        set({
          customerPricingData: response?.data ?? {}
        })

        const patientId = response.data?.patient?.patient_id?.toString() ?? `unknown`
        await addPatientIdToNoibu( patientId )

      } catch ( error ) {
        reportToSentry(
          new Error( `Error fetching customer pricing data`, {
            cause: error
          }),
          {
            error: JSON.stringify( error )
          }
        )
      }
    }
  }),
  {
    name: `customer-pricing`,
    storage: createJSONStorage( () => sessionStorage )
  })
)
