import qs from 'query-string'
import Cookies from 'js-cookie'
import { reportToSentry } from 'utils/reportToSentry'

export {
  sendOneTimePassword,
  loginOneTimePassword,
  defaultOTPLoginError,
  defaultOTPSendError,
  defaultOTPSuccess
} from './postOneTimePassword'

// This referral_url will break the leads request if it is on local or dev, which is the reasoning for the hard-code of the url on non-prod sites
const referral_url = process.env.REACT_APP_ENVIRONMENT !== `production` ? `https://react-lactation.aeroflow.ninja/education-classes` : `https://lactation.aeroflowbreastpumps.com/education-classes`


export const currentQueryParams = new URLSearchParams(
  window.location.search
)

const baseURL = process.env.REACT_APP_PATIENTS_URL

export async function submitPatientLookup( formData ) {
  const submitData = Object.prototype.hasOwnProperty.call(
    formData,
    `email`
  )
    ? formData
    : {
      patient_hash: formData
    }

  const response = await fetch( `${baseURL}/deprecated-login`, {
    method: `POST`,
    body: JSON.stringify( submitData ),
    headers: {
      'Content-Type': `application/json`
    }
  })

  return response.json()
}

export async function fetchAppointmentTimeslots( lc_required, page, tz, authorization, signal, referral = 0 ) {
  const url = qs.stringifyUrl({
    url: `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/appointment-timeslots`,
    query: {
      referral,
      lc_required,
      page,
      tz
    }
  })
  const response = await fetch( url, {
    method: `GET`,
    headers: {
      authorization
    },
    signal
  })

  return response.json()
}

export async function submitAppointment( data, buildAuthorizer ) {
  const url = `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/schedule-appointment`

  const response = await fetch( url, {
    method: `POST`,
    body: JSON.stringify( data ),
    headers: {
      'Content-Type': `application/json`,
      'Authorization': buildAuthorizer
    }
  })

  return response.json()
}

export async function cancelAppointment( appointment_id, buildAuthorizer ) {
  const url = `${process.env.REACT_APP_AWS_URL}/cancel-appointment`

  const response = await fetch( url, {
    method: `POST`,
    body: JSON.stringify({
      appointment_id: appointment_id
    }),
    headers: {
      'Content-Type': `application/json`,
      'Authorization': buildAuthorizer
    }
  })

  return response.json()
}

/**
 * gets the payers by state
 * @param {string} state
 * @returns json response
 */
export async function getStatePayers( state ) {
  const response = await fetch(
    `${process.env.REACT_APP_PAYER_URL}/?state=${state}&url=https://aeroflowbreastpumps.com/qualify-through-insurance`
  )

  return response.json()
}

export async function getHowDidYouHearValues( referral_url, controller ) {

  return await fetch(
    `${process.env.REACT_APP_LEADS_URL}/form-details?referral_url=${referral_url}`, {
      signal: controller.signal
    }
  )
    .then( data => {
      return data.json()
    })
    .then( response => {

      if ( response?.status_code === 200 && response?.data ) {

        return response.data
      } else {

        throw new Error( `hdyhau options error - server response code ${response?.status_code}` )
      }
    })
    .catch( err => {
      if ( err.name !== `AbortError` ) {
        reportToSentry( `there was an error getting how did you hear values`, {
          err
        })
      }
    })
}

export function hasCookies() {
  return Boolean( Object.values( getCookies() ).filter( cV => {
    return cV
  }).length )
}

function getCookies() {
  const cookieReferrals = {
    utm_campaign: Cookies.get( `utm_campaign` ),
    fbclid: Cookies.get( `fbclid` ),
    gclid: Cookies.get( `gclid` ),
    msclkid: Cookies.get( `msclkid` ),
    ttclid: Cookies.get( `ttclid` )
  }

  return cookieReferrals
}

export function setCookies() {
  const utmValue = currentQueryParams.get( `utm_campaign` )
  const fbookValue = currentQueryParams.get( `fbclid` )
  const googleValue = currentQueryParams.get( `gclid` )
  const msValue = currentQueryParams.get( `msclkid` )
  const ttValue = currentQueryParams.get( `ttclid` )

  if ( utmValue !== null ) {
    Cookies.set( `utm_campaign`, utmValue )
  } else if ( fbookValue !== null ) {
    Cookies.set( `fbclid`, fbookValue )
  } else if ( googleValue !== null ) {
    Cookies.set( `gclid`, googleValue )
  }

  // set msValue if present, no hierarchy
  if ( msValue !== null ) {
    Cookies.set( `msclkid`, msValue )
  }

  if ( ttValue !== null ) {
    Cookies.set( `ttclid`, ttValue )
  }
}

export function buildReferralUrl() {
  let updatedReferralURL = referral_url

  const { fbclid: fbclidCookieVal, gclid: gclidCookieVal } =
    getCookies()
  const fbookURLValue = currentQueryParams.get( `fbclid` )
  const googleURLValue = currentQueryParams.get( `gclid` )

  if ( fbookURLValue === null && fbclidCookieVal !== undefined ) {
    updatedReferralURL = `${referral_url}?fbclid=${fbclidCookieVal}`
  } else if (
    googleURLValue === null &&
    gclidCookieVal !== undefined
  ) {
    updatedReferralURL = `${referral_url}?gclid=${gclidCookieVal}`
  }

  return updatedReferralURL
}

/**
 * Gets the patients timezone from internal
 * @param {num} authHeader
 * @param {signal} controller - abort controller
 * @returns json response which has the timezone the patient is in
 */
export async function fetchPatientTimezone( authHeader, controller ) {
  const response = await fetch( `${process.env.REACT_APP_PATIENTS_URL}/time-zone`, {
    headers: {
      'Authorization': authHeader
    },
    signal: controller.signal
  })

  return await response.json()
}

/**
 * submits a new lead
 * @param {object} formData this contains the request to be sent to qualify a patient
 * @returns json response
 */
export async function submitQualifyLeads( formData ) {
  const { utm_campaign, fbclid, gclid, msclkid, ttclid } = getCookies()
  setCookies()

  const overridenRefferalUrl = process.env.REACT_APP_ENVIRONMENT !== `production` ? `https://react-lactation.aeroflow.ninja${window.location.pathname}` : window.location.href

  const finalSubmissionData = {
    ...formData,
    form_code: `breastpumps_lactation_link_qualify`,
    utm_campaign: utm_campaign,
    fbclid: fbclid,
    gclid: gclid,
    msclkid: msclkid,
    ttclid: ttclid,
    referral_url:
    overridenRefferalUrl
  }
  const response = await fetch( `${process.env.REACT_APP_LEADS_URL}/qualify`, {
    method: `POST`,
    body: JSON.stringify(
      finalSubmissionData
    )
  })

  return await response.json()
}