import Tile from '../Tile'
import 'styles/one_on_one.css'
import { schedule_one_on_one } from 'assets/herHub'
import { useNavigate } from 'react-router-dom'
import { useFetchAppointmentsByPageHook } from 'views/OneOnOneScheduling/hooks/useFetchAppointmentsByPageHook'
import RingLoader from 'components/RingLoader'
import useHerHubStore from 'stores/herHub'

export default function Schedule() : JSX.Element | null {
  const navigate = useNavigate()

  const {
    loading,
    error
  } = useFetchAppointmentsByPageHook( `US/Eastern`, 1, true )

  const { recentAppointmentCancel, recentAppointmentReschedule } = useHerHubStore()

  // do not display this tile if there is an error, if patient is ineligible error will be set to Not Found
  if ( error ) return null

  if ( loading || recentAppointmentCancel.length || recentAppointmentReschedule.length ) return <div className="my-4"><RingLoader /></div>

  return (
    <Tile
      className="flex cursor-pointer gap-6" onClick={() => { navigate( `/schedule-appointment${window.location.search}` ) }}
    >
      <img src={schedule_one_on_one} width={75} />
      <h1 className="hub-heading text-gray-dark self-center">{`Schedule Your Appointment`}</h1>
    </Tile>
  )
}