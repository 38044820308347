import { ScheduledClass as SClass } from "types/shopScheduleTypes"

// Sorts classes based on SClass.scheduled_date
export function sortClasses( _classes: SClass[] ) : SClass[] {
  return _classes?.sort( ( a, b ) => {
    const aDate = new Date( a?.scheduled_date )
    const bDate = new Date( b?.scheduled_date )

    return aDate.getTime() - bDate.getTime()
  })
}

// Removes classes that share the same sku
export function removeDuplicates( _classes: SClass[] ) : SClass[] {
  return _classes.filter( ( _class, index, self ) =>
    index === self.findIndex( ( c ) => (
      c.class_sku === _class.class_sku
    ) )
  )
}