import React, { useState, useRef, useEffect } from 'react'
import { useSpring, animated as a} from '@react-spring/web'
import PropTypes from 'prop-types'

// This wrapper allows you to animate height changes to a div
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
function AnimatedHeightDiv({children, display, innerContainerClassName=``, outerContainerClassName=``, padHeight = 0 }) {

  const [ height, setHeight ] = useState( 0 )

  const animatedHeightRef = useRef( null )

  const animatedHeightStyles = useSpring({
    config: {
      duration: 400
    },
    height: display ? `${height + padHeight}px` : `0px`,
    overflowY: `hidden`
  })

  const handleWindowResize = () => {
    if ( animatedHeightRef?.current?.clientHeight && animatedHeightRef?.current?.clientHeight !== height ) return setHeight( animatedHeightRef.current.clientHeight )

    return null
  }

  useEffect( () => {
    window.addEventListener( `resize`, handleWindowResize )

    return () => {
      window.removeEventListener( `resize`, handleWindowResize )
    }
  }, [] )

  useEffect( () => {
    setHeight( animatedHeightRef?.current?.clientHeight ?? 0 )
  })

  return (
    <a.div style={animatedHeightStyles} className={outerContainerClassName}>
      <div ref={animatedHeightRef} className={innerContainerClassName}>
        {
          React.cloneElement( children ?? <></> )
        }
      </div>
    </a.div>
  )
}

AnimatedHeightDiv.propTypes = {
  heightDependencies: PropTypes.array,
  display: PropTypes.bool,
  children: PropTypes.node,
  padHeight: PropTypes.number,
  innerContainerClassName: PropTypes.string,
  outerContainerClassName: PropTypes.string
}

export default AnimatedHeightDiv