// eslint-disable-next-line import/named
import { AddToCalendarActionType, atcb_action } from 'add-to-calendar-button-react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import React, { useState } from 'react'
import { getCurrentTimeZoneString } from 'utils/time'
import dayjs from 'dayjs'

function formatTime( timeString: string ) {
  const [ hours, minutes ] = timeString.split( `:` ).map( Number )

  const formattedHours = hours % 12 || 12 // Convert to 12-hour format
  const period = hours < 12 ? `AM` : `PM`

  return `${formattedHours}:${minutes.toString().padStart( 2, `0` )} ${period}`
}

function CustomAddToCalendarButton({ config, disabled = false } : { config: AddToCalendarActionType, disabled?: boolean }) : JSX.Element {

  const [ hovering, setHovering ] = useState<boolean>( false )

  const monthAbbr = dayjs( config?.startDate ).format( `MMM` )

  return (
    <div
      className={`shadow-lg rounded-lg w-full text-gray-dark border border-pink-3 h-full ${disabled ? `cursor-default` : `cursor-pointer` }`}
      onClick={() => { if ( !disabled ) atcb_action( config ) }}
      onMouseOver={() => setHovering( !disabled )}
      onMouseOut={() => setHovering( false )}
      style={{
        height: `108px`
      }}
    >
      <div className="w-full flex items-center h-full justify-center">
        <div
          className={`bg-pink-3 w-1/4 h-full flex flex-col items-center justify-center rounded-tl-md rounded-bl-md py-6 ${hovering && `opacity-80`}`}
        >
          <p className="text-3xl font-medium">{dayjs( config?.startDate ).format( `DD` )}</p>
          <p className="text-sm font-medium">{monthAbbr}</p>
        </div>
        {hovering ?
          <div className="text-center w-full self-center font-semibold">{`+ Add to Calendar`}</div>
          :
          (
            <div className="flex flex-col items-start justify-start gap-2 text-sm w-full h-full p-2">
              <p className="font-semibold">{config.name}</p>
              <div className="flex items-center text-xs gap-1">
                <CalendarMonthIcon
                  style={{
                    fontSize: `14px`
                  }}
                />
                <p>{formatTime( config.startTime as string )}</p>
                <p>{`-`}</p>
                <p>{formatTime( config.endTime as string )}</p>
                <p>{getCurrentTimeZoneString( `label` )}</p>
              </div>
              { !disabled && <p className="a text-sm">{`Add to Calendar`}</p> }

            </div>
          )}
      </div>
    </div>
  )
}

export default CustomAddToCalendarButton