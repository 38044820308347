export const initialState = {
  "1": null,
  "2": null,
  "3": null,
  "4": null,
  "5": null,
  "6": null
}

export const cachedResultsReducer = ( state, action ) => {

  const { newData, timezone, page, type } = action

  switch ( type ){
  case `add`: {
    const stateCopy = {
      ...state
    }
    stateCopy[ page ] = {
      ...stateCopy[ page ],
      [ timezone ]: newData
    }

    return stateCopy
  }
  case `add_multiple`: {
    const stateCopy = {
      ...state
    }
    stateCopy[ page ] = newData

    return stateCopy
  }
  default: {
    throw new Error( `Unexpected type of action: cachedResultsReducer` )
  }
  }

}