import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div.attrs({
  className: ``
})`
  & > div {
    border: 2px solid #fcdcc1;
    border-top: 2px solid #f9f9f9;
    border-radius: 100%;
    width: 18px;
    height: 18px;
    animation: spin 1.25s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spin-reverse {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
`

function ButtonLoader() {
  return (
    <Wrapper>
      <div></div>
    </Wrapper>
  )
}

export default ButtonLoader
