import PropTypes from 'prop-types'

const Select = ({
  className = ``,
  containerClassName = `flex flex-col`,
  selectValues,
  name,
  onChange,
  label,
  displayLabel = true,
  error,
  required = false,
  disabled = false,
  labelClassName = `font-semibold mb-1.5`,
  description = ``,
  inputProps
}) => {
  return (
    <div className={containerClassName}>
      {
        displayLabel &&
        <label htmlFor={name} className={labelClassName}>
          {label}
          {required && <span className="text-error"> {`*`}</span>}
        </label>
      }
      {description && <p className="text-gray-500 mb-2">{description}</p>}
      <div>
        <select
          className={className ?? ``}
          id={name}
          name={name}
          onChange={e => { return onChange( e.currentTarget.value ) }}
          disabled={disabled}
          {...inputProps}
        >
          {selectValues.map( ( item, i ) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <option value={item.value} key={i} disabled={item.disabled}>
                {item.name}
              </option>
            )
          })}
        </select>
      </div>
      {error && (
        <div className="text-error pl-3.5 pt-2 text-xs">{error}</div>
      )}
    </div>
  )
}

Select.propTypes = {
  className: PropTypes.string,
  selectValues: PropTypes.array.isRequired,
  labelClassName: PropTypes.string,
  description: PropTypes.string,
  containerClassName: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  inputProps: PropTypes.object,
  displayLabel: PropTypes.bool,
  error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool
}

export default Select
