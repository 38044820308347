module.exports = {
  gray: {
    white: `#f9f9f9`,
    light: `#d3d3d3`,
    dark: `#515151`
  },
  pink: {
    white: `#fef8f8`,
    "1": `#feeae7`,
    "2": `#F5D3CE`,
    "3": `#f9b9b7`,
    "4": `#f48b91`
  },
  white: `#ffffff`,
  black: `#333333`,
  error: `#b30000`,
  primary: `#F3757E`,
  secondary: `#00464A`
}
