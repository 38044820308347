import { provider_directory_icon } from 'assets/herHub'
import Tile from '../Tile'

function ProviderDirectory(): JSX.Element {
  return (
    <Tile
      className="cursor-pointer"
      onClick={() => {
        window.open(
          `https://lactation.gorgias.help/en-US/513845-dd1bd2c9fb9d4ad387c08e16d44aed47`,
          `_blank`,
          `noreferrer`
        )
      }}
    >
      <div className="flex gap-6">
        <img src={provider_directory_icon} width={75} />
        <p className="hub-heading self-center">
          {`Provider Directory`}
        </p>
      </div>
    </Tile>
  )
}

export default ProviderDirectory
