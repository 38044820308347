import PropTypes from 'prop-types'

export default function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div role="alert">
      <p>{`Something went wrong:`}</p>
      <p
        style={{
          color: `red`
        }}
      >{error.message}</p>
      <p className="text-center w-80 mx-auto">
        <button className="btn primary-button" onClick={resetErrorBoundary}>{`Please try again`}</button>
      </p>
    </div>
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.object,
  resetErrorBoundary: PropTypes.func
}