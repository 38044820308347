import fetchScheduledAppointments from 'apis/getScheduledAppointments'
import { useEffect, useState } from 'react'
import useAuthStore from 'stores/auth'
import usePatientDetails from './usePatientDetails'
import { AWSResponse } from 'types/aws'
import { ScheduleAppointment } from 'types/shopScheduleTypes/ScheduleAppointment'
import { reportToSentry } from 'utils/reportToSentry'
import useHerHubStore from 'stores/herHub'
import { OneOnOne } from 'types/shopScheduleTypes/OneOnOne'

type ScheduleAppointmentData = {
  upcoming: ScheduleAppointment[];
  completed: ScheduleAppointment[];
  has_signed_questionnaire?: boolean;
}

type ScheduledAppointmentsReturn = {
  data: ScheduleAppointmentData | null,
  upcoming: ScheduleAppointment[],
  completed: ScheduleAppointment[],
  hasSignedQuestionnaire: boolean,
  loading: boolean,
  error: string
}

function useScheduledAppointments() : ScheduledAppointmentsReturn {
  const [ loading, setLoading ] = useState<boolean>( true )
  const [ error, setError ] = useState<string>( `` )
  const [ data, setData ] = useState<ScheduleAppointmentData | null>( null )
  const [ numRetries, setNumRetries ] = useState<number>( 0 )

  const { buildAuthorizer } = useAuthStore()
  const { timezone } = usePatientDetails()
  const { recentAppointmentCancel, removeRecentAppointmentCancel, recentAppointmentReschedule, removeRecentAppointmentReschedule, resetRecentAppointmentCancel, resetRecentAppointmentReschedule } = useHerHubStore()

  useEffect( () => {
    if ( timezone ) {
      let interval:ReturnType<typeof setInterval>
      if ( recentAppointmentCancel.length || recentAppointmentReschedule.length || !data ) {
        // poll /scheduled-appointments every 3 seconds until everything is up to date or number of retries is greater than 3
        interval = setInterval( () => {
          handlePolling( interval )
        }, 3000 )
      }

      return () => {
        clearInterval( interval )
      }
    }
  }, [ recentAppointmentReschedule, recentAppointmentCancel, numRetries, timezone ] )

  const handlePolling = ( interval: ReturnType<typeof setInterval> ) => {
    fetchScheduledAppointments( buildAuthorizer(), timezone ?? `US/Eastern` )
      .then( ( data: AWSResponse<ScheduleAppointmentData> ) => {
        // send data to check for recently rescheduled appointments and caneled appointments
        if ( data.meta.status === `OK` && data?.data?.upcoming ) handleCanceledAppointments( data?.data )
        else {
          setError( `Oops... We encountered an error attempting to get your scheduled appointments.` )
          reportToSentry( new Error( `HerHub: Error fetching scheduled appointments` ), {
            data: JSON.stringify( data )
          })
        }
      })
      .catch( ( error ) => {
        setError( `Oops... We encountered an error attempting to get your scheduled appointments.` )
        reportToSentry( new Error( `HerHub: Error fetching scheduled appointments`, {
          cause: error
        }), {
          data: JSON.stringify( data )
        })
      })
      .finally( () => {
        setLoading( false )
        // if we have tried 3 times and these updates still have not been updated something most likely went wrong so display the management tile
        if ( numRetries > 3 ) {
          clearInterval( interval )
          setNumRetries( 0 )
          resetRecentAppointmentCancel()
          resetRecentAppointmentReschedule()
        } else if ( !recentAppointmentCancel.length && !recentAppointmentReschedule.length ) clearInterval( interval )
        else setNumRetries( numRetries + 1 )
      })
  }

  const handleCanceledAppointments = ( data: ScheduleAppointmentData ) => {
    const updatedAppointments = [ ...data.upcoming ]

    // check if appointments that have been recently cancelled are still in upcoming (have not been updated)
    // if the recently cancelled appointment is not in upcoming it has been updated on the backend and we can remove it from the array
    recentAppointmentCancel.forEach( ( canceledAppointment: ScheduleAppointment ) => {
      const inUpcoming = data.upcoming.find( ( upcomingAppointment: ScheduleAppointment ) => {
        return canceledAppointment.start_time === upcomingAppointment.start_time
      })
      if ( inUpcoming ) updatedAppointments.splice( updatedAppointments.indexOf( inUpcoming ), 1 )
      else removeRecentAppointmentCancel( canceledAppointment )
    })

    const updatedData = Object.assign({}, data, {
      upcoming: updatedAppointments
    })

    handleRescheduleAppointments( updatedData )
  }

  const handleRescheduleAppointments = ( data: ScheduleAppointmentData ) => {
    const updatedAppointments = [ ...data.upcoming ]

    // find appointments that have been rescheduled recently and update with rescheduled time
    recentAppointmentReschedule.forEach( ( rescheduledAppointment: OneOnOne ) => {
      const upcomingIndex = updatedAppointments.findIndex( ( upcomingAppointment: ScheduleAppointment ) => {
        return rescheduledAppointment.start_time.substring( 0, 10 ) === upcomingAppointment.start_time.substring( 0, 10 )
      })
      if ( upcomingIndex > -1 ) removeRecentAppointmentReschedule( rescheduledAppointment ) // middleware/internal is now caught up we can remove from recent reschedule
    })

    // sort by most recent
    updatedAppointments.sort( ( a: ScheduleAppointment, b: ScheduleAppointment ) => {
      return a.start_time.localeCompare( b.start_time )
    })

    const updatedData = Object.assign({}, data, {
      upcoming: updatedAppointments
    })

    setData( updatedData )
  }


  return {
    data,
    upcoming: data?.upcoming ?? [],
    completed: data?.completed ?? [],
    hasSignedQuestionnaire: data?.has_signed_questionnaire ?? true,
    loading,
    error
  }
}

export default useScheduledAppointments