import React, { useEffect } from 'react'
import Tile from '../Tile'
import { OneOnOneNotifications } from 'types/shopScheduleTypes/OneOnOneNotifications'
import useHerHubStore from 'stores/herHub'

function OneOnOneNotification({ notification } : { notification: OneOnOneNotifications }) : JSX.Element {

  const { removeOneOnOneNotification } = useHerHubStore()

  useEffect( () => {
    const removeNotificationTimer = setTimeout( () => {
      removeOneOnOneNotification( notification.appointment_id )
    }, 15000 )

    return () => clearTimeout( removeNotificationTimer )
  }, [] )

  return (
    <Tile key={notification.appointment_id} className="bg-secondary rounded-lg text-white -my-4 relative">
      <p
        className="absolute px-4 top-0 right-0 cursor-pointer"
        onClick={() => { removeOneOnOneNotification ( notification.appointment_id ) }}
      >
        {`x`}
      </p>
      <p className="uppercase font-semibold text-center w-full mt-1">{`You have ${notification.type}${notification.type === `cancel` ? `e` : ``}d your appointment`}</p>
    </Tile>
  )
}

export default OneOnOneNotification