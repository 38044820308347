import { create } from 'zustand'
import { OneOnOne } from 'types/shopScheduleTypes/OneOnOne'

type AppointmentHelpers = {
  consultantName: string | null | undefined;
  setConsultantName: ( _name: string | null | undefined ) => void;
  staffRequired: number;
  setStaffRequired: ( _required: number ) => void;
  selectedAppointment: OneOnOne | null;
  setSelectedAppointment: ( _appointment: OneOnOne | null ) => void;
}

const useAppointmentHelpers = create<AppointmentHelpers>( ( set ) => {
  return {
    consultantName: null,
    setConsultantName: ( _name ) => {
      set({
        consultantName: _name
      })
    },
    staffRequired: 0,
    setStaffRequired: ( _required ) => {
      set({
        staffRequired: _required
      })
    },
    selectedAppointment: null,
    setSelectedAppointment: ( _appointment ) => {
      set({
        selectedAppointment: _appointment
      })
    }
  }
})

export default useAppointmentHelpers