import RingLoader from 'components/RingLoader'

const cn = `w-full max-w-2xl flex flex-col justify-center items-center min-h-400 mx-auto rounded`

const AppointmentsLoadingBox = ({ message, display = true } : { message?: string, display?: boolean }) : JSX.Element | null => {

  if ( !display ) return null

  return (

    <div className={cn} >
      {
        message &&
        <p className="font-light max-w-xs mb-5 text-center md:text-lg">{message}</p>
      }
      <RingLoader />
    </div>
  )
}

export default AppointmentsLoadingBox