import { PostClassSurveyValues } from "./PostClassSurveyForm"


export const submitPostClassSurvey = async ( data: PostClassSurveyValues ) : Promise<boolean> => {
  const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/post-class-survey`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`
    },
    body: JSON.stringify( data )
  })

  const responseData = await response.json()

  return responseData?.meta?.status?.toLowerCase() === `ok`
}