import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { checkMaintenanceFlag } from 'components/MaintenanceListener/utils'
import RingLoader from 'components/RingLoader'
import useMaintenanceNav from 'hooks/useMaintenanceNav'

function Maintenance() : JSX.Element {

  const [ statusMessage, setStatusMessage ] = React.useState( `` )
  const [ loading, setLoading ] = React.useState( false )

  const navigate = useNavigate()
  const { maintenancePreviousURL, clearPreviousPath, isNetworkError, setIsNetworkError } = useMaintenanceNav()


  const handleNavigate = () => {
    // if the error is a network error attempt to check back in on the previous url
    if ( isNetworkError ) {
      setIsNetworkError( false )

      return navigate( maintenancePreviousURL ?? `/education-classes` )
    }

    setLoading( true )
    setStatusMessage( `` )

    // if it wasn't a network issue check the maintenance flag
    checkMaintenanceFlag()
      .then( data => {
        setLoading( false )
        if ( data?.meta?.status === `OK` && typeof data?.data?.status === `boolean` ) {
          if ( !data.data.status ) {
            // site was taken out of maintenance mode
            navigate( maintenancePreviousURL ?? `/education-classes` )

            return clearPreviousPath()
          }

          return setStatusMessage( `We are currently still working on the site... Thank you for your patience.` )

        }

        setStatusMessage( `We encountered an error checking the site status. Please reload the page and try again...` )
      })
      .catch( () => {
        setLoading( false )
        setStatusMessage( `We encountered an error checking the site status. Please reload the page and try again...` )
      })

  }

  return (
    <div className="text-center w-11/12 mx-auto">
      <div className="mt-8">
        <h1 className="mb-6 sm:text-3xl md:text-5xl">{`Our website is currently napping...`}</h1>
        <p className="my-2">
          {`We are sorry for the inconvenience but our server is taking a quick nap! Please try again in a few minutes.`}
        </p>
        <p className="my-2">
          {`If you have any questions or requests in the meantime you can contact us at `}
          <span>
            <a
              href="tel:+18887323979"
              type="tel"
              className="underline text-secondary decoration-secondary"
            >
              {`(888) 732-3979`}
            </a>
          </span>
        </p>
        <>
          {
            maintenancePreviousURL &&
            <div className="max-w-xl mx-auto flex flex-col justify-center">
              <div className="my-2 mx-auto">
                <div className="my-5">
                  {
                    loading ?
                      <RingLoader />
                      :
                      <button
                        onClick={handleNavigate}
                        disabled={loading}
                        className="primary-button"
                      >
                        <p>{`Check Status`}</p>
                      </button>

                  }
                </div>
              </div>
              {
                statusMessage &&
                <p className="text-error">{statusMessage}</p>
              }
            </div>
          }
        </>
      </div>
    </div>
  )
}

export default Maintenance