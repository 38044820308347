import useHerHubStore from "stores/herHub"
import ClassUpdate from "./ClassUpdate"
import { ScheduledClass } from "types/shopScheduleTypes"
import { useMemo } from "react"

export default function RecentCancel() : JSX.Element {
  const { recentCancel, recentReschedule, hiddenClassNotifications, wasHiddenFromNotifications } = useHerHubStore()

  const filteredCanceledClasses = useMemo( () => {
    // If the same class title is in recent reschedule, we need to hide the class from showing in cancel
    return recentCancel.filter( ( rc ) => {
      const isAlsoInRecentReschedule = Boolean( recentReschedule.find( ( rr ) => rr.class_title === rc.class_title ) )

      return !isAlsoInRecentReschedule
    }).filter( ( rc ) => !wasHiddenFromNotifications( rc.class_pk ) ) // This second filter only shows classes that were not dismissed
  }, [ hiddenClassNotifications, recentCancel, recentReschedule ] )

  return (
    <>
      {
        filteredCanceledClasses.map( ( _class : ScheduledClass ) => {
          return (
            <ClassUpdate
              key={_class.class_pk}
              updateText={`You have canceled your class`}
              classItem={_class}
            />
          )
        }) ?? null
      }
    </>
  )
}