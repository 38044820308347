import ResetPasswordForm from "./ResetForm"
import RequestPasswordForm from "./RequestForm"
import useForgotPasswordStore from "./store"

export default function ForgotPasswordForm() : JSX.Element {

  const { getToken } = useForgotPasswordStore()
  const token = getToken()

  if ( token ) return <ResetPasswordForm />

  return <RequestPasswordForm />
}