import { PostClassSurveyForm } from 'components/PostClassSurveyForm'
import React from 'react'

function PostClassSurvey() : JSX.Element {
  return (
    <div className="py-4 px-4 flex flex-col w-full items-center justify-center max-w-xl mx-auto space-y-3">
      <PostClassSurveyForm />
    </div>
  )
}

export default PostClassSurvey