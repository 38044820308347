import React from 'react'
import useApolloClient from 'apollo/apolloClient'
import { ApolloProvider } from '@apollo/client'

function ApolloSetup({children} : { children: JSX.Element }) : JSX.Element {
  const apolloClient = useApolloClient()

  return (
    <ApolloProvider client={apolloClient}>
      {children}
    </ApolloProvider>
  )
}

export default ApolloSetup