import { ScheduledClass } from 'types/shopScheduleTypes'
import { create } from 'zustand'

interface ScheduledState {
  scheduledClasses: ScheduledClass[];
  setScheduledClasses: ( _scheduledClasses: ScheduledClass[] ) => void;
}

const useScheduledStore = create<ScheduledState>()( ( set ) => ({
  scheduledClasses: [],
  setScheduledClasses: ( _scheduledClasses ) => set({
    scheduledClasses: _scheduledClasses
  })
}) )

export default useScheduledStore