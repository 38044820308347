import { submitPatientLookup } from 'apis'
import React, { useState } from 'react'
import { ShopSchedulePatient } from 'types/shopScheduleTypes'
import { getSource } from 'utils/getSource'
import { reportToSentry } from 'utils/reportToSentry'
import { getCurrentTimeZoneString } from 'utils/time'

type PatientDetailsReturn = {
  fetchPatientDetails: ( _emailDobOrHash : string | Record<string, string> ) => Promise<ShopSchedulePatient | null>;
  timezone: string | null;
  setTimezone: React.Dispatch<React.SetStateAction<string>>;
  error: string;
}

function usePatientDetails() : PatientDetailsReturn {
  const [ timezone, setTimezone ] = useState<string>( getCurrentTimeZoneString() )
  const [ error, setError ] = useState<string>( `` )

  const searchParams = new URLSearchParams( window.location.search )
  const hash = searchParams.get( `sgh` ) ?? searchParams.get( `txt` )


  const handlePatientLookup = async ( emailDobOrHash : string | Record<string, string>, controller? : AbortController ) : Promise<ShopSchedulePatient | null> => {
    if ( controller?.signal.aborted ) return null
    const data = await submitPatientLookup( emailDobOrHash ).catch( ( error : Error ) => {
      reportToSentry( new Error( `Shop and Schedule: Error getting patient data with login endpoint` ), {
        hash,
        errorReturned: error
      })
      controller?.abort()
      setError( `Oops... We encountered an error looking up your details.` )
    })

    if ( !data?.data ) {
      reportToSentry( new Error( `Shop and Schedule: NO patient data returned` ), {
        hash,
        data: JSON.stringify( data )
      })
      setError( `Oops... We encountered an error looking up your details.` )

      return null
    }

    const patientData = data.data
    const _patientDetails : ShopSchedulePatient = {
      ...patientData,
      fname: patientData.first_name,
      lname: patientData.last_name,
      email: patientData.email,
      phone: patientData.phone,
      zipcode: patientData.zip_code,
      source: getSource()
    }

    return _patientDetails
  }

  return {
    fetchPatientDetails: handlePatientLookup,
    timezone,
    setTimezone,
    error
  }

}

export default usePatientDetails