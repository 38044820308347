import { MAINTENANCE_PAGE_PATH } from "routes"

export const checkMaintenanceFlag = async () => {
  const response = await fetch( `${process.env.REACT_APP_SITE_MAINTENANCE_URL}/get-site-status?site=hersource`, {
    headers: {
      "Content-Type": `application/json`
    }
  })

  return await response.json()
}

export const getMaintenancePageStatus = () => {
  const { location: { pathname }} = window

  return pathname === MAINTENANCE_PAGE_PATH
}