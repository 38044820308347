import { Navigate, useLocation } from 'react-router-dom'
import useAuthStore from 'stores/auth'
import 'styles/login.css'
import EmailOnlyForm from './EmailOnly'
import NewCustomers from './NewCustomers'
import { OneTimePasswordForm } from 'views/OneTimePassword'

export default function Login() : JSX.Element {

  const { evaluateIsLoggedIn } = useAuthStore()
  const location = useLocation()

  if ( evaluateIsLoggedIn() ) return <Navigate to={location?.state?.from ?? `/my-account`} />

  return (
    <div className="max-w-sm mx-auto flex flex-col items-center gap-7 my-10 px-5">

      <h1 className="heading">{`Customer Login`}</h1>

      <OneTimePasswordForm />

      <div className="flex gap-3 w-full max-w-sm items-center justify-center">
        <hr className="break"></hr>
        <p className="font-semibold text-lg">{`OR`}</p>
        <hr className="break"></hr>
      </div>

      <EmailOnlyForm />

      <hr className="break w-full"></hr>

      <NewCustomers />

    </div>
  )
}