import React from 'react'
import PropTypes from 'prop-types'

import { getUniqueIdPrefix } from './utils'

const CheckboxInput = ({
  name,
  value,
  onChange,
  required = false,
  label,
  className = `mr-2 focus:outline-none rounded-full`,
  labelClassName = ``,
  containerClassName = ``,
  ...rest
}) => {
  const uniqueId = `${getUniqueIdPrefix()}-${name}`

  return (
    <div className={containerClassName}>
      <input
        id={uniqueId}
        name={name}
        type="checkbox"
        checked={value}
        onChange={onChange}
        className={className}
        required={required}
        {...rest}
      />

      {required && <span className="text-error font-normal">{`*`}</span>}

      <label htmlFor={uniqueId} className={`text-sm font-semibold ${labelClassName}`}>
        {label}
      </label>
    </div>
  )
}
CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string
}

export default CheckboxInput
