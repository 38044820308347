import Tile from '../Tile'
import AnimatedHeightDiv from 'components/AnimatedHeightDiv/AnimatedHeightDiv'
import { minus_ico } from 'assets/herHub'
import { ScheduledClass, ClassProduct } from 'types/shopScheduleTypes'
import Class from './ClassDetails/Class'
import useHerHubStore from 'stores/herHub'

type ExpandedProps = {
  scheduledClasses: ScheduledClass[];
  completedClasses: ScheduledClass[];
  classDetails: ClassProduct[];
  error: string;
  onLess: () => void;
  hideActions?: boolean;
}
export default function Expanded({ scheduledClasses, completedClasses, classDetails, onLess, hideActions = false } : ExpandedProps ) : JSX.Element {

  const { wasRecentlyCanceled } = useHerHubStore()

  const filteredScheduledClasses = scheduledClasses.filter( ( _class: ScheduledClass ) => ( Boolean( _class.class_sku ) ) ).filter( ( _class => { // Remove classes that have been recently canceled
    return !wasRecentlyCanceled( _class.class_pk )
  }) )
  const filteredCompletedClasses = completedClasses.filter( ( _class: ScheduledClass ) => ( Boolean( _class.class_sku ) && !filteredScheduledClasses.find( ( classDetails: ScheduledClass ) => { return classDetails.class_sku === _class.class_sku }) ) )

  return (
    <Tile>
      <div className="flex justify-between">
        <h1 className="hub-heading text-gray-dark">{`Class Management`}</h1>
        <div onClick={onLess} className="cursor-pointer">
          <img
            src={minus_ico} width={20} height={5}
            className="mx-auto"
          />
          <p className="hub-alt-text-sm my-0.5">{`Less`}</p>
        </div>
      </div>
      <AnimatedHeightDiv
        display
        heightDependencies={[ scheduledClasses, completedClasses ]}
        outerContainerClassName="w-full"
        innerContainerClassName="w-full flex items-center justify-center mx-auto"
        padHeight={10}
      >
        <>
          <div className="my-2 w-full">
            <p>{`Your Upcoming Classes`}</p>
            {
              filteredScheduledClasses?.length > 0 ? (
                filteredScheduledClasses.map( ( _class : ScheduledClass, i: number ) => {
                  const _classDetail = classDetails.find( ( classDetail: ClassProduct ) => ( classDetail.sku === _class.class_sku ) )

                  return (
                    <Class
                      key={_class.class_sku}
                      classDetails={_classDetail as ClassProduct}
                      scheduledClasses={scheduledClasses}
                      {..._class}
                      // if hide actions is true we need to hide actions on the first class
                      hideActions={i === 0 && hideActions}
                    />
                  )
                })
              ) : (
                <p className="hub-alt-text-sm ml-4">{`No scheduled classes`}</p>
              )
            }
            <p>{`Completed Classes`}</p>
            {
              filteredCompletedClasses?.length ? (
                filteredCompletedClasses.map( ( _class : ScheduledClass ) => {
                  const classDetail = classDetails.find( ( classDetail: ClassProduct ) => ( classDetail.sku === _class.class_sku ) )

                  return (
                    <Class
                      key={_class.class_sku}
                      {..._class}
                      classDetails={classDetail as ClassProduct}
                      completed
                      scheduledClasses={scheduledClasses}
                    />
                  )
                })
              ) : (
                <p className="hub-alt-text-sm ml-4">{`No completed classes`}</p>
              )}
          </div>
        </>
      </AnimatedHeightDiv>
    </Tile>
  )
}