import { useEffect } from "react"
import useAuthStore from "stores/auth"
import RingLoader from "components/RingLoader"
import { useNavigate } from "react-router-dom"
import { LOGIN_PAGE_PATH } from "routes"

export default function Logout() : JSX.Element {

  const { removeCurrentToken, evaluateIsLoggedIn } = useAuthStore()
  const navigate = useNavigate()

  const redirectToLogin = () => {
    navigate( LOGIN_PAGE_PATH )
  }

  const handleLogout = () => {
    if ( !evaluateIsLoggedIn() ) return
    removeCurrentToken()
    redirectToLogin()
  }

  // Logout on mount
  useEffect( handleLogout, [] )

  if ( !evaluateIsLoggedIn() ) return (
    <div className="max-w-lg mx-auto flex flex-col items-center gap-5">
      <h1 className="text-3xl font-semibold text-center">{`You are already logged out`}</h1>
      <button className="btn primary-button" onClick={redirectToLogin}>{`Log In`}</button>
    </div>
  )


  return (
    <div className="max-w-lg mx-auto flex flex-col items-center gap-5">
      <h1 className="text-3xl font-semibold text-center">{`Logging out...`}</h1>
      <RingLoader />
      <button className="btn primary-button" onClick={handleLogout}>{`Manual Test`}</button>
    </div>
  )
}