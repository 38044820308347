import { useState, useEffect, useMemo } from "react"
import { useNavigate, useParams } from "react-router-dom"
import fetchClassDescription from "apis/getClassDescription"
import useAuthStore from "stores/auth"
import { ClassDescription, ClassProduct, ScheduledClass } from "types/shopScheduleTypes"
import CalendarSelect from "components/Reschedule/CalendarSelect"
import TimeBlockSelect from 'components/Reschedule/TimeBlock'
import { HER_HUB_PATH } from "views/HerHub/utils/constants"
import "styles/her_hub.css"
import useRescheduleClass from "hooks/useRescheduleClass"
import ClassProductHeading from "components/Reschedule/ClassProductHeading"
import useTimeSlotValues from "./hooks/useTimeslotValues"
import useRescheduleHandlers from "./hooks/useRescheduleHandlers"
import { AppointmentsLoadingBox } from "components/AppointmentsLoadingBox"
import usePatientDetails from "hooks/usePatientDetails"

type RescheduleProps = {
  classProducts: Array<ClassProduct>;
  scheduledClasses: Array<ScheduledClass>;
  totalSteps?: number;
  classIndex?: number;
  seriesClasses?: ScheduledClass[];
  setSeriesClasses?: React.Dispatch<React.SetStateAction<ScheduledClass[]>>;
  nextPage?: () => void;
  prevPage?: () => void;
}

export default function Reschedule({
  classProducts,
  scheduledClasses,
  totalSteps,
  classIndex,
  seriesClasses,
  setSeriesClasses,
  nextPage,
  prevPage
} : RescheduleProps ) : JSX.Element {

  const [ classDetails, setClassDetails ] = useState<ClassDescription>()
  const [ classDetailsLoading, setClassDetailsLoading ] = useState( true )

  const [ selectedDay, setSelectedDay ] = useState<Date | null>( null )
  const [ selectedTime, setSelectedTime ] = useState<Date | null>( null )

  const params = useParams<{classId?: string}>()
  const { buildAuthorizer } = useAuthStore()
  const { timezone, setTimezone } = usePatientDetails()
  const navigate = useNavigate()

  useEffect( () => {
    const { classId } = params
    if ( !classId ) navigate( HER_HUB_PATH, {
      replace: true
    })
    fetchClassDescription( classId as string, buildAuthorizer() ).then( response => {
      setClassDetails({
        ...response.data
      })
    })
      .finally( () => setClassDetailsLoading( false ) )
  }, [] )

  const { scheduledClass, scheduledClassProduct } = useMemo( () => {
    const { classId } = params
    const scheduledClass = scheduledClasses?.find( ( c : ScheduledClass ) => {
      return c.class_id === classId
    })
    const scheduledClassProduct = classProducts?.find( ( cp : ClassProduct ) => {
      return scheduledClass?.class_sku === cp.sku
    })

    return {
      scheduledClass,
      scheduledClassProduct
    }
  }, [ scheduledClasses, classProducts ] )

  // these values are all in custom hooks simply to reduce the size of this file
  const { timeSlots, timeSlotDates } = useTimeSlotValues( classDetails, scheduledClass, selectedTime, selectedDay, setSelectedDay, setSelectedTime, timezone )
  // these functions are responsible for firing off the fetch requests and handling the response
  const { rescheduleSingle, rescheduleMultiple, errorMessage : submitError, loading: submitLoading } = useRescheduleClass()
  // these functions are responsible for formatting the data and creating the bodies for the functions above
  const { handleSubmitReschedule, handleSeriesReschedule } = useRescheduleHandlers( scheduledClass, classDetails, rescheduleSingle, rescheduleMultiple )

  const handleDaySelect = ( d : Date ) => {
    setSelectedDay( d )
    setSelectedTime( null )
  }

  const handleMonthSelect = ( newMonth : Date ) => {
    // Auto select first day of new month
    const firstAvailableTimeSlot = timeSlotDates.find( ( d : Date ) => d.getMonth() === newMonth.getMonth() )
    setSelectedDay( firstAvailableTimeSlot ?? null )
  }

  const handleReschedule = () => {
    if ( totalSteps ) {
      return handleSeriesReschedule(
        totalSteps,
        seriesClasses,
        ( setSeriesClasses as React.Dispatch<React.SetStateAction<ScheduledClass[]>> ),
        classIndex,
        nextPage,
        selectedTime,
        timezone
      )
    }

    return handleSubmitReschedule( classDetails, selectedTime, timezone )
  }

  const isLastSeries = ( totalSteps && classIndex && classIndex === totalSteps - 1 ) || totalSteps === 1

  if ( submitLoading ) return (
    <div className="my-4">
      <AppointmentsLoadingBox message="Please hold tight while we reschedule your class..." />
    </div>
  )

  return (
    <div className="max-w-xl mx-auto text-center mb-10 px-10">
      <h1 className="text-3xl md:text-4xl font-header mt-5 mb-2 text-center">{`Reschedule this class`}</h1>
      <h2 className="text-lg md:text-2xl font-light">{`Choose a day and time`}</h2>

      <ClassProductHeading classImage={scheduledClassProduct?.small_image} classTitle={scheduledClass?.class_title} classDuration={scheduledClass?.duration_minutes} />

      <CalendarSelect
        onDaySelect={handleDaySelect}
        onMonthChange={handleMonthSelect}
        timeSlotDates={timeSlotDates}
        shouldBeLoading={classDetailsLoading}
        activeDay={selectedDay}
      />

      <TimeBlockSelect
        timeSlots={timeSlots}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        selectedTimeZone={timezone} // Tech Debt Note: this prop-drill isn't ideal, we might want to pull the timeZone selector to this level and add hide logic here
        setSelectedTimeZone={setTimezone}
        header={<h1 className="text-center text-2xl mt-10 mb-5">{selectedDay?.toLocaleDateString( `en-US`, {
          dateStyle: `full`
        }).slice( 0, -6 ) ?? ``}</h1>}
      />

      <div className="mt-10 mx-auto flex justify-center gap-3 flex-col w-48">
        {
          selectedDay !== null && (
            <button
              className="primary-button" disabled={selectedTime === null || submitLoading} onClick={handleReschedule}
            >
              {submitLoading ? `Rescheduling...` : totalSteps && !isLastSeries ? `Next` : `Reschedule`}
            </button>
          )
        }
        {
          totalSteps && classIndex !== 0 &&
          <button
            className="standard-button"
            onClick={prevPage}
            disabled={submitLoading || classDetailsLoading}
          >
            {`Back`}
          </button>
        }
        {
          !classIndex && !classDetailsLoading &&
          <button
            className="standard-button"
            onClick={() => { navigate( `${HER_HUB_PATH}${window.location.search}` ) }}
            disabled={submitLoading}
          >
            {`Back`}
          </button>
        }
      </div>
      <p className="text-error text-center mt-2">{submitError}</p>
    </div>
  )
}