import { AWSResponse } from "types/aws"
import { getMagentoEnvHeader } from "utils/getMagentoEnvHeader"
import { ScheduleAppointment } from "types/shopScheduleTypes/ScheduleAppointment"

export default async function fetchScheduledAppointments( authorizationHeader: string, timezone: string ) : Promise<AWSResponse<{ upcoming: ScheduleAppointment[], completed: ScheduleAppointment[] }>> {
  const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/scheduled-appointments?tz=${timezone}&source=${window.location.hostname}&status=upcoming`, {
    headers: {
      ...getMagentoEnvHeader(),
      'Authorization': authorizationHeader
    }
  })

  return await response.json()
}