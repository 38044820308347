import {
  ClassManagement,
  HelpfulResources,
  OneOnOneManagement,
  OneOnOneSchedule,
  OneOnOneUpdates,
  RecentClassUpdates,
  ReturnToMyAccount,
  ReviewsTile,
  ShopAndSchedule
} from 'components/HubLandingTiles'
import ProviderDirectory from 'components/HubLandingTiles/ProviderDirectory/ProviderDirectory'

import 'styles/her_hub.css'

export default function Landing(): JSX.Element {
  return (
    <div className="max-w-md mx-auto pl-3">
      <h1 className="hub-welcome text-gray-dark">{`Welcome back!`}</h1>
      <div>
        <RecentClassUpdates />
        <OneOnOneUpdates />
        <ClassManagement />
        <ShopAndSchedule />
        <OneOnOneManagement />
        <OneOnOneSchedule />
        <ProviderDirectory />
        <ReviewsTile />
        <ReturnToMyAccount />
        <HelpfulResources />
      </div>
    </div>
  )
}