import { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { TextInput, Validators } from '../Forms/components'

const DOBInput = ({ onChange, error, hideDOBLabels = false, errorClassName = `text-error text-xs pl-3.5 pt-2 `}) => {
  const yearRef = useRef( null )
  const dateRef = useRef( null )
  const [ dob, setDob ] = useState({
    month: ``,
    date: ``,
    year: ``
  })
  const handleChange = ( name, value ) => {
    const newDob = {
      ...dob,
      [name]: value
    }
    setDob( newDob )
    onChange(
      `${newDob.year}-${String( newDob.month ).padStart(
        2,
        `0`
      )}-${String( newDob.date ).padStart( 2, `0` )}`
    )
  }

  return (
    <>
      <div className="flex items-end md:gap-4 gap-1 flex-wrap">
        <TextInput
          label={!hideDOBLabels ? `Month` : ``}
          name="month"
          type="text"
          placeholder="MM"
          value={dob.month}
          pattern="\d*"
          onChange={e => {
            if ( Validators.dobMM( e.target.value ) ) {
              handleChange( `month`, e.target.value )
              if ( e.target.value.length > 1 ) dateRef?.current?.focus()
            }
          }}
          className="input text-center w-16  md:w-24"
        />
        <span className="p-1">{`/`}</span>
        <TextInput
          label={!hideDOBLabels ? `Day` : ``}
          name="date"
          type="text"
          pattern="\d*"
          placeholder="DD"
          value={dob.date}
          onChange={e => {
            if ( Validators.dobDD( e.target.value ) ) {
              handleChange( `date`, e.target.value )
              if ( e.target.value.length > 1 ) yearRef?.current?.focus()
            }
          }}
          className="input text-center w-16 md:w-24"
          reference={dateRef}
        />
        <span className="p-1">{`/`}</span>
        <TextInput
          label={!hideDOBLabels ? `Year` : ``}
          name="year"
          type="text"
          pattern="\d*"
          placeholder="YYYY"
          value={dob.year}
          onChange={e => {
            if ( Validators.dobYYYY( e.target.value ) ) {
              handleChange( `year`, e.target.value.slice( 0, 4 ) )
            }
          }
          }
          className="input text-center w-20 md:w-40"
          reference={yearRef}
        />
      </div>
      {error && (
        <div className={`${errorClassName}`}>{error}</div>
      )}
    </>
  )
}

DOBInput.propTypes = {
  onChange: PropTypes.func,
  error: PropTypes.string,
  errorClassName: PropTypes.string
}

export default DOBInput
