import { AppointmentButton } from "components/DatePicker/styled"

type TimeBlockProps = {
  time: Date;
  classDuration: number;
  selected?: boolean;
  timeZone?: string;
}

export default function TimeBlock({time, classDuration, selected = false, timeZone} : TimeBlockProps ) : JSX.Element {

  const formattedTime = time.toLocaleTimeString( `en-US`, {
    timeZone: timeZone ?? `EST`,
    hour: `2-digit`,
    minute:`2-digit`
  })

  return (
    <AppointmentButton active={selected}>
      {/* Displays Time in the format of 1:30 PM */}
      <p className="hub-time-block-p">{formattedTime}</p>
      <p className={`duration-text lowercase ${selected && `text-white`}`}>{`${ classDuration } mins`}</p>
    </AppointmentButton>
  )
}