export { default as RecentRescheduleUpdateTitle } from './RecentReschedule'
export { default as RecentCancelUpdateTitle } from './RecentCancel'
export { default as ClassUpdate } from './ClassUpdate'

import { RecentRescheduleUpdateTitle, RecentCancelUpdateTitle} from './'

export default function RecentClassUpdates() : JSX.Element {
  return (
    <div className="flex flex-col">
      <RecentRescheduleUpdateTitle />
      <RecentCancelUpdateTitle />
    </div>
  )
}