import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import useAuthStore from 'stores/auth'
import { usePatientDataStore } from 'stores/patientData'

function TokenCheck() : null {

  const location = useLocation()
  const { getCurrentToken, setCurrentToken } = useAuthStore()
  const { setCustomerPricingData } = usePatientDataStore()

  useEffect( () => {
    async function checkToken() {
      if ( location.search ) {
        const searchParams = new URLSearchParams( location.search )
        const token = searchParams.get( `mct` )
        if ( token ) {
          if ( token !== getCurrentToken() ) {
            await setCustomerPricingData( `Bearer ${token}` )
          }
          setCurrentToken( token )
        }
      }
    }

    checkToken()
  }, [ location ] )

  return null
}

export default TokenCheck