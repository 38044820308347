import { Modal } from '../../components/modal'
import { Xicon } from '../../assets'
import React from 'react'
import RingLoader from '../../components/RingLoader'
import useHerHubStore from 'stores/herHub'
import { ScheduleAppointment } from 'types/shopScheduleTypes/ScheduleAppointment'
import { cancelAppointment } from 'apis'
import useAuthStore from 'stores/auth'

type CancelAppointmentProps = {
  handleClose: () => void;
  isOpen: boolean;
  appointment: ScheduleAppointment;
}

export default function CancelAppointment({handleClose, isOpen, appointment }: CancelAppointmentProps ) :JSX.Element {

  const [ loading, setLoading ] = React.useState( false )
  const [ error, setError ] = React.useState( `` )

  const { addRecentAppointmentCancel, addOneOnOneNotification } = useHerHubStore()
  const { buildAuthorizer } = useAuthStore()

  const handleCancelAppointment = async () => {
    setLoading( true )
    setError( `` )
    const cancelResponse = await cancelAppointment( appointment.id, buildAuthorizer() )

    if ( cancelResponse.status === 201 ) {
      addRecentAppointmentCancel( appointment )
      addOneOnOneNotification({
        type: `cancel`,
        lc_name: appointment.staff_display_name,
        time: `${appointment.scheduled_date_pretty}, ${appointment.begin_time_pretty}`,
        appointment_id: appointment.id
      })

      return handleClose()
    }

    setError( `Oops... We encountered an error attempting to cancel your appointment.` )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      defaultCloseButton={false}
      hasClickListener={false}
    >
      <div className="p-5">
        { loading ?
          <div className="flex flex-col justify-center items-center gap-4">
            <RingLoader />
            <p className="text-center">{`Canceling Appointment...`}</p>
          </div>
          :
          <div className="flex flex-col items-center justify-center gap-4 p-12">
            <div
              className="absolute top-6 right-6 cursor-pointer hover:opacity-80 p-2"
              onClick={handleClose}
            >
              <img
                src={Xicon}
                alt="close modal icon"
                width={15}
                height={15}
              />
            </div>
            <p className="text-center font-light text-lg">{`Are you sure you want to cancel this appointment?`}</p>
            {/* <p className="text-center font-semibold text-sm mb-2">{`A cancellation fee of $25.00 will be charged to the card on file.`}</p> */}
            <button className="primary-button" onClick={handleCancelAppointment}>{`Yes`}</button>
            <button className="standard-button" onClick={handleClose}>{`No`}</button>
            {error && <p className="text-error my-3">{error}</p>}
          </div>
        }
      </div>

    </Modal>

  )
}