export const sendOneTimePassword = async ( phoneNumber: string ) => {
  const response = await fetch( `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/rest/V1/mobilelogin/otp/send`, {
    method: `POST`,
    headers: {
      "Content-Type": `application/json`
    },
    body: JSON.stringify({
      mobilenumber: phoneNumber.replace( /[\\(\\)]/g, `` ).replace( / /g, `-` ),
      otptype: `login`,
      resendotp: false,
      oldmobile: false
    })
  })

  return response.json()
}

export const loginOneTimePassword = async ( phoneNumber: string, otpInput: string ) => {
  const response = await fetch( `${process.env.REACT_APP_MAGENTO_2_BASE_URL}/rest/V1/mobilelogin/otp/verify`, {
    method: `POST`,
    headers: {
      "Content-Type": `application/json`
    },
    body: JSON.stringify({
      mobilenumber: phoneNumber.replace( /[\\(\\)]/g, `` ).replace( / /g, `-` ),
      otptype: `login`,
      otpcode: otpInput,
      oldmobile: 0
    })
  })

  return response.json()
}

export const defaultOTPSendError = `The phone number provided is not registered to an account or an exception occurred attempting to send your one time password. Please review the phone number given and try again.`
export const defaultOTPLoginError = `The one time password entered was incorrect. Please review the one time password given above and try again.`
export const defaultOTPSuccess = `A one-time password has been sent to your phone. Please check your phone and enter the password below to login.`