import { useState } from 'react'
import TermsChecker from 'components/TermsChecker'
import DatePicker from 'components/DatePicker'
import { AppointmentsLoadingBox } from 'components/AppointmentsLoadingBox'
import { SchedulingErrorBlock } from 'components/SchedulingErrorBlock'
import ButtonLoader from 'components/ButtonLoader'
import { useFetchAppointmentsByPageHook } from './hooks/useFetchAppointmentsByPageHook'
import useAppointmentHelpers from 'stores/appointmentHelpers'
import { cancelAppointment, submitAppointment } from 'apis'
import { reportToSentry } from 'utils/reportToSentry'
import usePatientDetails from 'hooks/usePatientDetails'
import { OneOnOne } from 'types/shopScheduleTypes/OneOnOne'
import useEligibleAppointments from 'hooks/useEligibleAppointments'
import { IneligibleBlock } from 'components/IneligibleBlock'
import useAuthStore from '../../stores/auth'
import { useNavigate } from 'react-router-dom'
import { HER_HUB_PATH } from 'views/HerHub/utils/constants'
import useHerHubStore from 'stores/herHub'
import { NoAppointmentsBlock } from 'components/NoAppointmentsBlock'

export const OneOnOneScheduling = ({ isReschedule, nextPage }: {
  isReschedule: boolean,
  nextPage?: () => void
}): JSX.Element => {

  // ##################### S T A T E  H O O K S ##################### =>
  const [ pageIndex, setPageIndex ] = useState<number>( 0 )
  const [ isTermChecked, setIsTermChecked ] = useState<boolean>( false )
  const [ submitLoading, setSubmitLoading ] = useState<boolean>( false )
  const [ submitError, setSubmitError ] = useState<string>( `` )


  //          ##################### C O N T E X T  H O O K S #####################          =>
  const {
    staffRequired,
    setStaffRequired,
    setConsultantName,
    consultantName,
    setSelectedAppointment,
    selectedAppointment
  } = useAppointmentHelpers()
  const { buildAuthorizer } = useAuthStore()
  const { addRecentAppointmentReschedule, addOneOnOneNotification } = useHerHubStore()
  const { timezone, setTimezone } = usePatientDetails()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams( window.location.search )
  const appointmentId = searchParams.get( `appointment_id` )

  const consultantFirstName = consultantName?.split( ` ` )[0]

  //          ##################### C U S T O M  H O O K S #####################            =>

  const {
    data,
    loading,
    error,
    serviceId
  } = useFetchAppointmentsByPageHook(
    timezone, pageIndex.toString(),
    !isReschedule && staffRequired,
    setPageIndex,
    setSelectedAppointment
  )

  const { eligibleAppointments, error: scheduledAppointmentsError } = useEligibleAppointments( timezone, data )

  const { recentAppointmentCancel, recentAppointmentReschedule } = useHerHubStore()

  //          ##################### E V E N T S #####################                       =>

  const handleTimeClick = ( selected: { start_time: string }) => {
    if ( !selectedAppointment || selected.start_time !== selectedAppointment.start_time ) return setSelectedAppointment( selected as OneOnOne )
    setSelectedAppointment( null )
  }

  const handleSchedule = () => {
    submitAppointment({
      service_id: serviceId,
      staff_id: selectedAppointment?.staff_id,
      start_time: selectedAppointment?.start_time,
      tz: timezone,
      aob_accepted: isTermChecked
    }, buildAuthorizer() )
      .then( response => {
        setSubmitLoading( false )
        const recentUpdatedAppointment: OneOnOne = Object.assign({}, selectedAppointment, {
          appointment_id: appointmentId
        })
        addRecentAppointmentReschedule( recentUpdatedAppointment )
        addOneOnOneNotification({
          lc_name: selectedAppointment?.lc_name ?? ``,
          time: `${selectedAppointment?.scheduled_date_pretty}, ${selectedAppointment?.begin_time_pretty}`,
          type: appointmentId ? `reschedule` : `schedule`,
          appointment_id: appointmentId ?? ``

        })
        if ( isReschedule ) {
          const searchParamsUpdated = new URLSearchParams( window.location.search )
          searchParamsUpdated.delete( `appointment_id` )

          return navigate( HER_HUB_PATH.concat( `?${searchParamsUpdated.toString()}` ) )
        }
        if ( nextPage && response?.data?.success ) return nextPage()
        throw new Error( `We had trouble scheduling your class. Please refresh your browser window and try again.` )
      })
      .catch( err => {
        setSubmitLoading( false )
        setSubmitError( err.message )
        reportToSentry( `There was an error scheduling an appointment on individual appointment form`, {
          staff_id: selectedAppointment?.staff_id,
          start_time: selectedAppointment?.start_time,
          tz: timezone,
          error: `${err}`
        })
      })
  }

  const handleCancel = async ( appointmentId: string | null ) => {
    const cancelResponse = await cancelAppointment( appointmentId, buildAuthorizer() )

    if ( cancelResponse?.response?.status !== `OK` ) {
      setSubmitLoading( false )
      reportToSentry( new Error( `One on One: Cancel Error` ), {
        appointmentId
      })

      return setSubmitError( `Oops... we encountered an issue attempting to cancel your previous class. Please try again or contact customer service` )
    }

    return handleSchedule()
  }

  const handleSubmit = async () => {
    setSubmitLoading( true )
    setSubmitError( `` )
    if ( isReschedule ) {
      await handleCancel( appointmentId ).catch( ( error ) => {
        setSubmitLoading( false )
        reportToSentry( new Error( `One on One: Cancel Error`, {
          cause: error
        }), {
          appointmentId
        })

        return setSubmitError( `Oops... we encountered an issue attempting to cancel your previous class. Please try again or contact customer service` )
      })
    } else handleSchedule()
  }

  const handleMonthChange = ( m: Date ) => {
    const today = new Date()
    let months = ( m.getFullYear() - today.getFullYear() ) * 12
    months -= today.getMonth()
    months += m.getMonth()

    months <= 0 ? 1 : months + 1

    setSelectedAppointment( null )
    setPageIndex( months + 1 )
  }

  const handleShowAllConsultants = ( e: React.MouseEvent<HTMLButtonElement> ) => {
    e.preventDefault()
    setConsultantName( `` )
    setStaffRequired( 0 )
  }

  if ( error === `Patient Ineligible` ) return <IneligibleBlock />

  if ( error === `No Appointments` ) return <NoAppointmentsBlock />

  if ( error || scheduledAppointmentsError ) return <SchedulingErrorBlock />

  return (
    <div className="flex flex-col items-center justify-center w-full pb-32">
      <p
        className="text-3xl md:text-4xl font-header mt-5 mb-2 text-center"
      >{`${isReschedule ? `Reschedule` : `Schedule`} Your Appointment`}</p>
      <p className="text-lg md:text-2xl font-light mb-10">{`Choose a day and a time`}</p>
      {
        loading || !eligibleAppointments || recentAppointmentCancel.length || recentAppointmentReschedule.length ?
          <AppointmentsLoadingBox message="Please hold tight while we get your available appointments..." />
          :
          <div className="max-w-2xl mx-auto">
            <DatePicker
              timeZone={timezone}
              setTimeZone={setTimezone}
              appointments={eligibleAppointments || {}}
              startTime={selectedAppointment?.start_time}
              handleTimeClick={handleTimeClick}
              onMonthChange={handleMonthChange}
              error={error}
              dynamicMonthBoundary={false}
            />
            {staffRequired === 1 &&
              <div className="text-center w-full mt-12 mb-8">
                <p
                  className="mb-8 text-secondary"
                >{`Don't see an appointment with ${consultantFirstName} that fits your schedule?`}</p>
                <button onClick={handleShowAllConsultants}>{`Show availability for all providers`} </button>
              </div>
            }
            <TermsChecker
              name="authorize_aeroflow"
              type="checkbox"
              value={isTermChecked}
              containerClassname={`pb-4 pt-2 px-2 bg-pink-3 bg-opacity-50 mb-7`}
              agreeToCancel
              showMayRecordStatement
              onClick={e => {
                setIsTermChecked( e.target.checked )
              }}
            />

            <div className="text-center w-full mt-12 mb-8">
              <button
                disabled={submitLoading || !selectedAppointment || !isTermChecked}
                onClick={handleSubmit}
                className="primary-button"
              >
                {submitLoading ?
                  <ButtonLoader />
                  :
                  `Confirm`
                }
              </button>
            </div>

            <div>
              {submitError && <p className="text-error text-center">{submitError}</p>}
            </div>

          </div>

      }
    </div>
  )
}

export default OneOnOneScheduling