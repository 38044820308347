import fetchScheduledAppointments from 'apis/getScheduledAppointments'
import fetchScheduledClasses from 'apis/getScheduledClasses'
import { useEffect, useState } from 'react'
import useAuthStore from 'stores/auth'
import useHerHubStore from 'stores/herHub'
import { reportToSentry } from 'utils/reportToSentry'

function useEligibleAppointments( timezone, data ) {

  const [ scheduledAppointments, setScheduledAppointments ] = useState( null )
  const [ scheduledClasses, setScheduledClasses ] = useState( null )
  const [ eligibleAppointments, setEligibleAppointments ] = useState( null )
  const [ excludeDays, setExcludeDays ] = useState( null )
  const [ error, setError ] = useState( `` )

  const { buildAuthorizer } = useAuthStore()
  const { recentReschedule, recentAppointmentReschedule } = useHerHubStore()

  useEffect( () => {
    if ( timezone && !scheduledAppointments && !scheduledClasses ) {
      const auth = buildAuthorizer()
      fetchScheduledAppointments( auth, timezone )
        .then( ( data ) => {
          if ( data?.meta?.status === `OK` && data?.data?.upcoming ) setScheduledAppointments( data.data.upcoming )
          else {
            setError( `Oops... we encountered an error getting your scheduled appointments.` )
            setEligibleAppointments( data )
            setExcludeDays( new Set() )
          }
        })
        .catch ( ( error ) => {
          setEligibleAppointments( data )
          setExcludeDays( new Set() )
          setError( `Oops... we encountered an error getting your scheduled appointments.` )
          reportToSentry( new Error( `Error fetching scheduled appointments`, {
            cause: error
          }) )
        })

      fetchScheduledClasses( auth, timezone )
        .then( ( data ) => {
          if ( data?.meta?.status === `OK` && data?.data?.upcoming ) setScheduledClasses( data.data.upcoming )
          else {
            setError( `Oops... we encountered an error getting your scheduled classes.` )
            setEligibleAppointments( data )
            setExcludeDays( new Set() )
          }
        })
        .catch ( ( error ) => {
          setEligibleAppointments( data )
          setExcludeDays( new Set() )
          setError( `Oops... we encountered an error getting your scheduled classes.` )
          reportToSentry( new Error( `Error fetching scheduled classes`, {
            cause: error
          }) )
        })
    }
  }, [ timezone ] )

  useEffect( () => {

    if ( scheduledAppointments && scheduledClasses ) {

      const updateExcludeDays = new Set()

      scheduledAppointments.forEach( ( appointment ) => {
        const recentlyRescheduledAppointment = recentAppointmentReschedule.find( ( rescheduledAppointment ) => {
          return rescheduledAppointment.appointment_id === appointment.appointment_id
        })
        const startTime = recentlyRescheduledAppointment ? recentlyRescheduledAppointment.start_time_local : appointment.start_time_local
        updateExcludeDays.add( startTime.substring( 0, 10 ) )
      })

      // add newly schedule appointments to exclusion list
      recentAppointmentReschedule.filter( appointment => {
        return !appointment.appointment_id
      }).forEach( appointment => {
        updateExcludeDays.add( appointment.start_time_local.substring( 0, 10 ) )
      })

      scheduledClasses.forEach( ( classItem ) => {
        // check if class was recently rescheduled and old scheduling time is still available
        const recentlyRescheduled = recentReschedule.find( ( rescheduledAppointment ) => {
          return rescheduledAppointment.class_sku === classItem.class_sku
        })
        if ( !recentlyRescheduled ) updateExcludeDays.add( classItem.scheduled_date.substring( 0, 10 ) )
      })

      recentReschedule.forEach( ( rescheduledClass ) => {
        updateExcludeDays.add( rescheduledClass.scheduled_date.substring( 0, 10 ) )
      })

      setExcludeDays( updateExcludeDays )

      if ( data ) {
        const updatedEligible = Object.assign({}, data )

        // go through and remove date keys from appointments that have an upcoming appointment on the same day
        Object.keys( data ).forEach( ( dateKey ) => {
          if ( updateExcludeDays.has( dateKey ) ) delete updatedEligible[dateKey]
        })

        setEligibleAppointments( Object.assign({}, updatedEligible ) )
      }
    }
  }, [ data, scheduledAppointments, scheduledClasses, recentReschedule, recentAppointmentReschedule ] )

  return {
    eligibleAppointments,
    excludeDays,
    scheduledAppointments,
    scheduledClasses,
    error
  }
}

export default useEligibleAppointments