import React from 'react'

export interface RatingButtonButtonGroup {
  key: string
  selected: boolean
  text: string;
}

interface ButtonGroupProps {
  buttons: RatingButtonButtonGroup[];
  toggleButton: ( _button: RatingButtonButtonGroup ) => void;
  hideSelectText?: boolean;
}

function RatingButtonGroup({ buttons, toggleButton, hideSelectText = false } : ButtonGroupProps ) : JSX.Element {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center gap-2">
      {
        !hideSelectText &&
        <p className="text-xs md:text-sm">{`Select One:`}</p>
      }
      <div>
        <div className="flex items-center justify-between px-2 mb-0.5 font-light text-sm">
          <p>{`Not at all likely`}</p>
          <p>{`Extremely likely`}</p>
        </div>
        <div className="mx-auto flex items-center justify-center flex-wrap">
          {
            buttons.map( ( button ) => {
              return (
                <button
                  key={button.key}
                  className={`button-group-button-rating ${ button.selected && `button-group-button-selected-rating`}`}
                  onClick={() => { toggleButton( button ) }}
                >
                  {button.text}
                </button>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default RatingButtonGroup