import { Modal } from 'components/modal'
import { ClassImage, Description, ScheduledClass } from 'types/shopScheduleTypes'
import ReactHtmlParser from 'react-html-parser'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import useSeriesClasses from '../hooks/useSeriesClasses'
import CancelModal from './CancelModal'

type ClassDetailsModalProps = {
  scheduledClass: ScheduledClass;
  isOpen: boolean;
  handleClose: () => void;
  image?: ClassImage;
  description?: Description;
  onCancelClass: ( _seriesClasses: ScheduledClass[] ) => void
  completed: boolean;
  scheduledClasses: ScheduledClass[];
}
export default function ClassDetailsModal({ scheduledClass, isOpen, handleClose, image, description, onCancelClass, completed, scheduledClasses }: ClassDetailsModalProps ) : JSX.Element {

  const [ showConfirmationModal, setShowConfirmationModal ] = useState( false )
  const [ cancelLoading, setCancelLoading ] = useState( false )

  const seriesClasses = useSeriesClasses( scheduledClass, scheduledClasses )

  function handleCancelClass() {
    setCancelLoading( true )
    onCancelClass( seriesClasses )
  }

  if ( !image?.url || !description?.html ) return <></>

  return (
    <Modal
      isOpen={isOpen || cancelLoading}
      defaultCloseButton={false}
      containerStyling=""
      onClose={handleClose}
      hasClickListener={false}
    >
      <>
        <CancelModal
          setDisplay={setShowConfirmationModal}
          onCancel={handleCancelClass}
          seriesClasses={seriesClasses}
          display={showConfirmationModal}
        />

        <div className="bg-white p-10">
          <div className="absolute right-0 top-0 text-secondary text-lg cursor-pointer px-4 py-2" onClick={handleClose}>
            {`x`}
          </div>
          <img src={image.url} alt={image.label} />
          <h1 className="hub-heading text-gray-dark my-4">{scheduledClass.class_title}</h1>
          {ReactHtmlParser( description.html )}
          {!completed && scheduledClass.class_id?.length && ( <>
            <p className="a my-4"><Link to={`/reschedule/${scheduledClass.class_id}${window.location.search}`}>{`Reschedule Class`}</Link></p>
            <p className="a my-4" onClick={() => setShowConfirmationModal( true )}>{`Cancel Class`}</p>
          </> )}
        </div>
      </>
    </Modal>
  )
}