import { FormatterController } from "../validators"

export const Validators = {
  name: value => {
    return Boolean( value.match( /^[a-zA-Z-' ]+$/ ) )
  },
  street: value => {
    return Boolean( value.match( /^[0-9a-zA-Z- .]+$/ ) )
  },
  city: value => {
    return Boolean( value.match( /^[a-zA-Z- .]+$/ ) )
  },
  state: value => {
    const stateRE = /(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])/

    return Boolean( value.match( stateRE ) )
  },
  zipCode: value => {
    return Boolean( value.match( /(^\d{5}$)$/ ) )
  },
  phone: value => {
    return Boolean( value.match( /^\(([2-9]\d{2})\) \d{3}-\d{4}$/ ) )
  },
  password: value => {
    return Boolean( value.match( /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[$%&])|(?=.*[A-Z])(?=.*\d)(?=.*[$%&])|(?=.*[A-Z])(?=.*[a-z])(?=.*[$%&])).{8,255}$/ ) )
  },
  passwordConfirmation: ( password, passwordConfirmation ) => {
    return password === passwordConfirmation
  },
  UUID: value => {
    return Boolean( value.match( /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i ) )
  },
  memberID: value => {
    return Boolean( value.match( /^[0-9a-zA-Z-]+$/ ) )
  },
  groupNumber: value => {
    return Boolean( value.match( /^\d+$/ ) )
  },
  ccv: value => {
    return Boolean( value.match( /^[0-9]{3,4}$/ ) )
  },
  expiryMonth: value => {
    return Boolean( value.match( /^(0?[1-9]|1[012])$/ ) )
  },
  expiryYear: value => {
    const validNumbers = Boolean( value.match( /^[2-9][0-9]$/ ) )
    const notPrevYears = ( new Date().getFullYear() % 100 ) <= Number( value )

    return validNumbers && notPrevYears // Years of [>=currentYear]-99 are valid. This will not work with a year past 2099
  },
  creditCard: value => {
    return value.length > 0
  },
  email: value => {
    return Boolean( value.match( /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i ) )
  },
  dobMM: value => {
    return value >= 0 && value <= 12 && value.length < 3 && sneakyNumberDigits.every( digit => !value.includes( digit ) )
  },
  dobDD: value => {
    return value >= 0 && value <= 31 && value.length < 3 && sneakyNumberDigits.every( digit => !value.includes( digit ) )
  },
  dobYYYY: value => {
    const isDigitOrEmptyRegex = /^[0-9]*$/
    const isValidDate = isDigitOrEmptyRegex.test( value ) && value.length <= 4

    return isValidDate && sneakyNumberDigits.every( digit => !value.includes( digit ) )
  },
  dobYear: ( val ) => {
    const dateStr = `${val}-01-01T00:00:00`

    // can't be older than 120!
    const today = new Date()
    const dob = new Date( dateStr )
    let valid = false

    if ( dob == `Invalid Date` ) {
      return false
    }

    const ms = ( today - dob )
    const { day } = convertMS( ms )
    const years = ( day / 365 )


    if ( years === 0 ){
      valid = true
    }

    if ( years > 0 && years < 120 ){
      valid = true
    }

    if ( years < 0 ){
      valid = false
    }

    return valid
  }
}

export const Formatters = {
  phone: value => {
    let _val = value.replace( /[- )(]/g, `` )

    if ( _val.length === 1 && _val === `1` ){
      return ``
    }

    const a = _val.length > 3 ? `(${_val.substring( 0, 3 )})` : _val
    const b = _val.substring( 3, 6 ) ? ` ${_val.substring( 3, 6 )}` : ``
    const c = _val.substring( 6 ) ? `-${_val.substring( 6 )}` : ``

    // pattern `(888) 555-1212`
    return `${a}${b}${c}`.slice( 0, 14 )
  },
  zipCode: value => {
    return value.replace( /[^\d+]/g, `` ).slice( 0, 5 )
  },
  creditCard: value => {
    return FormatterController.ccFormatter( value )
  },
  dateYY: value => {
    return value.slice( -2 )
  },
  state: value => {
    return value.slice( -2 )
  },
  dobMonthDay: value => {
    if ( value > 0 && value < 10 && value.length < 2 ) {
      return FormatterController.prefaceDigitWithZero( value )
    }

    return value
  }
}

/**
 * Get Expiry Years - returns an array of objects of the next twenty years in format YY, and YYYY
 * @returns expYears - List of objects mapped with strings of the next twenty years, in YYYY, and YY format
 */
export const getExpiryYearList = () => {
  const startingYear = ( new Date() ).getFullYear()

  return Array.from( Array( 21 ) ).map( ( _, index ) => {
    return {
      YYYY: ( startingYear + index ).toString(),
      YY: ( startingYear + index ).toString().slice( -2 )
    }
  })
}

export const convertMS = ( milliseconds ) => {

  let day
  let hour
  let minute
  let seconds

  seconds = Math.floor( milliseconds / 1000 )
  minute = Math.floor( seconds / 60 )
  seconds = seconds % 60
  hour = Math.floor( minute / 60 )
  minute = minute % 60
  day = Math.floor( hour / 24 )
  hour = hour % 24

  return {
    day: day,
    hour: hour,
    minute: minute,
    seconds: seconds
  }
}
let uniqueIdPrefix = 0

export const getUniqueIdPrefix = () => {
  return uniqueIdPrefix++
}

/**
 * takes results and makes sure result.basic.last_name contains the search string
 * @param {Array} results
 * @param {String} lastNameStr
 * @returns {Array}
 */
export const filterOutOtherNames = ( results, lastNameStr ) => {

  const returnResults = []

  for ( let i = 0; i < results.length; i++ ){
    if ( results[i].basic.last_name.indexOf( lastNameStr ) !== -1 ){
      returnResults.push( results[i] )
    }
  }

  return returnResults
}

export const sneakyNumberDigits = [ `e`, `E`, `-`, `+` ]
