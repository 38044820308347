import useHerHubStore from "stores/herHub"
import ClassUpdate from "./ClassUpdate"
import { ScheduledClass } from "types/shopScheduleTypes"

export default function RecentReschedule() : JSX.Element {
  const { recentReschedule, wasHiddenFromNotifications } = useHerHubStore()

  return (
    <>
      {
        recentReschedule?.filter( ( rc ) => !wasHiddenFromNotifications( rc.class_pk ) ).map( ( _class : ScheduledClass ) => {
          return (
            <ClassUpdate
              key={_class.class_pk}
              updateText={`You have rescheduled your class`}
              classItem={_class}
            />
          )
        }) ?? null
      }
    </>
  )
}