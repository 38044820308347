import RingLoader from "components/RingLoader"
import { useState, useMemo } from 'react'
import { StyledDayPicker } from 'components/DatePicker/styled'
import MonthSelector from 'components/DatePicker/MonthSelector'
import { MONTHS } from "utils/constants"
import 'react-day-picker/lib/style.css'

type CalenderSelectProps = {
  timeSlotDates : Date[];
  onDaySelect: ( _d : Date ) => void
  onMonthChange?: ( _m : Date ) => void
  shouldBeLoading?: boolean;
  activeDay: Date | null
}

export default function CalendarSelect({timeSlotDates, onDaySelect, onMonthChange, shouldBeLoading = false, activeDay} : CalenderSelectProps ) : JSX.Element {

  const [ selectedMonth, setSelectedMonth ] = useState<Date>( new Date() )

  const [ fromMonth, toMonth ] = useMemo( () => {
    if ( !timeSlotDates?.length ) return [ new Date(), new Date() ]
    setSelectedMonth( timeSlotDates[0] )

    return [ timeSlotDates[0], timeSlotDates[timeSlotDates.length -1] ]
  }, [ timeSlotDates ] )

  function handleMonthChange( e : Date | string ) {
    let goToDate = e as Date
    if ( typeof e === `string` ) {
      const selectedMonthIndex = MONTHS.findIndex( month => { return month === e })
      // if the selected month index is less than the from month index need to go to next calendar year
      goToDate = new Date( fromMonth.getMonth() > selectedMonthIndex ? toMonth.getFullYear() : fromMonth.getFullYear(), selectedMonthIndex )
    }
    onMonthChange && onMonthChange( goToDate )
    setSelectedMonth( goToDate )
  }

  if ( shouldBeLoading ) return <div className="my-4"><RingLoader /></div>

  return (
    <div className="flex justify-center min-h-400 relative">
      <MonthSelector
        fromMonth={fromMonth}
        toMonth={toMonth}
        selectedMonth={selectedMonth}
        handleMonthSelection={handleMonthChange}
      />
      <StyledDayPicker
        onDayClick={onDaySelect}
        month={selectedMonth}
        selectedDays={timeSlotDates}
        // weekdaysShort={WEEKDAYS_SHORT}
        onMonthChange={handleMonthChange}
        fromMonth={fromMonth}
        toMonth={toMonth}
        renderDay={( day : Date ) => {
          return (
            <div className="date-number-wrapper">
              <div className="date-number">{day.getDate()}</div>
            </div>
          )
        }}
        modifiers={{
          birthday: activeDay
        }}
      />
    </div>
  )
}