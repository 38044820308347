import { ScheduleAppointment } from 'types/shopScheduleTypes/ScheduleAppointment'
import { useNavigate } from 'react-router-dom'
import { RESCHEDULE_ONE_ON_ONE_APPOINTMENT } from 'routes'
import CancelAppointment from '../../../../views/CancelAppointment/CancelAppointment'
import { useState } from 'react'
import { getCurrentTimeZoneString, getTimeString } from 'utils/time'
import tailwindColors from 'config/tailwindColors'
import { getAppointmentCalendarEventDescription } from 'utils'
import { is30MinsAway } from 'components/HubLandingTiles/YourNextClass/time'
import { CustomAddToCalendarButton } from 'components/CustomAddToCalendar'

function AppointmentBlock({
  appointment,
  hideActions = false
}: {
  appointment: ScheduleAppointment
  hideActions?: boolean
}): JSX.Element {
  const [ isModalOpen, setIsModalOpen ] = useState( false )

  const navigate = useNavigate()

  const time = new Date( appointment?.start_time_local ?? `` )
  const endTime = new Date(
    time.getTime() + appointment.duration * 60 * 1000
  )
  const appointmentDate = appointment?.start_time_local?.substring(
    0,
    10
  )

  const apptIs30MinutesAway = is30MinsAway( appointmentDate )

  const handleLaunchZoom = () => {
    window.open(
      appointment.interaction_details,
      `_blank`,
      `noreferrer`
    )
  }

  const styleOverrides = `--btn-background: ${tailwindColors.pink[`3`]}; --btn-border: ${tailwindColors.pink[`3`]}; --date-btn-cal-background: ${tailwindColors.pink[`3`]}; --btn-hover-text: ${tailwindColors.gray.dark}; --date-btn-text: ${tailwindColors.gray.dark};`

  return (
    <div className="w-full">
      <div
        className="flex gap-2 items-start w-full"
      >
        <img
          src={appointment?.staff_image ?? appointment.image_url}
          height="70"
          width="70"
        />

        <div className="flex flex-col w-full">

          <CustomAddToCalendarButton
            config={{
              name: `${appointment.staff_display_name}: Lactation Visit (${appointment.client_first_name} ${appointment.client_last_name})`,
              size: `3`,
              description: getAppointmentCalendarEventDescription( appointment ),
              iCalFileName: `lactation-link-appointment-calendar-event-download`,
              buttonStyle: `date`,
              styleLight: styleOverrides,
              styleDark: styleOverrides,
              options: [
                `Apple`,
                `Google`,
                `Outlook.com`,
                `Microsoft365`,
                `Yahoo`
              ],
              location: appointment.interaction_details ? appointment.interaction_details : ``,
              startDate: appointmentDate,
              endDate: appointmentDate,
              startTime: appointment?.start_time_local?.substring( 11, 16 ),
              endTime: getTimeString( endTime ),
              timeZone: getCurrentTimeZoneString(),
              hideBranding: true,
              hideCheckmark: true,
              customCss: `/resources/css/atcb-override.css`
            }}
          />
          {appointment?.id && (
            <div className="flex mt-4 gap-3 md:gap-6 flex-wrap items-start justify-start">
              {
                apptIs30MinutesAway &&
                <div
                  onClick={handleLaunchZoom}
                  className="text-sm text-center a"
                >
                  {`Join Appointment`}
                </div>
              }
              {!hideActions && (
                <>
                  <p
                    className="a text-sm text-center"
                    onClick={() => {
                      navigate(
                        RESCHEDULE_ONE_ON_ONE_APPOINTMENT.concat(
                          `${window.location.search}${
                            window.location.search ? `&` : `?`
                          }appointment_id=${appointment.id}`
                        )
                      )
                    }}
                  >
                    {`Reschedule`}
                  </p>
                  <p
                    className="a text-sm text-center"
                    onClick={() => setIsModalOpen( true )}
                  >
                    {`Cancel`}
                  </p>
                  {isModalOpen && (
                    <CancelAppointment
                      handleClose={() => setIsModalOpen( false )}
                      isOpen={isModalOpen}
                      appointment={appointment}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default AppointmentBlock
