import React from 'react'
import ThankYouPage from '../../reusable-components/ThankYouPage'

function PostClassThankYou(): JSX.Element {
  return <ThankYouPage
    header={`Your Survey Has Been Submitted!`}
    body={`We appreciate you taking the time to let us know your experience with our lactation program. We hope to continue servicing you throughout your motherhood journey.`}
    signOff={``}
    signature={`The Aeroflow Lactation Team`}
  />
}

export default PostClassThankYou