
import { Checkbox, FormControlLabel } from '@mui/material'
import TextInput from './TextInput'

function CheckboxOptions({
  id,
  label,
  onChange,
  labelClassName = `text-base font-semibold mb-1.5`,
  options,
  description = ``,
  required = false
}: {
  id: string
  name: string
  label: string
  description?: string
  options: {
    id: string
    name: string
    selected: boolean
  }[]
  onChange: ( _name: string, _value: boolean, _additional?: string ) => void
  labelClassName?: string
  required?: boolean
}) : JSX.Element {
  return (
    <div className="space-y-3 text-sm">
      <label htmlFor={id} className={labelClassName}>
        {label}{required && <span className="text-error font-normal">{`*`}</span>}
      </label>
      {description && <p className="text-gray-500 mb-2">{description}</p>}
      <div className="flex flex-col gap-3">
        {
          options?.map( ( option ) => {
            return (
              <div key={option.id} className="flex gap-1">
                <FormControlLabel
                  id={option.id} value={option.name}
                  control={<Checkbox
                    onClick={() => { onChange( option.name, !option.selected ) }} color="primary"
                    checked={option.selected}
                  />}
                  label={option.name} componentsProps={{
                    typography: {
                      sx: {
                        fontSize: `14px`,
                        fontWeight: `300`
                      }
                    }
                  }}
                />
                { option.name?.toLowerCase() === `other` && option?.selected && (
                  <TextInput
                    color="primary"
                    onChange={e => { onChange( `other`, true ); onChange( `other`, true, e.target.value ) }} value={undefined}
                  />
                )}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default CheckboxOptions