import React from 'react'

function ReviewDisplay({ reviews, number_of_reviews, vertical = true } : { reviews: number; number_of_reviews: number, vertical?: boolean}) : JSX.Element {
  return (
    <div className={`flex ${vertical && `flex-col` } items-center justify-center`}>
      <div className="ratings">
        <div className="empty-stars"></div>
        <div
          className="filled-stars"
          style={{
            width:`${( reviews / 5 ) * 100}%`
          }}
        />
      </div>
      {
        number_of_reviews &&
        <p className="text-secondary text-sm mx-2">{`${number_of_reviews} Review${number_of_reviews > 1 ? `s` : ``}`}</p>
      }
    </div>
  )
}

export default ReviewDisplay