import React from 'react'

function IndividualSelectionFooter({
  onClick
}: {
  onClick: () => void
}): JSX.Element {
  return (
    <div className="sticky bottom-0 py-6 bg-pink-white flex justify-center items-center w-full z-10">
      <button
        onClick={onClick}
        className="primary-button"
      >
        {`ADD & SCHEDULE`}
      </button>
    </div>
  )
}

export default IndividualSelectionFooter
