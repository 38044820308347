import React from 'react'

function ImgTab({text = `AEROFLOW RECOMMENDED`} : { text?: string }) : JSX.Element {
  return (
    <div className="px-2 py-0.5 bg-primary text-right text-white text-xs md:text-sm z-10">
      {text}
    </div>
  )
}

export default ImgTab