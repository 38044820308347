import { create } from 'zustand'
import { Class, TimeSlot } from 'types/shopScheduleTypes'

type SelectedClassesState = {
  selectedClasses: Class[];
  // exclude days is responsible for excluding dates that have already been selected
  excludeDays: Set<string>;
  // this is for saving the users selected time slot after a timezone change
  savedTimeSelection: string;
  setSavedTimeSelection: ( _time: string ) => void;
  clearSavedTimeSelection: () => void;
  setSelectedClasses: ( _selectedClasses: Class[] ) => void;
  dayFromCal: Date | null;
  setDayFromCal: ( _d: Date ) => void;
  clearDayFromCal: () => void;
  removeClasses: ( _removeClasses: Class[] ) => void;
  addClasses: ( _addClasses: Class[] ) => void;
  isClassSelected: ( _checkClass: Class ) => boolean;
  clearClasses: () => void;
  selectTime: ( _currentClass: Class, _timeSelection: TimeSlot ) => boolean;
  addTimeslots: ( _classData: Class, _timezone: string ) => boolean;
  removeTimes: ( _classes: Class[] ) => void;
  toggleSkipScheduling: ( _class: Class ) => void;
}

const useSelectedClasses = create<SelectedClassesState>( ( set, get ) => {
  return {
    selectedClasses: [],
    excludeDays: new Set(),
    savedTimeSelection: ``,
    dayFromCal: null,
    isClassSelected: ( checkClass: Class ) => {
      // This is a helper function that will return if the class is in the selected array
      const currentClasses = get().selectedClasses
      if ( !currentClasses ) return false

      return Boolean(
        currentClasses.find( ( currClass: Class ) => {
          return checkClass.class_id === currClass.class_id
        })
      )
    },
    setSelectedClasses: ( selectedClasses: Class[] ) => {
      set({
        selectedClasses: [ ...selectedClasses ]
      })
    },
    toggleSkipScheduling: ( classData: Class ) => {
      const currentClasses = get().selectedClasses
      if ( !currentClasses ) return false

      const currentClassIndex = currentClasses.findIndex( ( classDetails: Class ) => {
        return classData.class_id === classDetails.class_id
      })

      if ( currentClassIndex !== -1 ) {
        currentClasses[currentClassIndex] = Object.assign({}, currentClasses[currentClassIndex], {
          skip_scheduling: !currentClasses[currentClassIndex].skip_scheduling
        })
      } else return false

      set({
        selectedClasses: [ ...currentClasses ]
      })

      return true
    },
    removeClasses: ( removeClasses: Class[] ) => {
      // This function is responsible for removing classes to the selected classes array
      const currentClasses = get().selectedClasses
      const currentDaySet = new Set( get().excludeDays )
      if ( !currentClasses ) return

      removeClasses.forEach( ( classItem ) => {
        const classIndex = currentClasses?.findIndex( ( classDetails: Class ) => {
          return classDetails.class_id === classItem.class_id
        })
        if ( classIndex !== -1 ) {
          const dateVal = currentClasses[classIndex]?.selected_timeslot?.scheduled_date?.substring( 0, 10 )
          if ( dateVal ) currentDaySet.delete( dateVal )
          currentClasses?.splice( classIndex, 1 )
        }
      })

      set({
        selectedClasses: [ ...currentClasses ],
        excludeDays: new Set( currentDaySet )
      })
    },
    addClasses: ( addClasses: Class[] ) => {
      // This function is responsible for adding classes to the selected classes array
      const currentClasses = get().selectedClasses.concat( addClasses )

      set({
        selectedClasses: [ ...currentClasses ]
      })
    },
    addTimeslots: ( classData: Class, timezone: string ) => {
      // This function saves the fetched timeslots in the data structure so we don't have to refetch
      // the timeslots if the user back navigates since these are fetched on class page load

      const currentClasses = get().selectedClasses
      if ( !currentClasses ) return false

      const currentClassIndex = currentClasses.findIndex( ( classDetails: Class ) => {
        return classData.class_id === classDetails.class_id
      })

      if ( currentClassIndex !== -1 ) {
        currentClasses[currentClassIndex] = Object.assign({}, currentClasses[currentClassIndex], {
          [`${timezone}_timeslots`]: classData.timeslots,
          class_duration: classData?.timeslots?.length ? classData.timeslots[0].duration_minutes : ``
        })
      } else return false

      set({
        selectedClasses: [ ...currentClasses ]
      })

      return true

    },
    removeTimes: ( classes: Class[] ) => {
      const currentClasses = get().selectedClasses
      const currentDaySet = new Set( get().excludeDays )
      if ( !currentClasses ) return

      classes.forEach( ( removeClassInfo ) => {
        const currentClassIndex = currentClasses.findIndex( ( classDetails: Class ) => {
          return removeClassInfo.class_id === classDetails.class_id
        })

        if ( currentClassIndex !== -1 ) {
          const dateVal = currentClasses[currentClassIndex]?.selected_timeslot?.scheduled_date?.substring( 0, 10 )
          // remove date value from excluded days if the user is changing dates
          if ( dateVal ) currentDaySet.delete( dateVal )
          delete currentClasses[currentClassIndex].selected_timeslot
        }
      })

      set({
        selectedClasses: [ ...currentClasses ],
        excludeDays: new Set( currentDaySet )
      })
    },
    selectTime: ( currentClass: Class, timeSelection: TimeSlot ) => {
      // this function adds the selected timeslot to the selected_timeslot property for submission on confirm page

      const currentClasses = get().selectedClasses
      const currentDaySet = new Set( get().excludeDays )
      if ( !currentClasses ) return false

      // add selected timeslot to class object
      const currentClassIndex = currentClasses.findIndex( ( classDetails: Class ) => {
        return currentClass.class_id === classDetails.class_id
      })

      if ( currentClassIndex !== -1 ) {
        const dateVal = currentClasses[currentClassIndex]?.selected_timeslot?.scheduled_date?.substring( 0, 10 )
        // remove date value from excluded days if the user is changing dates
        if ( dateVal ) currentDaySet.delete( dateVal )
        currentClasses[currentClassIndex] = Object.assign({}, currentClass, {
          selected_timeslot: timeSelection
        })
        currentDaySet.add( timeSelection.scheduled_date.substring( 0, 10 ) )
      } else return false

      set({
        selectedClasses: [ ...currentClasses ],
        excludeDays: new Set( currentDaySet )
      })

      return true
    },
    setSavedTimeSelection: ( time: string ) => {
      set({
        savedTimeSelection: time
      })
    },
    clearSavedTimeSelection: () => {
      set({
        savedTimeSelection: ``
      })
    },
    setDayFromCal: ( date: Date ) => {
      set({
        dayFromCal: date
      })
    },
    clearDayFromCal: () => {
      set({
        dayFromCal: null
      })
    },
    clearClasses: () => {
      set({
        selectedClasses: []
      })
    }
  }
})

export default useSelectedClasses