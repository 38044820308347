import React from 'react'
import { Modal } from 'components/modal'

function ThemedModal({ message, open, handleClose, handleClickOff } : {
  message: string,
  open: boolean,
  handleClose: () => void,
  handleClickOff?: () => void
}) : JSX.Element {
  return (
    <Modal
      isOpen={open}
      defaultCloseButton={false}
      containerStyling=""
      onClose={handleClickOff ?? handleClose}
    >
      <div className="rounded-xl bg-secondary p-9 flex flex-col justify-center items-center">
        <p className="text-xl text-white mb-8 text-center">{message}</p>
        <button
          className="primary-button"
          onClick={handleClose}
        >
          {`GOT IT`}
        </button>
      </div>
    </Modal>
  )
}

export default ThemedModal