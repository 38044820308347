import { getSource } from "utils/getSource"

export const fetchBundles = async ( auth ) => {
  const response = await fetch( `${process.env.REACT_APP_SHOP_AND_SCHEDULE_URL}/bundles?source=${getSource()}`, {
    headers: {
      "Authorization": auth
    }
  })

  return await response.json()
}